import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const infoWindowStyle = {
  position: 'absolute',
  top: '100%',
  left: '-143.5px',
  width: 287,
  backgroundColor: 'white',
  borderRadius: '16px',
  //overflow: 'hidden',
  boxShadow: '0px 10px 40px 15px rgba(0, 0, 0, 0.03)',
  padding: 0,
  fontSize: 14,
  zIndex: 100,
  cursor: 'pointer',
};

const infoWindowThumbnailStyle = {
  position: 'relative',
  height: '220px',
  overflow: 'hidden',
};
const infoWindowThumbnailStyleSingle = {
  position: 'relative',
  height: '125px',
  overflow: 'hidden',
};

const infoWindowThumbnailImgStyle = {
  minWidth: '100%',
  width: '287px',
  height: '220px',
  objectFit: 'cover',
  display: 'block',
  lineHeight: 1,
};

const infoWindowTextBlockStyle = {
  paddingTop: 10,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
};

const InfoWindow = (props) => {
  const [mode, setMode] = useState(infoWindowThumbnailStyle);
  const history = useHistory();

  const moveToObject = () => {
    history.push({
      pathname: `/${props.model.type}/` + props.model.slug,
      state: { update: true },
    });
  };
  useEffect(() => {
    if (props.infoWindowModeVersion == 'singleObject') {
      setMode(infoWindowThumbnailStyleSingle);
    }
  }, []);
  if (!props.isShow) return null;

  return (
    <div style={infoWindowStyle}>
      <div style={mode} onClick={moveToObject}>
        <img
          style={infoWindowThumbnailImgStyle}
          src={props.model.image.mini}
          alt={props.model.title}
        />
      </div>

      <div onClick={moveToObject} style={infoWindowTextBlockStyle}>
        <h3
          style={{
            fontSize: '18px',
            color: '#000',
            lineHeight: '22px',
            margin: 0,
          }}
        >
          {props.model.title}
        </h3>
        <div
          style={{
            fontSize: '0.8125rem',
            color: '#7e7e7e',
            lineHeight: '1rem',
          }}
        >
          {props.model.region_name},{props.model.area_name}
        </div>
      </div>
    </div>
  );
};

InfoWindow.propTypes = {
  model: PropTypes.object.isRequired,
  infoWindowModeVersion: PropTypes.string,
  isShow: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default InfoWindow;
