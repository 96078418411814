import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import DominantaLogoAboutUs from "../../media/about-us/DominantaLogoAboutUs.png"
import DominantaLogoAboutUsSmall from "../../media/about-us/DominantaLogoAboutUsSmall.png"
import DialogBooking from "../Dialog/DialogBooking";
const BrandBlock = () => {
  const { t, i18n } = useTranslation();


  return (
      <div className="home-page--about home-page--about--brand">
        <div className="home-page--about-bars">
          <div className="bars-row">
          <div className="sell-page-coll">
              <div className="mask-about-us">
              <div className="container-about-us">
                <h2>{t("Міжнародне агенство нерухомості")}</h2>
                  <img src={DominantaLogoAboutUs} className="default-dominanta-logo" alt="logo"/>
                  <div className="actions">
                      <DialogBooking model={{}} textOrder={"ХОЧУ СТАТИ ЧАСТИНОЮ КОМАНДИ"} />
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default BrandBlock;
