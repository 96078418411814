import { createSlice } from "@reduxjs/toolkit";

export const selectedFilterDataSlice = createSlice({
  name: "selectedFilterData",
  initialState: {
    value: {
      city: 33,
      // objectId:
      // district:11,
      // district:8,

      // only view developers
      // price
      // rooms
      // populars
      // floors
      // numberOfStoreys
      // buildingType
      // heating
      // walls
      // repair
      // totalArea
      // livingArea
      // kitchenArea
      // floorVariants
      // terraceVariants
      // housesVariants
      // windowVariants
      // viewVariants
      // bathRoomVariants
    },
  },
  reducers: {
    setMass: (state, action) => {
      state.value = action.payload;
    },
    setId: (state, action) => {
      // state.objectId={value:{objectId:action.payload}}
      state.value = { ...state.value, objectId: action.payload };
    },
    removeId: (state, action) => {
      const newState = { ...state.value };
      delete newState.objectId;
      state.value = newState;
    },
    setCity: (state, action) => {
      const newState = { ...state.value, city: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setDistrict: (state, action) => {
      const newState = { ...state.value, district: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setPrice: (state, action) => {
      const newState = { ...state.value, price: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    removePrice: (state, action) => {
      const newState = { ...state.value, price: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setArea: (state, action) => {
      const newState = { ...state.value, totalArea: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setLivingArea: (state, action) => {
      const newState = { ...state.value, livingArea: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setKitchenArea: (state, action) => {
      const newState = { ...state.value, kitchenArea: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setRooms: (state, action) => {
      const newState = { ...state.value, rooms: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setFloorVariants: (state, action) => {
      const newState = { ...state.value, floorVariants: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setTerraceVariants: (state, action) => {
      const newState = { ...state.value, terraceVariants: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setLandPlotsCommunicationsVariants: (state, action) => {
      const newState = {
        ...state.value,
        communicationsVariants: action.payload,
      };
      delete newState.objectId;
      state.value = newState;
    },
    setHousesVariants: (state, action) => {
      const newState = { ...state.value, housesVariants: action.payload };
      delete newState.objectId;
      state.value = newState;
    },

    setViewVariants: (state, action) => {
      const newState = { ...state.value, viewVariants: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setWindowVariants: (state, action) => {
      const newState = { ...state.value, windowVariants: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setLandPlotsVariants: (state, action) => {
      const newState = { ...state.value, landPlotsVariants: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setBathRoomVariants: (state, action) => {
      const newState = { ...state.value, bathRoomVariants: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setPopulars: (state, action) => {
      const newState = { ...state.value, populars: action.payload };
      const clearData = Object.entries(newState).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {},
      );
      state.value = clearData;
      // if(newState.populars===''){
      //
      // }else{
      //     delete newState.objectId;
      //     state.value=newState;
      // }
    },
    setFloors: (state, action) => {
      const newState = { ...state.value, floors: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setNumberOfStoreys: (state, action) => {
      const newState = { ...state.value, numberOfStoreys: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setPurposes: (state, action) => {
      const newState = { ...state.value, purposes: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setBuildingType: (state, action) => {
      const newState = { ...state.value, buildingType: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setHeating: (state, action) => {
      const newState = { ...state.value, heating: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setWalls: (state, action) => {
      const newState = { ...state.value, walls: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setRepair: (state, action) => {
      const newState = { ...state.value, repair: action.payload };
      delete newState.objectId;
      state.value = newState;
    },
    setResetSelectedFilter: (state, action) => {
      // const newState={...state.value,city:action.payload}
      const newState = { city: 33 };
      delete newState.objectId;
      state.value = newState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMass,
  setCity,
  setDistrict,
  setPrice,
  setArea,
  setLivingArea,
  setKitchenArea,
  setRooms,
  setFloorVariants,
  setTerraceVariants,
  setLandPlotsCommunicationsVariants,
  setHousesVariants,
  setBathRoomVariants,
  setViewVariants,
  setWindowVariants,
  setLandPlotsVariants,
  setPopulars,
  setFloors,
  setNumberOfStoreys,
  setBuildingType,
  setHeating,
  setWalls,
  setRepair,
  setResetSelectedFilter,
  setId,
  setPurposes,
  removeId,
  removePrice,
} = selectedFilterDataSlice.actions;

export default selectedFilterDataSlice.reducer;
