import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Slider, { SliderThumb } from "@mui/material/Slider";

const PriceSlider = styled(Slider)(({ theme }) => ({
  color: "#3F6DB5",
  height: 1,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 21,
    width: 21,
    backgroundColor: "#fff",
    border: "1px solid #fafafa",
    "&:hover": {
      boxShadow: "0 0 0 1px rgba(64, 110, 181, 0)",
    },
    "&.airbnb-bar": {
      height: 1,
      width: 1,
      backgroundColor: "transparent",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 1,
    backgroundColor: "transparent",
    border: "1px solid transparent",
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 1,
  },
}));
const ThumbComponent = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10.5" cy="10.5" r="10" stroke="#DEDEDE" />
        <line
          x1="10.4473"
          y1="5.52631"
          x2="10.4473"
          y2="15.4737"
          stroke="black"
        />
        <line
          x1="13.7627"
          y1="6.76974"
          x2="13.7627"
          y2="14.2303"
          stroke="black"
        />
        <line
          x1="7.13184"
          y1="6.76974"
          x2="7.13184"
          y2="14.2303"
          stroke="black"
        />
      </svg>
    </SliderThumb>
  );
};

const SliderFilter = (props) => {
  const [valueMinInit, setValueMinInit] = useState(0);
  const [valueMaxInit, setValueMaxInit] = useState(0);

  //todo fix it init then 0 0
  useEffect(() => {
    if (valueMaxInit <= 0) {
      setValueMinInit(props.min);
      setValueMaxInit(props.max);
    }
  }, [props.min, props.max]);

  useEffect(() => {
    if (props.hasReset) {
      setValueMinInit(props.min);
      setValueMaxInit(props.max);
    }
  }, [props.hasReset]);

  // render trouble todo x it
  if (valueMaxInit <= 0) {
    return null;
  }
  return (
    <React.Fragment>
      <PriceSlider
        components={{ Thumb: ThumbComponent }}
        getAriaLabel={(index) =>
          index === 0 ? "Minimum price" : "Maximum price"
        }
        onChange={props.callbackChange}
        defaultValue={[valueMinInit, valueMaxInit]}
        min={valueMinInit}
        max={valueMaxInit}
      />
    </React.Fragment>
  );
};

SliderFilter.propTypes = {
  callbackChange: PropTypes.func,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  hasReset: PropTypes.number.isRequired,
};

export default SliderFilter;
