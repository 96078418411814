import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { putObjectBreadCrumbs } from "../../store/reducers/object";
import APP_CONFIG from "../../config";
import AppHeader from "../AppHeader";
import BreadCrumbs from "../ui/BreadCrumbs";
import Consultation from "./Consultation";
import AppFooter from "../AppFooter";
import { useTranslation } from "react-i18next";

const StepsList = [
  {
    index: 1,
    title: "Розмістіть оголошення",
    text: "Залиште свій номер телефону, і наш фахівець зв'яжеться з вами. Він поставить кілька запитань про вашу нерухомість і розмістить ваше оголошення на сайті",
  },
  {
    index: 2,
    title: "Підготовка до продажу",
    text: "Ми додамо фотографії та планування, а також створимо привабливий опис вашої пропозиції. Ви можете підписати договір з агентом в офісі або онлайн",
  },
  {
    index: 3,
    title: "Пошук покупців",
    text: "Ми розмістимо ваше оголошення на популярних міських платформах для пошуку нерухомості. Наш фахівець з нерухомості організує особисті або онлайн покази вашого об'єкта для потенційних покупців.",
  },
  {
    index: 4,
    title: "Укладання договору",
    text: "Беремо на себе весь процес підготовки документів і проведення угоди. Організовуємо і супроводжуємо процес передачі завдатку, можна онлайн. Оплачуєте послуги компанії. Проводимо операцію, реєструємо право власності. Передаєте ключі.",
  },
  // {
  //     index: 5,
  //     title: 'Квартира ваша',
  //     text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  // }
];

const CooperationWork = () => {
  const [openStep, setOpenStep] = useState(StepsList[0]);
  const { t, i18n } = useTranslation();
  const openStepAction = (step) => {
    setOpenStep(step);
  };

  return (
    <div className="home-page--cooperation">
      <div className="container">
        <h2>{t("Як це працює")} </h2>
      </div>

      <div className="container step-list">
        {StepsList.map((obj, i) => {
          return (
            <div key={i} className="step-list--row">
              <div
                key={i}
                onClick={() => openStepAction(obj)}
                className={
                  openStep === obj ? "step--cell open-cell" : "step--cell"
                }
              >
                <h3>{t(obj.title)}</h3>

                {openStep === obj && (
                  <div className="fade-block">
                    <p>{t(obj.text)}</p>
                  </div>
                )}
              </div>
              {openStep === obj && (
                <div className="current-step--label">
                  <div className="fade-block">{openStep.index}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CooperationWork;
