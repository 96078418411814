import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import { useDispatch, useSelector } from "react-redux";
import {
  setHousesVariants,
  setRooms,
} from "../../store/reducers/selectedFilterData";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";

const HousesTypesSelect = ({ type }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  //
  const housesVariants = useSelector(
    (state) => state.filter.value.housesVariants,
  );
  const search = useSearchMove(type);
  const handlerSelectRoomsChange = (event, newValue) => {
    const selectedSlugs = newValue.map((e) => e.slug).join("-");
    setSelectedItems(newValue);
    dispatch(setHousesVariants(selectedSlugs));
    let overriddenData = { housesVariants: selectedSlugs };
    search.moveToFilterOverridden(overriddenData);
  };
  useEffect(() => {
    if (
      housesVariants &&
      housesVariants.length &&
      selectedFilterData.housesVariants
    ) {
      //todo fix get params cutted plus
      const splitted = selectedFilterData.housesVariants.split("-").map((e) => {
        return e;
      });

      const filtered = housesVariants.filter(
        (e) => splitted.findIndex((d) => e.slug === d) > -1,
      );
      setSelectedItems(filtered);
    }
  }, [housesVariants, selectedFilterData]);

  if (!housesVariants || !housesVariants.length) {
    return null;
  }
  return (
    <React.Fragment>
      <SelectItems
        limitTags={1}
        items={housesVariants}
        selected={selectedItems}
        label={t("Тип будівлі")}
        multiple={true}
        callbackChange={handlerSelectRoomsChange}
      />
    </React.Fragment>
  );
};

HousesTypesSelect.propTypes = {};

export default HousesTypesSelect;
