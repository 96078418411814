import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import { useDispatch, useSelector } from "react-redux";
import {
  setDistrict,
  setPopulars, setPurposes,
  setRooms,
} from "../../store/reducers/selectedFilterData";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";

const PopularSelect = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const items = useSelector((state) => state.filter.value.purposes);
  const [selectedItems, setSelectedItems] = useState(null);
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  const search = useSearchMove('commerce');
  const handlerSelectRoomsChange = (event, newValue) => {
    dispatch(setPurposes(newValue?.slug ?? ""));
    let overriddenData = { purposes: newValue?.slug ?? "" };
    // const clearData = Object.entries(overriddenData).reduce(
    //   (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    //   {},
    // );
    // search.moveToFilterOverridden(clearData);
    search.moveToFilterOverridden(overriddenData);
  };
  useEffect(() => {
    if (items && items.length && selectedFilterData.purposes) {
      const filtered = items.find(
        (e) => e.slug === selectedFilterData.purposes,
      );
      setSelectedItems(filtered);
    } else {
      setSelectedItems(null);
    }
  }, [items, selectedFilterData]);
  return (
    <React.Fragment>
      <SelectItems
        items={items}
        selected={selectedItems}
        label={t("Призначення")}
        multiple={false}
        callbackChange={handlerSelectRoomsChange}
      />
    </React.Fragment>
  );
};

PopularSelect.propTypes = {};

export default PopularSelect;
