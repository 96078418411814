import APP_CONFIG from "../config";

const METADATA = {

  networksList: [
    {
      title: "Instagram",
      icon_name: "instagram",
      action: () => {
        window.open(APP_CONFIG.social.instagram);
      },
    },
    {
      title: "YouTube",
      icon_name: "you_tube",
      action: () => {
        window.open(APP_CONFIG.social.youTube);
      },
    },
    {
      title: "Facebook",
      icon_name: "facebook",
      action: () => {
        window.open(APP_CONFIG.social.facebook);
      },
    },
    {
      title: "Telegram",
      icon_name: "telegram",
      action: () => {
        window.open(APP_CONFIG.social.telegram);
      },
    },
  ],
};

export default METADATA;