import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import { apiGet } from "../../utils/api";
import { useHistory } from "react-router-dom";

const CssTextField = styled(TextField)({
  borderRadius: "0",
  "& label.Mui-focused": {
    color: "#F1623D",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D9D9D9",
      borderRadius: "0",
    },
    "&:hover fieldset": {
      borderColor: "#F1623D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F1623D",
    },
  },
});

const Consultation = () => {
  const { t, i18n } = useTranslation();
  const [textError, setTextError] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [isPhoneCompleted, setIsPhoneCompleted] = useState(false);
  const [selectedPropertyType, setSelectedPropertyType] = useState("Квартира");
  const [data, setData] = useState({});
  const [phone, setPhone] = useState("");
  const history = useHistory();
  const [comment, setComment] = useState("");

  const sendRequest = () => {
    const params = {
      phone: phone,
      propertyType: selectedPropertyType,
      comment: comment,
    };

    const result = apiGet("user-action-booking-view", params)
        .then((data) => data.data)
        .then((response) => {
          const { status, serverResponce } = response;
          let ojson = JSON.parse(serverResponce.replace(/'/g, '"'));
          const { error_message, error } = ojson;
          if (error_message === "Лид добавлен") {
            if (status) {
              history.push({
                pathname: "/user-success-page",
                state: {
                  update: true,
                },
              });
            } else {
              alert("Error");
            }
          }
        })
        .catch((error) => {
          console.error("Помилка при оформленні консультації:", error);
        });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedPropertyType === "") {
      setTextError(true);
      return;
    }
    if (!isPhoneCompleted) {
      return;
    }
    sendRequest();
  };

  const handlePropertyTypeChange = (event) => {
    setSelectedPropertyType(event.target.value);
  };

  const changePhoneValue = (value) => {
    setPhone(value);
    if (value.length >= 12) {
      setIsPhoneCompleted(true);
    } else {
      setIsPhoneCompleted(false);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
      <div className="home-page--about">
        <div className="home-page--about-bars">
          <div className="bars-row">
            <div className="sell-page-coll">
              {/*<img src={AboutLgSale} alt={"about"} />*/}
              <div className="mask">
                <h2>{t("Допоможемо продати нерухомість швидко і вигідно")}</h2>
                <p>
                  {t(
                      "Розмістіть оголошення про продаж або дізнайтесь ринкову вартість нерухомості"
                  )}
                </p>
              </div>
            </div>
            <div className="top-sale--cell cell-left">
              <div className="stiky-wrap-main">
                <div className="top-form--sell">
                  <div className="top-form--sell-text">
                    <p>{t("Тип нерухомості")}</p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="top-form--sell-form">
                      <CssTextField
                          sx={{
                            marginBottom: "45px",
                            backgroundColor: "#ffffff",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#ffffff",
                              },
                              "&:hover fieldset": {
                                borderColor: "#ffffff",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#ffffff",
                              },
                            },
                          }}
                          select
                          defaultValue="Квартира"
                          autoFocus
                          error={textError}
                          margin="dense"
                          id="property-type"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={selectedPropertyType}
                          onChange={handlePropertyTypeChange}
                      >
                        <MenuItem value="Квартира">{t("Квартира")}</MenuItem>
                        <MenuItem value="Таунхаус">{t("Таунхаус")}</MenuItem>
                        <MenuItem value="Будинок">{t("Будинок")}</MenuItem>
                        <MenuItem value="Ділянка">{t("Ділянка")}</MenuItem>
                        <MenuItem value="Дача">{t("Дача")}</MenuItem>
                        <MenuItem value="Гараж">{t("Гараж")}</MenuItem>
                      </CssTextField>
                      <PhoneInput
                          inputStyle={{
                            borderRadius: "0px",
                          }}
                          variant="outlined"
                          error={textError}
                          regions={"europe"}
                          country={"ua"}
                          onlyCountries={["ua"]}
                          disableSearchIcon={true}
                          value={phone}
                          onChange={changePhoneValue}
                          specialLabel={""}
                          placeholder={"+380 (00) 000 00 00"}
                          isValid={(inputNumber, country, countries) => {
                            const isValid = countries.some((country) => {
                              return (
                                  startsWith(inputNumber, country.dialCode) ||
                                  startsWith(country.dialCode, inputNumber)
                              );
                            });
                            if (
                                isPhoneCompleted !== isValid &&
                                phone.length >= 12
                            ) {
                              setIsPhoneCompleted(isValid);
                            }
                            return isValid;
                          }}
                      />
                      <CssTextField
                          sx={{
                            marginBottom: "45px",
                            marginTop: "45px",
                            backgroundColor: "#fff",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#fff",
                              },
                              "&:hover fieldset": {
                                borderColor: "#fff",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#c2c2c2",
                              },
                            },
                          }}
                          margin="dense"
                          id="comment"
                          type="text"
                          fullWidth
                          variant="outlined"
                          multiline
                          minRows={2}
                          placeholder={t(
                              "Добрий день. Хочу продати нерухомість.\nПотрібна консультація"
                          )}
                          value={comment}
                          onChange={handleCommentChange}
                      />
                      <div className="top-sale top-sale--dialog">
                        <div className="viewing-action-row">
                          <button
                              disabled={!isPhoneCompleted}
                              className="viewing-action"
                          >
                            {t("Отримати безкоштовну консультацію")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Consultation;
