import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import HomePageRealtorsPromoItem from "./HomePageRealtorsPromoItem/HomePageRealtorsPromoItem";
import { apiGet } from "../utils/api";
import { useDispatch } from "react-redux";

const settingsBestRealtor = {
  dots: false,
  centerMode: true,
  // centerPadding: '60px',
  slidesToShow: 3,
  infinite: false,
  initialSlide: 1,
  swipe: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        dots: true,
        swipe: true,
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const HomePageRealtorsPromo = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => {
    apiGet("responsible/promo")
      .then((data) => data.data)
      .then((response) => {
        setData(response.data);
      });
    return () => {};
  }, [i18n.language]);

  return (
    <div className="home-page--realtor-promo">
      <div className="container">
        <div className="home-page--realtor-promo__title">
          <div className="cell promo-cell">
            <h2> {t("96% клієнтів задоволені нашими послугами")}</h2>
            <h3>
              {" "}
              {t(
                "З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом із Dominanta, проходять навчання для надання якісних послуг. А після угоди – клієнти оцінюють їхню роботу",
              )}
            </h3>
          </div>
        </div>

        <div className="home-page--realtor-promo-tabs">
          <div className="home-page--realtor-promo-tabs__content">
            <div className="home-page--realtor-promo-mobile-list">
              {data.length && (
                <Slider {...settingsBestRealtor} key={"mob_deals"}>
                  {data.map((object, i) => {
                    return <HomePageRealtorsPromoItem data={object} />;
                  })}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePageRealtorsPromo;
