export const setItemWithExpiry = (key, value, ttl) => {
  const now = new Date();

  // `ttl` is time to live in milliseconds
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key, defaultValue = []) => {
  const itemStr = localStorage.getItem(key);

  // If the item doesn't exist, return null
  if (!itemStr) {
    return defaultValue;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // Compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage and return null
    localStorage.removeItem(key);
    return defaultValue;
  }

  return item.value;
};
