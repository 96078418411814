import React from "react";

export const TotalAreaIcon = () => {
  return (
    <svg
      width="39"
      height="36"
      viewBox="0 0 39 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.015 24.6842V35.2857H1V14.8496H13.5413"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61719 14.8496H38.1736V28.6993H23.557L22.1585 30.6391"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.548 14.8496V26.3534L10.4277 27.5263"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5098 15.4812V22.6993"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.1729 1H1V9.93233H38.1729V1Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.2861 9.9323V7.49622"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.4434 9.93232V5.46616"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6016 9.9323V7.49622"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7139 9.93232V5.46616"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8721 9.9323V7.49622"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0293 9.93232V5.46616"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1875 9.9323V7.49622"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3457 9.93232V5.46616"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.458 9.9323V7.49622"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61621 9.93232V5.46616"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95508 9.9323V7.49622"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.11328 9.93232V5.46616"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CountRoomsIcon = () => {
  return (
    <svg
      width="42"
      height="34"
      viewBox="0 0 42 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.6246 17.4934H3.73226C2.20936 17.4934 1 18.8307 1 20.5147V33.442H6.82285V30.1235C6.82285 29.1329 7.53951 28.3404 8.43534 28.3404H34.0111C34.9069 28.3404 35.6236 29.1329 35.6236 30.1235V33.442H41.4464V20.5147C41.4016 18.8307 40.1475 17.4934 38.6246 17.4934Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.62695 18.6821V18.3354C1.62695 15.9085 3.4186 13.8777 5.65816 13.8777H36.7432C38.938 13.8777 40.7744 15.8589 40.7744 18.3354V18.6821"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20898 13.8777V7.73605C5.20898 4.02132 7.94125 1 11.3454 1H31.2327C34.592 1 37.3243 4.02132 37.3243 7.73605V13.8777"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1816 13.8282V12.689C10.1816 11.5994 11.0775 10.7574 12.242 10.7574H17.8409C18.9607 10.7574 19.9013 11.6489 19.9013 12.689V13.8282"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2197 13.8282V12.689C32.2197 11.5994 31.3239 10.7574 30.1593 10.7574H24.5604C23.4406 10.7574 22.5 11.6489 22.5 12.689V13.8282"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AddressIcon = () => {
  return (
    <svg
      width="39"
      height="40"
      viewBox="0 0 39 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 15.6696L1 12.0916V35.0799L13.3333 38.6578L25.6667 35.0799L38 38.6578V15.6696L27.3822 12.5836"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.334 15.6696V38.6578"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.668 15.6696V35.0799"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5008 1C13.0096 1 8.83668 7.70864 11.9432 13.2097L19.5008 26.6717L27.0585 13.2097C30.165 7.70864 25.9921 1 19.5008 1Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5003 12.6283C21.5489 12.6283 23.2096 11.0264 23.2096 9.05035C23.2096 7.07431 21.5489 5.47241 19.5003 5.47241C17.4517 5.47241 15.791 7.07431 15.791 9.05035C15.791 11.0264 17.4517 12.6283 19.5003 12.6283Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BuildingTypeIcon = () => {
  return (
    <svg
      width="42"
      height="38"
      viewBox="0 0 42 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.7567 3.84211H4.24316V1H37.7567V3.84211Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.24316 3.8421V33.7191"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.7568 3.8421V33.7191"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 34.1579H1V37H41V34.1579Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7686 33.6196V20.3564H26.5485V33.6196"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1592 20.3564V33.6196"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6888 20.3564H7.29883V27.4674H12.6888V20.3564Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.568 27.4496H34.958V20.3387H29.568V27.4496Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6888 6.92023H7.29883V14.0312H12.6888V6.92023Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8245 6.92023H18.4346V14.0312H23.8245V6.92023Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.9583 6.92023H29.5684V14.0312H34.9583V6.92023Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
