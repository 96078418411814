import React, { useEffect, useState } from "react";
// import Apartments from "/src/components/AppHeader/components/HomePageFilterFields/Apartment";
import {
  FilterCommercesIcon,
  FilterHouseIcon,
  // FilterNewBuildingIcon,
  FilterPlacesIcon,
  FilterSecondaryIcon,
} from "/src/components/vector/FIlterIcons";

import { useDispatch, useSelector } from "react-redux";
import { setActiveFilterTab, setFilter } from "/src/store/reducers/filter";
import { useTranslation } from "react-i18next";
import CommerceFilterBlock from "./HomePageFilterFields/CommerceFilterBlock";
import HousesFilterBlock from "./HomePageFilterFields/HousesFilterBlock";
import ApartmentsSecondaryFilterBlock from "./HomePageFilterFields/ApartmentsSecondaryFilterBlock";
import PlotsFilterBlock from "./HomePageFilterFields/PlotsFilterBlock";
import { filterContainer } from "../../../containers/filterContainer";

const filterTabs = [
  {
    title: "Квартири втор. ринок",
    value: 1,
    icon: <FilterSecondaryIcon />,
    code: "apartment",
  },
  {
    title: "Комерція",
    value: 5,
    icon: <FilterCommercesIcon />,
    code: "commerce",
  },

  {
    title: "Будинки",
    value: 3,
    icon: <FilterHouseIcon />,
    code: "house",
  },
  {
    title: "Ділянки",
    value: 4,
    icon: <FilterPlacesIcon />,
    code: "plots",
  },
  // {
  //   title: "Новобудови",
  //   value: 2,
  //   icon: <FilterNewBuildingIcon />,
  //   code: "developmentbuild",
  // },
];

const HomePageFilter = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(filterTabs[0]);
  useEffect(() => {
    dispatch(setActiveFilterTab(selectedFilter.code));
    props.loadFilterData(`?type=${selectedFilter.code}`).then((data) => {
      dispatch(setFilter(data));
    });
  }, [selectedFilter, i18n.language]);

  const renderFilterFields = () => {
    switch (selectedFilter.value) {
      case 1:
        return <ApartmentsSecondaryFilterBlock />;
      case 2:
        return <Apartments />;
      case 3:
        return <HousesFilterBlock />;
      case 4:
        return <PlotsFilterBlock />;
      case 5:
        return <CommerceFilterBlock />;
    }
  };

  return (
    <div className="filter-wrap">
      <div className="filter-head">
        {filterTabs.map((obj) => {
          return (
            <button
              onClick={setSelectedFilter.bind(null, obj)}
              key={obj.value}
              className={
                obj === selectedFilter
                  ? "filter-head--item selected"
                  : "filter-head--item"
              }
            >
              <span className="icon-wrap">{obj.icon}</span>
              <span className="label">{t(obj.title)}</span>
            </button>
          );
        })}
      </div>

      <div className="filter-selected-fields">{renderFilterFields()}</div>
    </div>
  );
};
export default filterContainer(HomePageFilter);
