import { createSlice } from "@reduxjs/toolkit";

export const objectsSlice = createSlice({
  name: "bestProposals",
  initialState: {
    // data: {data:[],links:{},meta:{}},
    data: [],
  },
  reducers: {
    putBestProposalsObjects: (state, action) => {
      state.data = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { putBestProposalsObjects } = objectsSlice.actions;
export default objectsSlice.reducer;
