import React, { useEffect, useState } from "react";
import RoundButtonLi from "/src/components/ui/buttons/RoundButtonLi";
import PropTypes from "prop-types";
import { getDefaultById, getDefaultBySlug } from "/src/utils/filterUtils";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const FilterButtonGroup = (props) => {
  const [selected, setSelected] = useState([]);
  let query = useQuery();

  useEffect(() => {
    // console.log('options', props.options)
    if (props.options && props.options.length) {
      const param = query.get(props.keyName);
      if (param) {
        const splitData = param.split(",");
        const newSelected = [];
        // if (props.keyName=='populars' || props.keyName == 'floors'){
        splitData.forEach((e) => {
          const el1 = getDefaultBySlug(e, props.options);
          newSelected.push(el1);
        });
        // }
        setSelected(newSelected);
      }
    }
  }, [props.options]);
  useEffect(() => {
    if (props.hasReset) {
      setSelected([]);
    }
  }, [props.hasReset]);

  return (
    <div className="fields__cell">
      <div className="fields__cell-label">{props.title}</div>

      <ul className="options-list">
        {props.options && props.options.map((obj) => {
          const isSelected = getDefaultById(obj.id, selected);
          return (
            <RoundButtonLi
              key={obj.id}
              model={obj}
              status={isSelected}
              callback={() => {
                const newData = selected.filter((e)=> {
                  if(!e){
                    return false;
                  }
                 return  e.id != obj.id;

                });
                if (!isSelected) {
                  newData.push(obj);
                }
                setSelected(newData);
                props.callbackChange(newData);
              }}
            />
          );
        })}
      </ul>
    </div>
  );
};
FilterButtonGroup.defaultProps = {
  keyName: "",
};
FilterButtonGroup.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  callbackChange: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  hasReset: PropTypes.number.isRequired,
  // selected:PropTypes.array.isRequired,
};
export default FilterButtonGroup;
