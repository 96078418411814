import React, { useState, Fragment } from "react";

import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import useSearchMove from "/src/hooks/useSearchMove";
import FilterButtonGroup from "/src/components/Filter/FilterButtonGroup";
import {
  setBuildingType,
  setFloors,
  setHeating,
  setNumberOfStoreys,
  setPopulars,
  setRepair,
  setWalls,
} from "/src/store/reducers/selectedFilterData";
import RangeFieldsetBlock from "../ui/sliders/RangeFieldsetBlock";
import {
  setArea,
  setKitchenArea,
  setLivingArea,
  setPrice,
  setResetSelectedFilter,
} from "../../store/reducers/selectedFilterData";
import { useTranslation } from "react-i18next";
// import RangeFieldset from "../ui/sliders/RangeFieldset";

// const Option = ({ option = {} }) => {
//     return <li className='option'>
//         {option.title}
//         <button>
//             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M4.50024 4.5L11.5004 11.5001" stroke="white" strokeLinejoin="round" />
//                 <path d="M4.50024 11.5L11.5004 4.49988" stroke="white" strokeLinejoin="round" />
//             </svg>
//         </button>
//     </li>
// }

const RangeFieldset = () => {
  const [value, setValue] = React.useState([25000, 125000]);

  const PriceSlider = styled(Slider)(({ theme }) => ({
    color: "#3F6DB5",
    height: 1,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
      height: 21,
      width: 21,
      backgroundColor: "#fff",
      border: "1px solid #fafafa",
      "&:hover": {
        boxShadow: "0 0 0 1px rgba(64, 110, 181, 0)",
      },
      "&.airbnb-bar": {
        height: 1,
        width: 1,
        backgroundColor: "transparent",
        marginLeft: 1,
        marginRight: 1,
      },
    },
    "& .MuiSlider-track": {
      height: 1,
      backgroundColor: "transparent",
      border: "1px solid transparent",
    },
    "& .MuiSlider-rail": {
      color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
      opacity: theme.palette.mode === "dark" ? undefined : 1,
      height: 1,
    },
  }));

  function ThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.5" cy="10.5" r="10" stroke="#DEDEDE" />
          <line
            x1="10.4473"
            y1="5.52631"
            x2="10.4473"
            y2="15.4737"
            stroke="black"
          />
          <line
            x1="13.7627"
            y1="6.76974"
            x2="13.7627"
            y2="14.2303"
            stroke="black"
          />
          <line
            x1="7.13184"
            y1="6.76974"
            x2="7.13184"
            y2="14.2303"
            stroke="black"
          />
        </svg>
      </SliderThumb>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PriceSlider
      components={{ Thumb: ThumbComponent }}
      getAriaLabel={(index) =>
        index === 0 ? "Minimum price" : "Maximum price"
      }
      defaultValue={[20, 40]}
    />
  );
};

const CatalogAdvancedFilter = ({
  hasReset,
  type,
  onReset = (f) => f,
  onCloseRequest = (f) => f,
}) => {
  const { t, i18n } = useTranslation();
  const search = useSearchMove(type);
  //extend filter
  const populars = useSelector((state) => state.filter.value.populars);
  const floors = useSelector((state) => state.filter.value.floors);
  const numberOfStoreys = useSelector(
    (state) => state.filter.value.numberOfStoreys,
  );
  const buildingType = useSelector((state) => state.filter.value.buildingType);
  const heating = useSelector((state) => state.filter.value.heating);
  const walls = useSelector((state) => state.filter.value.walls);
  const repair = useSelector((state) => state.filter.value.repair);
  const areas = useSelector((state) => state.filter.value.totalArea);
  const livingAreas = useSelector((state) => state.filter.value.livingArea);
  const kitchenAreas = useSelector((state) => state.filter.value.kitchenArea);
  //extend filter
  const previousTotal = useSelector((state) => state.previousResults.total);
  const prices = useSelector((state) => state.filter.value.prices);

  ///selected
  // price rooms populars floors numberOfStoreys buildingType heating walls repair
  const selectedPrices = useSelector(
    (state) => state.selectedFilterData.value.price,
  );
  const selectedArea = useSelector(
    (state) => state.selectedFilterData.value.totalArea,
  );
  const selectedLivingArea = useSelector(
    (state) => state.selectedFilterData.value.livingArea,
  );
  const selectedKitchenArea = useSelector(
    (state) => state.selectedFilterData.value.kitchenArea,
  );

  const dispatch = useDispatch();
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );

  return (
    <div className="advanced-filter--wrap" style={{ zIndex: "5" }}>
      <div className="advanced-filter--head">
        <button className="close-action" onClick={() => onCloseRequest()}>
          {t("Приховати")}{" "}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.31348 6.31253L17.6887 17.6877"
              stroke="#242328"
              strokeLinejoin="round"
            />
            <path
              d="M6.31348 17.6875L17.6887 6.31228"
              stroke="#242328"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      <div className="advanced-filter--content">
        <div className="advanced-filter--content_head">
          <h3>{t("Фільтри")}</h3>
        </div>

        <div className="advanced-filter--content_form webkit-custom-scroll">
          <div className="filter-advanced-fields">
            <FilterButtonGroup
              options={populars}
              title={t("Популярні запити")}
              callbackChange={(data) => {
                const selectedSlugs = data.map((e) => e.slug).join(",");
                dispatch(setPopulars(selectedSlugs));
              }}
              keyName={"populars"}
              hasReset={hasReset}
            />

            <div className="filter-advanced-fields__row">
              <RangeFieldsetBlock
                title={t("Вартість")}
                handlerMessage={(valueMin, valueMax) =>
                  `$${valueMin} - $${valueMax}`
                }
                values={selectedPrices}
                baseValues={prices}
                dispatcherFn={setPrice}
                hasReset={hasReset}
              />

              <RangeFieldsetBlock
                title={t("Загальна площа")}
                handlerMessage={(valueMin, valueMax) => {
                  return (
                    <React.Fragment>
                      {valueMin}м<sup>2</sup> - {valueMax}м<sup>2</sup>
                    </React.Fragment>
                  );
                }}
                values={selectedArea}
                baseValues={areas}
                dispatcherFn={setArea}
                hasReset={hasReset}
              />
            </div>

            <div className="filter-advanced-fields__row">
              <RangeFieldsetBlock
                title={t("Житлова площа")}
                handlerMessage={(valueMin, valueMax) => {
                  return (
                    <React.Fragment>
                      {valueMin}м<sup>2</sup> - {valueMax}м<sup>2</sup>
                    </React.Fragment>
                  );
                }}
                values={selectedLivingArea}
                baseValues={livingAreas}
                dispatcherFn={setLivingArea}
                hasReset={hasReset}
              />

              <RangeFieldsetBlock
                title={t("Площа кухні")}
                handlerMessage={(valueMin, valueMax) => {
                  return (
                    <React.Fragment>
                      {valueMin}м<sup>2</sup> - {valueMax}м<sup>2</sup>
                    </React.Fragment>
                  );
                }}
                values={selectedKitchenArea}
                baseValues={kitchenAreas}
                dispatcherFn={setKitchenArea}
                hasReset={hasReset}
              />
              {/*<div className="fields__cell">*/}
              {/*    <div className="range-fieldset">*/}
              {/*        <div className="fields__cell-label">Жилая площадь</div>*/}

              {/*        <RangeFieldset />*/}
              {/*    </div>*/}
              {/*</div>*/}

              {/*<div className="fields__cell">*/}
              {/*    <div className="range-fieldset">*/}
              {/*        <div className="fields__cell-label">Площадь кухни</div>*/}

              {/*        <RangeFieldset />*/}
              {/*    </div>*/}
              {/*</div>*/}
            </div>

            <div className="filter-advanced-fields__row">
              <FilterButtonGroup
                options={floors}
                title={t("Поверх")}
                callbackChange={(data) => {
                  const selectedSlugs = data.map((e) => e.slug).join(",");
                  dispatch(setFloors(selectedSlugs));
                }}
                keyName={"floors"}
                hasReset={hasReset}
              />

              <FilterButtonGroup
                options={numberOfStoreys}
                title={t("Кількість поверхів")}
                callbackChange={(data) => {
                  const selectedSlugs = data.map((e) => e.slug).join(",");
                  dispatch(setNumberOfStoreys(selectedSlugs));
                }}
                keyName={"numberOfStoreys"}
                hasReset={hasReset}
              />
            </div>

            <FilterButtonGroup
              options={buildingType}
              title={t("Тип будівлі")}
              callbackChange={(data) => {
                const selectedSlugs = data.map((e) => e.slug).join(",");
                dispatch(setBuildingType(selectedSlugs));
              }}
              keyName={"buildingType"}
              hasReset={hasReset}
            />

            <div className="filter-advanced-fields__row">
              <FilterButtonGroup
                options={heating}
                title={t("Опалення")}
                callbackChange={(data) => {
                  const selectedSlugs = data.map((e) => e.slug).join(",");
                  dispatch(setHeating(selectedSlugs));
                }}
                keyName={"heating"}
                hasReset={hasReset}
              />

              <FilterButtonGroup
                options={walls}
                title={t("Стіни")}
                callbackChange={(data) => {
                  const selectedSlugs = data.map((e) => e.slug).join(",");
                  dispatch(setWalls(selectedSlugs));
                }}
                keyName={"walls"}
                hasReset={hasReset}
              />
            </div>

            <FilterButtonGroup
              options={repair}
              title={t("Ремонт")}
              callbackChange={(data) => {
                const selectedSlugs = data.map((e) => e.slug).join(",");
                dispatch(setRepair(selectedSlugs));
              }}
              keyName={"repair"}
              hasReset={hasReset}
            />

            <br></br>
          </div>
        </div>

        <div className="advanced-filter--content_footer q2">
          <div className="advanced-filter--content_footer--cell">
            <button className="show_all" onClick={search.moveToFilter}>
              {t("Показати")} <span>{previousTotal}</span> {t("пропозиції")}{" "}
            </button>
          </div>

          <div className="advanced-filter--content_footer--cell">
            <button
              onClick={() => {
                dispatch(setResetSelectedFilter({}));
                onReset();
              }}
              className="reset_filter"
            >
              {t("Очистити фільтри")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CatalogAdvancedFilter;
