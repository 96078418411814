import React, { useState, Fragment, useRef, useEffect } from "react";

import AppHeader from "/src/components/AppHeader";
import AppFooter from "/src/components/AppFooter";
import BreadCrumbs from "/src/components/ui/BreadCrumbs";
import RoomCarousel from "/src/components/Room/RoomCarousel";
import { roomContainer } from "/src/containers/roomContainer";
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
// import GoogleMapPlaceItem from "../../components/GoogleMapComponent/GoogleMapPlaceItem";
import {
  AddressIcon,
  BuildingTypeIcon,
  CountRoomsIcon,
  TotalAreaIcon,
} from "../../components/vector/RoomIcons";
import Slider from "react-slick";
import AddToFavorites from "../../components/ui/AddToFavorites";
import { WindowWidth } from "../../globals";
import APP_CONFIG from "../../config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  putObject,
  putObjectBreadCrumbs,
  putObjectSimilar,
} from "../../store/reducers/object";
import DialogBooking from "../../components/Dialog/DialogBooking";
import { realtorContainer } from "../../containers/realtorContainer";
import clsx from "clsx";
import Rating from "../../components/ui/rating/Rating";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";
import startsWith from "lodash.startswith";
import { apiGet } from "../../utils/api";
import RatingLabel from "../../components/ui/rating/RatingLabel";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#F1623D",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#F1623D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F1623D",
    },
  },
});

const RealtorExperience = ({ data = [] }) => {
  const { t, i18n } = useTranslation();
  return data
    .map((e) => e.name.split("||"))
    .map((e) => {
      return (
        <li className={"expirence-element"}>
          <div className={"expirence-element--name"}>{t(e[0])}</div>{" "}
          <div className={"expirence-element--value"}>{t(e[1])}</div>
        </li>
      );
    });
};
const ApartmentSecondaryItem = ({ item = {} }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="home-page--best-deals-list--item">
      <div className="home-page--best-deals-list--item_thumbnail">
        <img src={item.image.mini} alt="deals title" />

        <div className="home-page--best-deals-list--item_thumbnail-mask">
          <div className="like-cell">
            <AddToFavorites id={item.id} />
          </div>

          <div className="info-cell">
            <div className="price">
              {item.price_for_meter} $/м²
              <div className="total">{item.price} $</div>
            </div>

            <div className="id-label">ID {item.id}</div>
          </div>
        </div>
      </div>

      <div className="home-page--best-deals-list--item_details">
        <Link to={`/${item.type}/` + item.slug} preventScrollReset={true}>
          <h3>{item.title}</h3>
        </Link>

        <div className="location">
          {item.district_name}, {item.city_name}
        </div>

        <ul className="details-list">
          <li className="details-list--item">
            {item.cnt_room} {t("кімнати")}
          </li>

          <li className="details-list--item">{item.total_area} м2</li>

          <li className="details-list--item">
            {item.floor} {t("поверх з")} {item.max_floor}
          </li>

          <li className="details-list--item">{item.building_type_name}</li>
        </ul>
      </div>
    </div>
  );
};

const ApartmentSecondaryItemMobile = ({ item = {} }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="home-page--best-deals-mobile-list--item">
      <div className="home-page--best-deals-list--item">
        <div className="home-page--best-deals-list--item_thumbnail">
          <img src={item.image.mini} alt="deals title" />

          <div className="home-page--best-deals-list--item_thumbnail-mask">
            <div className="like-cell">
              <AddToFavorites id={item.id} />
            </div>

            <div className="info-cell">
              <div className="price">
                {item.price_for_meter} $/м²
                <div className="total">{item.price} $</div>
              </div>

              <div className="id-label">ID {item.id}</div>
            </div>
          </div>
        </div>

        <div className="home-page--best-deals-list--item_details">
          <Link to={`/${item.type}/` + item.slug} preventScrollReset={true}>
            <h3>{item.title}</h3>
          </Link>

          <div className="location">
            {item.district_name}, {item.city_name}
          </div>

          <ul className="details-list">
            <li className="details-list--item">
              {item.cnt_room} {t("кімнати")}
            </li>

            <li className="details-list--item">{item.total_area} м2</li>

            <li className="details-list--item">
              {item.floor} {t("поверх з")} {item.max_floor}
            </li>

            <li className="details-list--item">{item.building_type_name}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
const ApartmentSecondary = ({ mobile = false, data = [] }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="home-page--best-deals-tab">
      {!mobile ? (
        <div className="home-page--best-deals-list">
          {data &&
            data.map((obj, index) => {
              return <ApartmentSecondaryItem key={index} item={obj} />;
            })}
        </div>
      ) : (
        <div className="home-page--best-deals-mobile-list">
          <Slider {...settings} key={"mob_deals"}>
            {data &&
              data.map((obj, index) => {
                return <ApartmentSecondaryItemMobile key={index} item={obj} />;
              })}
          </Slider>
        </div>
      )}
    </div>
  );
};
const ItemReview = ({ data = [] }) => {
  return data.reverse().map((e) => {
    return (
      <li>
        <div className="user">
          <div className="user-block">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse
                cx="24.6493"
                cy="24.6609"
                rx="24.6493"
                ry="24.6609"
                transform="matrix(0.999904 -0.0138556 0.0143213 0.999897 0 0.683105)"
                fill="#979797"
                fill-opacity="0.5"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 18.5C17 16.5109 17.7902 14.6032 19.1967 13.1967C20.6032 11.7902 22.5109 11 24.5 11C26.4891 11 28.3968 11.7902 29.8033 13.1967C31.2098 14.6032 32 16.5109 32 18.5C32 20.4891 31.2098 22.3968 29.8033 23.8033C28.3968 25.2098 26.4891 26 24.5 26C22.5109 26 20.6032 25.2098 19.1967 23.8033C17.7902 22.3968 17 20.4891 17 18.5ZM9 36.5C9 35.36 9.58 34.3 10.6 33.38C11.808 32.346 13.1893 31.5335 14.68 30.98C17.96 29.66 21.88 29 24.5 29C27.1 29 31.04 29.66 34.3 30.96C35.96 31.62 37.4 32.44 38.42 33.36C39.42 34.32 40 35.36 40 36.5V41C40 41.2652 39.8946 41.5196 39.7071 41.7071C39.5196 41.8946 39.2652 42 39 42H10C9.73478 42 9.48043 41.8946 9.29289 41.7071C9.10536 41.5196 9 41.2652 9 41V36.5Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="user-block">
            <span className={"user-block--name"}>{e.user}</span>{" "}
            <span className={"user-block--stars"}>
              <Rating rating={e.rating} />
            </span>
            <br />
            <span className={"user-block--time"}>
              {moment(e.created_at).calendar()}
            </span>
          </div>
        </div>
        <div className={"user-review"}>{e.text}</div>
      </li>
    );
  });
};

const RealtorP = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const descriptionRef = useRef(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.object.data)
  const [data, setData] = useState({});
  const breadCrumbs = useSelector((state) => state.object.breadCrumbs);
  const similar = useSelector((state) => state.object.similar);
  const [textError, setTextError] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [phone, setPhone] = useState("0");
  const [isPhoneCompleted, setIsPhoneCompleted] = useState(false);
  const changeInputValue = (event) => {
    setTextValue(event.target.value);
    if (event.target.value.length >= 3) {
      setTextError(false);
    }
  };
  const changePhoneValue = (value) => {
    setPhone(value);
    if (value.length >= 12) {
      setIsPhoneCompleted(true);
    } else {
      setIsPhoneCompleted(false);
    }
  };
  function handleSubmit(event) {
    event.preventDefault();
    if (textValue.length < 3) {
      setTextError(true);
      return;
    }
    if (!isPhoneCompleted) {
      return;
    }

    sendRequest();
  }
  const sendRequest = () => {
    const params = {
      name: textValue,
      phone: phone,
      email: "",
      modelId: "",
      responsibleId: data.id,
    };
    const result = apiGet("user-action-booking-view", params)
      .then((data) => data.data)
      .then((response) => {
        const { status, serverResponce } = response;
        let ojson = JSON.parse(serverResponce.replace(/'/g, '"'));
        const { error_message, error } = ojson;
        if (error_message === "Лид добавлен") {
          if (status) {
            history.push({
              pathname: "/user-success-page",
              // search: '?'+query,  // query string
              state: {
                // location state
                update: true,
              },
            });
          } else {
            alert("Error");
          }
        }
      });
  };

  //todo change to use
  const scrollTopAction = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  let { slug } = useParams();
  useEffect(() => {
    props.loadData(slug).then((e) => {
      scrollTopAction();
      setData(e);
      dispatch(
        putObjectBreadCrumbs([
          ...APP_CONFIG.breadCrumbs.defaultVtorichka,
          { title: e.title, path: "#" },
        ]),
      );
      dispatch(putObjectSimilar(e.objects.data));
    });
  }, []);

  return (
    <div className="catalog-page realtor-page">
      <AppHeader />

      <div className="realtor-page--layout">
        <div className="room--cell">
          <BreadCrumbs linkChain={breadCrumbs} />

          <div className="primary-room--carousel">
            <div className="top-sale--cell cell-left">
              <div className="stiky-wrap-main">
                <div className="top-sale">
                  {/*<RatingLabel />*/}
                  <div className="realtor">
                    <div className="realtor-thumbnail">
                      <div className="wrp-logo-rieltor">
                        <img
                          width={141}
                          height={141}
                          src={data.photo}
                          alt={data.title}
                        />
                        <RatingLabel rating={data.middleRating} />
                      </div>
                    </div>

                    <div className="realtor-info">
                      <h3>{data.title}</h3>

                      <div className="job-title">{t("Ріелтор об'єкту")}</div>

                      <div className="actions">
                        <DialogBooking
                          model={data}
                          reviewModel={data}
                          textOrder={"Замовити дзвінок"}
                        />
                      </div>

                      <a
                        className="phones"
                        href={"tel:" + data.responsible_phone}
                      >
                        {data.responsible_phone}
                      </a>
                    </div>
                  </div>
                  <div className={"promo"}>
                    <p>{data.description}</p>
                  </div>

                  <div className={"promo-info"}>
                    <p>{t("Ріелторські послуги при купівлі та продажу")}</p>

                    <RealtorExperience data={data.experiences} />
                  </div>
                </div>
                <div className="top-form">
                  <div className="top-form--realtor">
                    <div className="top-form--realtor-text">
                      <p>
                        {t(
                          "Залишіть Ваш номер телефону та Вам передзвонять протягом 5 хвилин",
                        )}
                      </p>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="top-form--realtor-form">
                        <CssTextField
                          autoFocus
                          error={textError}
                          margin="dense"
                          id="name"
                          label={t("Ваше ім'я")}
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={textValue}
                          onChange={changeInputValue}
                        />
                        <br />
                        <br />
                        <br />
                        <PhoneInput
                          variant="outlined"
                          error={textError}
                          regions={"europe"}
                          country={"ua"}
                          onlyCountries={["ua"]}
                          disableSearchIcon={true}
                          value={phone}
                          onChange={changePhoneValue}
                          specialLabel={""}
                          placeholder={"380 (00) 000 00 00"}
                          isValid={(inputNumber, country, countries) => {
                            const isValid = countries.some((country) => {
                              return (
                                startsWith(inputNumber, country.dialCode) ||
                                startsWith(country.dialCode, inputNumber)
                              );
                            });
                            if (
                              isPhoneCompleted !== isValid &&
                              phone.length >= 12
                            ) {
                              setIsPhoneCompleted(isValid);
                            }
                            return isValid;
                          }}
                        />
                        <div className="top-sale top-sale--dialog">
                          <div className="viewing-action-row">
                            <button
                              disabled={!isPhoneCompleted}
                              className="viewing-action"
                            >
                              {" "}
                              {t("Надіслати")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<RoomCarousel items={data.images} model={data} key={data.id}/>*/}

          <div className="realtor-page--anchors-nav">
            <button
              onClick={() => setActiveTab(0)}
              className={
                activeTab == 0 ? "anchor-action active" : "anchor-action"
              }
            >
              {" "}
              {t("Відгуки")}
            </button>
            {/**/}
            <button
              onClick={() => setActiveTab(1)}
              className={
                activeTab == 1 ? "anchor-action active" : "anchor-action"
              }
            >
              {t("Мої об'єкти")}
            </button>

            {/*<button onClick={() => scrollAnchorAction(2, locationsRef)} className={currentAnchor == 2 ? 'anchor-action active' : 'anchor-action'}>{t("Розташування")}</button>*/}
          </div>

          {activeTab === 0 && (
            <div ref={descriptionRef} className="anchor-block description-room">
              <div className="description-text">
                <ul className={"noDot"}>
                  <ItemReview data={data.reviews} />
                </ul>
              </div>
            </div>
          )}

          <div
            ref={descriptionRef}
            className={clsx("anchor-block description-room", {
              hidden: activeTab === 0,
            })}
          >
            <div className="description-text">
              <div className="similar-objects">
                <div className="container">
                  <div className="home-page--best-deals-tabs">
                    <div className="home-page--best-deals-tabs__head"></div>
                    <div className="home-page--best-deals-tabs__content">
                      <ApartmentSecondary
                        mobile={WindowWidth() < 768}
                        data={similar}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppFooter />
    </div>
  );
};
export default realtorContainer(RealtorP);
