import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import { useDispatch, useSelector } from "react-redux";
import { setDistrict, setRooms } from "../../store/reducers/selectedFilterData";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";

const DistrictItemsSelect = ({ type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.filter.value.districts);
  const [selectedItems, setSelectedItems] = useState(null);
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  const search = useSearchMove(type);
  const handlerSelectRoomsChange = (event, newValue) => {
    dispatch(setDistrict(newValue?.id ?? ""));
    let overriddenData = { district: newValue?.id ?? "" };
    search.moveToFilterOverridden(overriddenData);
  };
  useEffect(() => {
    if (items.length && selectedFilterData.district) {
      const filtered = items.find(
        (e) => e.id === parseInt(selectedFilterData.district),
      );
      setSelectedItems(filtered);
    }
  }, [items, selectedFilterData]);
  return (
    <React.Fragment>
      <SelectItems
        items={items}
        selected={selectedItems}
        label={t("Район")}
        multiple={false}
        callbackChange={handlerSelectRoomsChange}
      />
    </React.Fragment>
  );
};

DistrictItemsSelect.propTypes = {};

export default DistrictItemsSelect;
