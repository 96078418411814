import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BreadCrumbs = ({ linkChain = false }) => {
  const { t, i18n } = useTranslation();
  if (!linkChain) return false;

  return (
    <div className="bread-crumb--wrap">
      <ul className="link-list">
        {linkChain.map((obj, i) => {
          return (
            <li key={obj.title+i} className="link-item">
              <Link to={obj.path}>{t(obj.title)}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default BreadCrumbs;
