import React from "react";
import PropTypes from "prop-types";

const Rating = ({ rating = 5 }) => {
  const content = [];

  for (let i = 0; i < rating; i++) {
    content.push(
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.05673 0.393548C6.20969 -0.0601276 6.85136 -0.0601289 7.00432 0.393546L8.16308 3.8304C8.23163 4.03373 8.42229 4.17065 8.63687 4.17065H12.3462C12.8351 4.17065 13.0334 4.79987 12.633 5.08024L9.66426 7.15887C9.48251 7.28613 9.40636 7.51795 9.47725 7.7282L10.6186 11.1136C10.7729 11.571 10.2535 11.9598 9.85807 11.6829L6.8173 9.55382C6.64512 9.43326 6.41592 9.43326 6.24374 9.55382L3.20297 11.6829C2.80754 11.9598 2.28817 11.571 2.4424 11.1136L3.5838 7.7282C3.65468 7.51795 3.57853 7.28613 3.39678 7.15887L0.428034 5.08024C0.0276045 4.79987 0.225986 4.17065 0.714812 4.17065H4.42417C4.63875 4.17065 4.82941 4.03373 4.89797 3.8304L6.05673 0.393548Z"
          fill="#F1623D"
        />
      </svg>,
    );
  }
  return content;
  // return (
  //
  //     <div>
  //
  //     </div>
  // );
};

Rating.propTypes = {};

export default Rating;
