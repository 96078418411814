import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { setItem } from "/src/store/reducers/activeItem";
import { useTranslation } from "react-i18next";
import AddToFavorites from "./ui/AddToFavorites";
import FloorNumberText from "./common/FloorNumberText";

const SearchResultsItem = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const itemId = useSelector((state) => state.activeItem.itemId);
  const [description, setDescription] = useState("");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const maxLength = 400;

    if (props.model.description.length > maxLength) {
      setDescription(props.model.description.slice(0, maxLength) + "...");
    } else {
      setDescription(props.model.description);
    }
  }, []);

  return (
    <div
      className={clsx("search-results-item", {
        selected: itemId == props.model.id,
      })}
    >
      <div className="search-results-item__thumbnail">
        <div className="thumbnail--wrap">
          <img src={props.model.image.mini} alt="deals title" />
        </div>

        <div
          className="mask-layer"
          onClick={() => {
            history.push({
              pathname: `/${props.model.type}/` + props.model.slug,
              state: { update: true },
            });
          }}
        >
          <div className="labels-list">
            <div className="labels-list--item">
              <div className="label green"> {t("В продажі")}</div>
            </div>
            {/*<div className='labels-list--item'>*/}
            {/*    <div className='label red'>Срочная продажа</div>*/}
            {/*</div>*/}

            {/*<div className='labels-list--item'>*/}
            {/*    <div className='label yellow'>Эксклюзив</div>*/}
            {/*</div>*/}

            {/*<div className='labels-list--item'>*/}
            {/*    <div className='label blue'>Обмен</div>*/}
            {/*</div>*/}

            {/*<div className='labels-list--item'>*/}
            {/*    <div className='label'>Торг</div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      <div className="search-results-item__pimary-info">
        <div className="pimary-info--head">
          <div className="price-cell">{props.model.price} $</div>

          <div className="cost-of-cubic">
            {props.model.price_for_meter} $/м²
          </div>

          <div className="id-item">ID {props.model.id}</div>
        </div>

        <div className="pimary-info--adress">
          <h3>{props.model.title}</h3>

          <p>
            {props.model.district_name}, {props.model.city_name}
          </p>
        </div>

        <div
          className="pimary-info--description messageDescription"
          onClick={() => {
            dispatch(setItem(props.model.id));
          }}
        >
          {props.model.description}
        </div>

        <div className="pimary-info--options">
          <div className="pimary-info--options_list">
            {props.model.cnt_room > 0 && (
              <div className="pimary-info--options_list-item">
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#3F6DB5" />
                </svg>
                {props.model.cnt_room} {t("кімнати")}
              </div>
            )}
            <div className="pimary-info--options_list-item">
              <svg
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="3" cy="3" r="3" fill="#3F6DB5" />
              </svg>
              {props.model.total_area} м2
            </div>

            {props.model.type !== "plots" && (
              <div className="pimary-info--options_list-item">
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#3F6DB5" />
                </svg>
                <FloorNumberText model={props.model}  />
              </div>
            )}

            {props.model.building_type_name && (
              <div className="pimary-info--options_list-item">
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#3F6DB5" />
                </svg>
                {props.model.building_type_name}
              </div>
            )}

            {props.model.type === "plots" && (
              <div className="pimary-info--options_list-item">
                <svg
                  width="6"
                  height="6"
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3" r="3" fill="#3F6DB5" />
                </svg>
                {props.model.housePlots}
              </div>
            )}
          </div>
        </div>

        <div className="pimary-info--footer">
          <button
            className="show_more"
            onClick={() => {
              history.push({
                pathname: `/${props.model.type}/` + props.model.slug,
                state: { update: true },
              });
            }}
          >
            {t("Детальніше")}
          </button>

          <AddToFavorites
            id={props.model.id}
          />
        </div>
      </div>
    </div>
  );
};
SearchResultsItem.propTypes = {
  // onClick: PropTypes.func,
  model: PropTypes.object.isRequired,
};
export default SearchResultsItem;
