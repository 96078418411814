import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { getDefaultById } from "/src/utils/filterUtils";
import { useDispatch, useSelector } from "react-redux";
import { setRooms } from "/src/store/reducers/selectedFilterData";
const NumberOfRooms = (props) => {
  const [selected, setSelected] = useState(props.selected);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.hasReset) {
      setSelected([]);
    }
  }, [props.hasReset]);
  return (
    <div className="number-of-room">
      {props.options.map((obj) => {
        const isSelected = getDefaultById(obj.id, selected);
        return (
          <button
            key={obj.id}
            className={clsx("number-of-room--item", { selected: isSelected })}
            onClick={() => {
              const newData = selected.filter((e) => e.id != obj.id);
              if (!isSelected) {
                newData.push(obj);
              }
              setSelected(newData);
              props.callbackChange(newData);
              //todo add sort
              const selectedSlugs = newData.map((e) => e.slug).join("-");

              // dispatch(setRooms(selectedSlugs))
              dispatch(props.dispatchFn(selectedSlugs));
            }}
          >
            {obj.label}
          </button>
        );
      })}
    </div>
  );
};
NumberOfRooms.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  callbackChange: PropTypes.func.isRequired,
  dispatchFn: PropTypes.func.isRequired,
  hasReset: PropTypes.number.isRequired,
};
export default NumberOfRooms;
