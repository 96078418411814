import React, { useState, Fragment, useRef, useEffect } from "react";

import AppHeader from "/src/components/AppHeader";
import AppFooter from "/src/components/AppFooter";
import BreadCrumbs from "/src/components/ui/BreadCrumbs";
import RoomCarousel from "/src/components/Room/RoomCarousel";
import { roomContainer } from "/src/containers/roomContainer";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import GoogleMapPlaceItem from "../../components/GoogleMapComponent/GoogleMapPlaceItem";
import {
  AddressIcon,
  BuildingTypeIcon,
  CountRoomsIcon,
  TotalAreaIcon,
} from "../../components/vector/RoomIcons";
import APP_CONFIG from "../../config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  putObject,
  putObjectBreadCrumbs,
  putObjectSimilar,
} from "../../store/reducers/object";
import DialogBooking from "../../components/Dialog/DialogBooking";
import SimilarSection from "../../components/common/SimilarBlock/SimilarSection";
import RealtorObjectSocial from "../../components/Realtor/RealtorObjectSocial";

const Commerce = (props) => {
  const [modelType] = useState("commerce");
  const [currentAnchor, setCurrentAnchor] = useState(0);
  const descriptionRef = useRef(null);
  const characteristicsRef = useRef(null);
  const locationsRef = useRef(null);
  const { t, i18n } = useTranslation();
  let location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.object.data);
  const breadCrumbs = useSelector((state) => state.object.breadCrumbs);
  const similar = useSelector((state) => state.object.similar);
  const [isMounted, setIsMounted] = useState(false);

  //     // ga.send(["pageview", location.pathname]);
  // }, [location]);
  const scrollAnchorAction = (anchor, ref) => {
    setCurrentAnchor(anchor);
    ref !== null &&
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  //todo change to use
  const scrollTopAction = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  let { slug } = useParams();
  useEffect(() => {
    props.loadData(slug).then((e) => {
      if (!isMounted) {
        scrollTopAction();
        setIsMounted(true);
      }
      dispatch(putObject(e));
      dispatch(
        putObjectBreadCrumbs([
          ...APP_CONFIG.breadCrumbs.defaultCommerce,
          { title: e.title, path: "#" },
        ]),
      );
      const { similar } = e;
      dispatch(putObjectSimilar(similar.data));
    });
  }, [i18n.language]);

  useEffect(() => {
    props.loadData(slug).then((e) => {
      scrollTopAction();
      setIsMounted(true);
      dispatch(putObject(e));
      dispatch(
        putObjectBreadCrumbs([
          ...APP_CONFIG.breadCrumbs.defaultCommerce,
          { title: e.title, path: "#" },
        ]),
      );
      const { similar } = e;
      dispatch(putObjectSimilar(similar.data));
    });
  }, [location]);

  useEffect(() => {
    const slider = document.querySelector(".characteristics-scroll");
    let isDown = false;
    let startX;
    let scrollLeft;

    const mouseDown = (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    const mouseLeave = (e) => {
      isDown = false;
      slider.classList.remove("active");
    };
    const mouseUp = (e) => {
      isDown = false;
      slider.classList.remove("active");
    };
    const mouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1.2; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    };
    slider.addEventListener("mousedown", mouseDown);
    slider.addEventListener("mouseleave", mouseLeave);
    slider.addEventListener("mouseup", mouseUp);
    slider.addEventListener("mousemove", mouseMove);
    return () => {
      slider.removeEventListener("mousedown", mouseDown);
      slider.removeEventListener("mouseleave", mouseLeave);
      slider.removeEventListener("mouseup", mouseUp);
      slider.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  return (
    <div className="catalog-page room-page">
      <AppHeader />

      <div className="room-page--layout">
        <div className="room--cell">
          <BreadCrumbs linkChain={breadCrumbs} />

          <RoomCarousel items={data.images} model={data} key={data.id} />

          <div className="room-page--anchors-nav">
            <button
              onClick={() => scrollAnchorAction(0, descriptionRef)}
              className={
                currentAnchor == 0 ? "anchor-action active" : "anchor-action"
              }
            >
              {" "}
              {t("Опис")}
            </button>

            <button
              onClick={() => scrollAnchorAction(1, characteristicsRef)}
              className={
                currentAnchor == 1 ? "anchor-action active" : "anchor-action"
              }
            >
              {t("Характеристики")}
            </button>

            <button
              onClick={() => scrollAnchorAction(2, locationsRef)}
              className={
                currentAnchor == 2 ? "anchor-action active" : "anchor-action"
              }
            >
              {t("Розташування")}
            </button>
          </div>

          <div ref={descriptionRef} className="anchor-block description-room">
            <div className="anchor-block-title">{t("Опис")}</div>

            <div className="description-text">
              <p>{data.description}</p>
            </div>
          </div>

          <div
            ref={characteristicsRef}
            className="anchor-block characteristics-room"
          >
            <div className="anchor-block-title">{t("Характеристики")}</div>
            <div className="characteristics-scroll--wrap">
              <div className="characteristics-scroll--mask"></div>

              <div className="characteristics-scroll">
                <div className="characteristics-row">
                  <div className="characteristics-cell">
                    <div className="characteristics-cell--title">
                      {t("Основні")}
                    </div>

                    <div className="characteristics">
                      <div className="title">{t("Код об'єкта")}</div>
                      <div className="value">{data.id}</div>
                    </div>

                    <div className="characteristics">
                      <div className="title">{t("Область")}</div>
                      <div className="value">{data.region_name}</div>
                    </div>

                    <div className="characteristics">
                      <div className="title"> {t("Населений пункт")}</div>
                      <div className="value">{data.city_name}</div>
                    </div>

                    {data.total_area && <div className="characteristics">
                      <div className="title"> {t("Загальна площа")}</div>
                      <div className="value">{data.total_area} м2</div>
                    </div>
                    }

                    {data.condition_name?.trim() && (
                        <div className="characteristics">
                          <div className="title">{t("Ремонт")}</div>
                          <div className="value">{data.condition_name}</div>
                        </div>
                    )}






                    {data.floor &&<div className="characteristics">
                      <div className="title"> {t("Поверх")}</div>
                      <div className="value">{data.floor}</div>
                    </div>
                    }

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'> {t("Кількість поверхів")}</div>*/}
                    {/*    <div className='value'>{data.max_floor}</div>*/}
                    {/*</div>*/}

                    {data.wall_name && <div className="characteristics">
                      <div className="title"> {t("Стіни")}</div>
                      <div className="value">{data.wall_name}</div>
                    </div>
                    }
                  </div>
                  <div className="characteristics-cell">
                    <div className="characteristics-cell--title">
                      {" "}
                      {t("Про комерцію")}
                    </div>

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'> {t("Кімнатність")}</div>*/}
                    {/*    <div className='value'>{data.cnt_room}</div>*/}
                    {/*</div>*/}

                    {data.ceiling_height &&<div className="characteristics">
                      <div className="title"> {t("Висота стелі")}</div>
                      <div className="value">{data.ceiling_height}</div>
                    </div>
                    }

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'> {t("Санвузол")}</div>*/}
                    {/*    <div className='value'>{data.bathroom}</div>*/}
                    {/*</div>*/}

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'> {t("Балкон")}</div>*/}
                    {/*    <div className='value'>{data.balcon}</div>*/}
                    {/*</div>*/}

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'> {t("Стан балкону")}</div>*/}
                    {/*    <div className='value'>{data.balcon_equipment}</div>*/}
                    {/*</div>*/}

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'> {t("Вікна")}</div>*/}
                    {/*    <div className='value'>{data.carpentry}</div>*/}
                    {/*</div>*/}

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'> {t("Сторони світу")}</div>*/}
                    {/*    <div className='value'>{data.wordSides}</div>*/}
                    {/*</div>*/}

                    {data.heating && <div className="characteristics">
                      <div className="title"> {t("Опалення")}</div>
                      <div className="value">{data.heating}</div>
                    </div>
                    }
                    {data.view && <div className="characteristics">
                      <div className="title"> {t("Вид")}</div>
                      <div className="value">{data.view}</div>
                    </div>
                    }
                    <div className="characteristics">
                      <div className="title"> {t("Тераса")}</div>
                      <div className="value">{data.terrace ? t("Так") : t("Ні")}</div>
                    </div>

                    {data.purpose && <div className="characteristics">
                      <div className="title"> {t("Призначення")}</div>
                      <div className="value">{data.purpose }</div>
                    </div>
                    }

                  </div>
                  <div className="characteristics-cell">
                    <div className="characteristics-cell--title">
                      {" "}
                      {t("Про будинок")}
                    </div>

                    {data.building_type_name &&<div className="characteristics">
                      <div className="title"> {t("Тип будівлі")}</div>
                      <div className="value">{data.building_type_name}</div>
                    </div>
                    }

                    {data.wall_name && <div className="characteristics">
                      <div className="title"> {t("Матеріал будівництва")}</div>
                      <div className="value">{data.wall_name}</div>
                    </div>
                    }

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'>Лифт</div>*/}
                    {/*    <div className='value'>3</div>*/}
                    {/*</div>*/}

                    {data.yards && <div className="characteristics">
                      <div className="title"> {t("Двір")}</div>
                      <div className="value">{data.yards}</div>
                    </div>
                    }

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'>Название ЖК</div>*/}
                    {/*    <div className='value'>Гвоздика</div>*/}
                    {/*</div>*/}

                    {/*<div className='characteristics'>*/}
                    {/*    <div className='title'>Застройщик</div>*/}
                    {/*    <div className='value'>Оджилбуд</div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ref={locationsRef} className="anchor-block locations-room">
            <div className="anchor-block-title">{t("Розташування")}</div>

            <div className="map-wrap">
              <GoogleMapPlaceItem model={data} />
            </div>
          </div>
        </div>

        <div className="top-sale--cell">
          <div className="stiky-wrap">
            <div className="labels-list">
              <div className="labels-list--item">
                <div className="label green">{t("В продажі")}</div>
              </div>
            </div>

            <div className="top-sale">
              <h2>{data.title}</h2>

              <div className="adress">
                {data.district_name}, {data.city_name}
              </div>

              <div className="primary-info">
                <div className="primary-info--cell">
                  <div className="price">{data.price} $</div>
                </div>

                <div className="primary-info--cell">
                  {data.price_for_meter} $/м²
                </div>

                <div className="primary-info--cell">
                  <div className="id-room">ID {data.id}</div>

                  {false && (
                    <div className="date hidden">{data.created_at_web}</div>
                  )}
                </div>
              </div>

              <div className="room-exept">{/*<p>{data.description}</p>*/}</div>

              <div className="infographics-tab">
                <div className="infographics-tab--row">
                  <div className="infographics-tab--cell">
                    <div className="infographics">
                      <TotalAreaIcon />
                      <div className="text">
                        <h4>{t("Загальна площа")}:</h4>

                        <div className="strong">{data.total_area} м2</div>
                      </div>
                    </div>
                  </div>

                  <div className="infographics-tab--cell">
                    {/*<div className="infographics">*/}
                    {/*  <CountRoomsIcon />*/}

                    {/*  <div className="text">*/}
                    {/*    <h4>{t("Кількість кімнат")}:</h4>*/}

                    {/*    <div className="strong">*/}
                    {/*      {data.cnt_room} {t("кімнати")}*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="infographics">
                      <BuildingTypeIcon />

                      <div className="text">
                        <h4>{t("Тип будівлі")}:</h4>

                        <div className="strong">{data.building_type_name}</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<div className="infographics-tab--row">*/}
                {/*  <div className="infographics-tab--cell">*/}
                {/*    /!*<div className="infographics">*!/*/}
                {/*    /!*  <AddressIcon />*!/*/}
                {/*    /!*  <div className="text">*!/*/}
                {/*    /!*    <h4>{t("Адреса")}:</h4>*!/*/}

                {/*    /!*    <div className="strong">*!/*/}
                {/*    /!*      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*!/*/}
                {/*    /!*    </div>*!/*/}
                {/*    /!*  </div>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*  </div>*/}

                {/*  <div className="infographics-tab--cell">*/}
                {/*    /!*<div className="infographics">*!/*/}
                {/*    /!*  <BuildingTypeIcon />*!/*/}

                {/*    /!*  <div className="text">*!/*/}
                {/*    /!*    <h4>{t("Тип будівлі")}:</h4>*!/*/}

                {/*    /!*    <div className="strong">{data.building_type_name}</div>*!/*/}
                {/*    /!*  </div>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>

              <div className="realtor">
                <div className="realtor-thumbnail">
                  <img
                    src={data.responsible_photo}
                    alt={data.responsible_name}
                  />
                </div>

                <div className="realtor-info">
                  <h3>{data.responsible_name}</h3>

                  <div className="job-title">{t("Ріелтор об'єкту")}</div>

                  <a className="phones" href={APP_CONFIG.phone.hrefPhone}>
                    {APP_CONFIG.phone.visualPhone}
                  </a>

                  <RealtorObjectSocial data={data} />
                </div>
              </div>

              <DialogBooking model={data} />
              {/*<div className='viewing-action-row'>*/}
              {/*    <button className="viewing-action">{t("Записати на перегляд")}</button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>

      <SimilarSection data={similar} type={modelType} />

      <AppFooter />
    </div>
  );
};
export default roomContainer(Commerce);
