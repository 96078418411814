import React, { useState, useEffect, useRef } from "react";
import ApartmentsNew from "./HomePageBestDealsTabs/ApartmentsNew";
import HousesAndPlots from "./HomePageBestDealsTabs/HousesAndPlots";
import { WindowWidth } from "./../globals";
import { useTranslation } from "react-i18next";
import ApartmentBestDeals from "./HomePageBestDealsTabs/ApartmentBestDeals";
import CommerceBestDeals from "./HomePageBestDealsTabs/CommerceBestDeals";
import Plots from "./HomePageBestDealsTabs/Plots";
import {useHistory} from "react-router-dom";
import {bind} from "lodash/function";


const HotDealsTabs = [
  {
    title: "Квартири втор. ринок",
    key: 1,
    code: 'catalog',
  },
  {
    title: "Комерція",
    key: 4,
    code: 'commerce',
  },
/*  {
    title: "Новобудови",
    key: 2,
  },*/
  {
    title: "Будинки",
    key: 3,
    code: 'house',
  },
  {
    title: "Ділянки",
    key: 5,
    code: 'plots',
  },
];

const settings1 = {
  dots: false,
  centerMode: true,
  // centerPadding: '60px',
  slidesToShow: 3,
  infinite: false,
  initialSlide: 1,
  swipe: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        dots: true,
        swipe: true,
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
  // dots: true,
  // infinite: false,
  // speed: 500,
  // slidesToShow: 2.2,
  // slidesToScroll: 1,
  // initialSlide: 0,
  // responsive: [
  //   {
  //     breakpoint: 600,
  //     settings: {
  //       slidesToShow: 1.5,
  //       slidesToScroll: 1,
  //     },
  //   },
  //   {
  //     breakpoint: 480,
  //     settings: {
  //       slidesToShow: 1.2,
  //       slidesToScroll: 1,
  //     },
  //   },
  // ],
};
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2.2,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomePageBestDeals = () => {
  const [current, setCurrent] = useState(HotDealsTabs[0]);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const moveTo = () => {
    const {code}=current
    history.push(`/${code}`)
  }


  const renderTabContent = () => {
    switch (current.key) {
      case 1:
        return (
          <ApartmentBestDeals
            mobile={WindowWidth() < 768}
            settings={settings}
          />
        );
      case 2:
        return (
          <ApartmentsNew mobile={WindowWidth() < 768} settings={settings} />
        );
      case 3:
        return (
          <HousesAndPlots mobile={WindowWidth() < 768} settings={settings} />
        );
      case 4:
        return (
          <CommerceBestDeals mobile={WindowWidth() < 768} settings={settings} />
        );
      case 5:
        return (
            <Plots mobile={WindowWidth() < 768} settings={settings} />
        );
    }
  };

  return (
    <div className="home-page--best-deals">
      <div className="container">
        <div className="home-page--best-deals__title">
          <div className="cell">
            <h2>
              {t("Найкращі")} {t("пропозиції")}
            </h2>

            <p>{t("На ринку нерухомості Одеси")}</p>
          </div>

          <div className="cell">
            <button className="all-deals" onClick={moveTo}>{t("Усі пропозиції")} </button>
          </div>
        </div>

        <div className="home-page--best-deals-tabs">
          <div className="home-page--best-deals-tabs__head">
            <ul className="home-page--best-deals-tabs__list">
              {HotDealsTabs.map((obj) => {
                return (
                  <li
                    key={obj.key}
                    onClick={setCurrent.bind(this, obj)}
                    className={
                      obj === current
                        ? "home-page--best-deals-tabs__list-item current"
                        : "home-page--best-deals-tabs__list-item"
                    }
                  >
                    {t(obj.title)}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="home-page--best-deals-tabs__content">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePageBestDeals;
