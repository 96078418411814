import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { multiFloorsText } from "../../../utils/translateUtils";

const SimilarItemDetailsList = ({ item }) => {
  const { t, i18n } = useTranslation();
  return (
    <ul className="details-list">
      {item.type === "plots" && (
        <React.Fragment>
          {item.house_plot_square && (
            <li className="details-list--item">
              {item.house_plot_square} {t("сотки")}
            </li>
          )}
          {item.house_land_plots && (
            <li className="details-list--item">{item.house_land_plots}</li>
          )}
        </React.Fragment>
      )}

      {item.type === "apartment" && (
        <React.Fragment>
          {item.cnt_room && (
            <li className="details-list--item">
              {item.cnt_room} {t("кімнати")}
            </li>
          )}

          {item.total_area && (
            <li className="details-list--item">{item.total_area} м2</li>
          )}

          {item.max_floor && (
            <li className="details-list--item">
              {item.floor} {t("поверх з")} {item.max_floor}
            </li>
          )}
          {item.building_type_name && (
            <li className="details-list--item">{item.building_type_name}</li>
          )}
        </React.Fragment>
      )}

      {item.type === "commerce" && (
        <React.Fragment>
          {item.total_area && (
            <li className="details-list--item">{item.total_area} м2</li>
          )}

          {item.floor && (
            <li className="details-list--item">
              {item.floor} {t("поверх")}
            </li>
          )}

          {item.building_type_name && (
            <li className="details-list--item">{item.building_type_name}</li>
          )}
        </React.Fragment>
      )}

      {item.type === "house" && (
        <React.Fragment>
          {item.cnt_room && (
            <li className="details-list--item">
              {item.cnt_room} {t("кімнати")}
            </li>
          )}

          {item.total_area && (
            <li className="details-list--item">{item.total_area} м2</li>
          )}

          {item.max_floor && (
            <li className="details-list--item">
              {item.max_floor} {t(multiFloorsText(item.max_floor))}
            </li>
          )}

          {item.house_land_plots && (
            <li className="details-list--item">{t("ділянка")}</li>
          )}
        </React.Fragment>
      )}
    </ul>
  );
};

SimilarItemDetailsList.propTypes = {};

export default SimilarItemDetailsList;
