import React from "react";
import { useTranslation } from "react-i18next";
import cityViewImage from "../../media/about-us/about_us_city_view.png";
import teamImage from "../../media/about-us/about_us_team_round.png";
import highriseApartmentImage from "../../media/about-us/about_us_higrise_apartment.png";
import dealImage from "../../media/about-us/about_us_deal.png";
import {SellCoinIcon, SellHourglassesIcon, SellPersonIcon, SellSafetyIcon} from "../vector/SellPageIcons";


const OurAchievements = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="about-us-page">
            <div className="about-us-page--why-we">
                <div className="container">
                    <div className="about-us-page--title-why-we">
                        <h2>
                            {t("Dominanta - це")}
                        </h2>
                    </div>
                    <div className="why-we--row">
                        <div className="why-we--cell">
                            <div className="centered-content">
                                <h4 className="why-we--cell_title">12+</h4>
                                <p>{t("років роботи")}</p>
                            </div>
                        </div>
                        <div className="why-we--cell">
                            <div className="centered-content">
                                <h4 className="why-we--cell_title">200+</h4>
                                <p>{t("працівників")}</p>
                            </div>
                        </div>
                        <div className="why-we--cell">
                            <div className="centered-content">
                                <h4 className="why-we--cell_title">30 000+</h4>
                                <p>{t("щасливих клієнтів")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurAchievements;
