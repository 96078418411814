import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="logo-wrap">
      <Link to={"/"}>
        <svg
          width="200"
          height="25"
          viewBox="0 0 200 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.0535 12.1438C22.0535 20.4163 18.3364 24.2645 8.31822 24.2645H0V0H8.34089C18.4723 0 22.0535 3.8943 22.0535 12.1438ZM17.9964 12.1438C17.9964 6.03732 16.1151 3.50257 8.34089 3.50257H4.03445V20.7158L8.34089 20.7619C16.1151 20.7619 17.9964 18.5037 17.9964 12.1438Z"
            fill="#F1623D"
          />
          <path
            d="M24.9092 15.6463C24.9092 11.1759 28.6036 6.52121 34.2927 6.52121C39.959 6.52121 43.6762 11.1298 43.6762 15.6463C43.6762 20.4393 39.891 24.8175 34.2927 24.8175C28.6263 24.8175 24.9092 20.3702 24.9092 15.6463ZM28.9436 15.6463C28.9436 19.0106 31.3235 21.3149 34.3153 21.3149C37.2845 21.3149 39.6871 19.1028 39.6871 15.6463C39.6871 12.1668 37.2392 10.0468 34.3153 10.0468C31.3235 10.0699 28.9436 12.1898 28.9436 15.6463Z"
            fill="#F1623D"
          />
          <path
            d="M78.7171 15.0703V24.2645H74.6826V15.6463C74.6826 11.6138 73.4814 10.0468 70.3762 10.0468C67.9283 10.0468 65.0045 12.1668 65.0045 15.7155V24.2645H60.97V15.6463C60.97 11.6138 59.8141 10.0468 56.6636 10.0468C53.9211 10.0468 51.2239 12.3051 51.2239 15.992V24.2645H47.1895V7.07427H50.4306L50.9746 10.0238H51.1786C52.0625 8.45687 54.3291 6.54428 57.5249 6.54428C60.8114 6.54428 63.0553 7.85774 64.1885 10.4616H64.3245C65.2538 8.94077 67.4977 6.54428 71.1468 6.54428H71.2148C76.0879 6.56732 78.7171 9.37859 78.7171 15.0703Z"
            fill="#F1623D"
          />
          <path
            d="M87.5566 3.96343H83.4995V0H87.5566V3.96343ZM87.5566 24.2645H83.4995V7.07426H87.5566V24.2645Z"
            fill="#F1623D"
          />
          <path
            d="M111.083 15.0703V24.2645H107.049V15.6463C107.049 11.6599 105.621 10.0468 102.38 10.0468C99.4785 10.0468 96.396 12.3051 96.396 15.992V24.2645H92.3389V7.07427H95.58L96.1693 10.0238H96.3507C97.3026 8.45687 99.9318 6.54428 103.286 6.54428C108.341 6.56732 111.083 9.37859 111.083 15.0703Z"
            fill="#F1623D"
          />
          <path
            d="M133.772 7.07427V24.2645H130.349L129.896 22.3289H129.715C128.989 23.7115 126.677 24.7945 124.139 24.7945C118.495 24.7945 114.914 20.3472 114.914 15.6694C114.914 11.1529 118.473 6.54428 124.139 6.54428C126.587 6.54428 128.876 7.62731 129.783 9.10208H129.919L130.508 7.09732H133.772V7.07427ZM129.805 15.6463C129.805 12.1438 127.063 10.0238 124.162 10.0238C121.215 10.0238 118.971 12.1438 118.971 15.6463C118.971 18.9876 121.215 21.2689 124.162 21.2689C127.153 21.2689 129.805 19.1028 129.805 15.6463Z"
            fill="#F1623D"
          />
          <path
            d="M157.026 15.0703V24.2645H152.992V15.6463C152.992 11.6599 151.564 10.0468 148.323 10.0468C145.421 10.0468 142.339 12.3051 142.339 15.992V24.2645H138.282V7.07427H141.523L142.112 10.0238H142.294C143.245 8.45687 145.875 6.54428 149.229 6.54428C154.284 6.56732 157.026 9.37859 157.026 15.0703Z"
            fill="#F1623D"
          />
          <path
            d="M170.399 24.4719C169.877 24.541 169.334 24.6101 168.722 24.6101C165.027 24.6101 162.375 23.0893 162.375 18.2502V10.5768H159.701V7.07428H162.375V2.11999L166.432 1.29044V7.09732H170.399V10.5999H166.432V18.0659C166.432 20.3472 168.11 20.9232 169.741 20.9232C169.945 20.9232 170.195 20.8771 170.399 20.8541V24.4719Z"
            fill="#F1623D"
          />
          <path
            d="M191.772 7.07427V24.2645H188.35L187.896 22.3289H187.715C186.99 23.7115 184.678 24.7945 182.139 24.7945C176.496 24.7945 172.915 20.3472 172.915 15.6694C172.915 11.1529 176.473 6.54428 182.139 6.54428C184.587 6.54428 186.876 7.62731 187.783 9.10208H187.919L188.508 7.09732H191.772V7.07427ZM187.806 15.6463C187.806 12.1438 185.063 10.0238 182.162 10.0238C179.216 10.0238 176.972 12.1438 176.972 15.6463C176.972 18.9876 179.216 21.2689 182.162 21.2689C185.154 21.2689 187.806 19.1028 187.806 15.6463Z"
            fill="#F1623D"
          />
          <path
            d="M197.915 24.6562C196.759 24.6562 195.943 23.7115 195.943 22.4671C195.943 21.2228 196.804 20.278 197.983 20.278C199.184 20.278 200 21.1767 200 22.4671C200 23.7115 199.184 24.6562 197.96 24.6562H197.915Z"
            fill="#F1623D"
          />
        </svg>
      </Link>
    </div>
  );
};
export default Logo;
