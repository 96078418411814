import React, { useState, Fragment, useEffect } from "react";
import CountItemsSelect from "./CountItemsSelect";
import DistrictItemsSelect from "./DistrictItemsSelect";
import SearchByIdInput from "./SearchByIdInput";
import PriceSelect from "./PriceSelect";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PriceField from "../AppHeader/components/HomePageFilterFields/Fields/PriceField";

const CatalogFilter = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="catalog-page--filter_wrap">
      <div className="filter-fields">
        <div className="filter-fields-group">
          <div className="filter-fields-group__cell">
            {/*todo add hasReset*/}
            <DistrictItemsSelect type={"catalog"} />
          </div>

          <div className="filter-fields-group__cell">
            <CountItemsSelect type={"catalog"} />
          </div>

          <div className="filter-fields-group__cell">
            <PriceSelect type={"catalog"} />
          </div>
        </div>

        <div className="filter-fields-group">
          <div className="filter-fields-group__cell">
            {/*sam todo fix double reload*/}
            <SearchByIdInput type={"catalog"} />
          </div>

          <div className="filter-fields-group__cell">
            <button
              className={
                props.showAdvancedFilter
                  ? "advanced-filter--toggle active"
                  : "advanced-filter--toggle"
              }
              onClick={props.setShowAdvancedFilter.bind(
                null,
                !props.showAdvancedFilter,
              )}
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3.5 6H6.5" stroke="#3F6DB5" strokeWidth="1.5" />
                <path d="M3.5 18H6.5" stroke="#3F6DB5" strokeWidth="1.5" />
                <path d="M14.5 6L21.5 6" stroke="#3F6DB5" strokeWidth="1.5" />
                <path d="M14.5 18L21.5 18" stroke="#3F6DB5" strokeWidth="1.5" />
                <path d="M3.5 12H15.5" stroke="#3F6DB5" strokeWidth="1.5" />
                <rect
                  x="8.5"
                  y="4"
                  width="4"
                  height="4"
                  rx="1.5"
                  stroke="#3F6DB5"
                  strokeWidth="1.5"
                />
                <rect
                  x="8.5"
                  y="16"
                  width="4"
                  height="4"
                  rx="1.5"
                  stroke="#3F6DB5"
                  strokeWidth="1.5"
                />
                <rect
                  x="17.5"
                  y="10"
                  width="4"
                  height="4"
                  rx="1.5"
                  stroke="#3F6DB5"
                  strokeWidth="1.5"
                />
              </svg>
              {t("Ще фільтри")}
              {/*<span>(1)</span>*/}
            </button>
          </div>

          <div className="filter-fields-group__cell"></div>
        </div>
      </div>
    </div>
  );
};
CatalogFilter.propTypes = {
  showAdvancedFilter: PropTypes.any.isRequired,
  setShowAdvancedFilter: PropTypes.func.isRequired,
  hasReset: PropTypes.number.isRequired,
};

export default CatalogFilter;
