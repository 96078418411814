import { createSlice } from "@reduxjs/toolkit";

//todo remove it
export const favoritesSlice = createSlice({
  name: "favorites",
  initialState: {
    data: [],
  },
  reducers: {
    addFavorites: (state, action) => {
      state.data = action.payload;
    },
    removeFavorites: (state, action) => {
      state.data = action.payload;
    },
    putFavoritesObjects: (state, action) => {
      state.data = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { addFavorites, removeFavorites, putFavoritesObjects } =
  favoritesSlice.actions;
export default favoritesSlice.reducer;
