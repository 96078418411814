import React, { useEffect } from "react";
import { apiGet } from "../utils/api";
import { getItemWithExpiry } from "../utils/localsotrage";

// const setItemWithExpiry=(key, value, ttl)=> {
//   const now = new Date();
//
//   // `ttl` is time to live in milliseconds
//   const item = {
//     value: value,
//     expiry: now.getTime() + ttl,
//   };
//
//   localStorage.setItem(key, JSON.stringify(item));
// }
//
// const getItemWithExpiry=(key)=> {
//   const itemStr = localStorage.getItem(key);
//
//   // If the item doesn't exist, return null
//   if (!itemStr) {
//     return null;
//   }
//
//   const item = JSON.parse(itemStr);
//   const now = new Date();
//
//   // Compare the expiry time of the item with the current time
//   if (now.getTime() > item.expiry) {
//     // If the item is expired, delete the item from storage and return null
//     localStorage.removeItem(key);
//     return null;
//   }
//
//   return item.value;
// }
export const localStorageExpireContainer = (Component) => {
  return function wrapLocalStorageExpireContainer(props) {
    // useEffect(()=>{
    //   const keyStorage='favorites';
    //   const keyExpireStorage='ExpireStorageFavorites';
    //
    //   const value = getItemWithExpiry(keyExpireStorage);
    //   if (value) {
    //     console.log('Retrieved value:', value);
    //   } else {
    //     //clear local storage favorites
    //     const dataToStorage = JSON.stringify([]);
    //     localStorage.setItem(keyStorage, dataToStorage);
    //     console.log('Item has expired or does not exist.');
    //   }
    // },[])

    return <Component {...props} />;
  };
};
