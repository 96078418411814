import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const TitleBlock = () => {
    const { t, i18n } = useTranslation();


    return (
        <div className="home-page--about">
            <div className="home-page--about-bars">
                <div className="bars-row">
                    <div className="contacts-page-coll">
                        <div className="mask-contacts-us">
                            <div className="container">
                                <h2>{t("Контакти")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TitleBlock;
