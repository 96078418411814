import React from "react";

export const FilterSecondaryIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9998 44C17.6708 44 14.1934 40.5226 14.1934 36.1936C14.1934 31.8645 17.6708 28.3871 21.9998 28.3871C26.3288 28.3871 29.8063 31.8645 29.8063 36.1936C29.8063 40.5226 26.3288 44 21.9998 44ZM21.9998 29.8065C18.4514 29.8065 15.6127 32.6452 15.6127 36.1936C15.6127 39.7419 18.4514 42.5807 21.9998 42.5807C25.5482 42.5807 28.3869 39.7419 28.3869 36.1936C28.3869 32.6452 25.5482 29.8065 21.9998 29.8065Z"
        fill="black"
      />
      <path
        d="M22.0001 39.7419C20.7937 39.7419 19.8711 38.8194 19.8711 37.6129H21.2904C21.2904 38.0387 21.5743 38.3226 22.0001 38.3226C22.4259 38.3226 22.7098 38.0387 22.7098 37.6129C22.7098 37.1871 22.4259 36.9032 22.0001 36.9032C20.7937 36.9032 19.8711 35.9806 19.8711 34.7742C19.8711 33.5677 20.7937 32.6452 22.0001 32.6452C23.2066 32.6452 24.1292 33.5677 24.1292 34.7742H22.7098C22.7098 34.3484 22.4259 34.0645 22.0001 34.0645C21.5743 34.0645 21.2904 34.3484 21.2904 34.7742C21.2904 35.2 21.5743 35.4839 22.0001 35.4839C23.2066 35.4839 24.1292 36.4064 24.1292 37.6129C24.1292 38.8194 23.2066 39.7419 22.0001 39.7419Z"
        fill="black"
      />
      <path
        d="M22.7099 31.2258H21.2905V33.3548H22.7099V31.2258Z"
        fill="black"
      />
      <path
        d="M22.7099 39.0323H21.2905V41.1613H22.7099V39.0323Z"
        fill="black"
      />
      <path
        d="M41.871 31.2258H26.2581V29.8065H41.871C42.2968 29.8065 42.5806 29.5226 42.5806 29.0968V27.6774C42.5806 27.2516 42.2968 26.9677 41.871 26.9677H2.12903C1.70323 26.9677 1.41935 27.2516 1.41935 27.6774V29.0968C1.41935 29.5226 1.70323 29.8065 2.12903 29.8065H17.7419V31.2258H2.12903C0.922581 31.2258 0 30.3032 0 29.0968V27.6774C0 26.471 0.922581 25.5484 2.12903 25.5484H41.871C43.0774 25.5484 44 26.471 44 27.6774V29.0968C44 30.3032 43.0774 31.2258 41.871 31.2258Z"
        fill="black"
      />
      <path
        d="M42.5807 26.2581H41.1613V4.25807H28.3871V26.2581H26.9678V2.83871H42.5807V26.2581Z"
        fill="black"
      />
      <path
        d="M41.1614 3.54839H39.742V1.41935H29.8066V3.54839H28.3872V0H41.1614V3.54839Z"
        fill="black"
      />
      <path
        d="M2.83879 16.3226H1.41943V26.2581H2.83879V16.3226Z"
        fill="black"
      />
      <path
        d="M21.2904 13.4839H19.871V12.4194L11.3549 6.52903L2.83879 12.4194V13.4839H1.41943V11.7097L11.3549 4.82581L21.2904 11.7097V13.4839Z"
        fill="black"
      />
      <path
        d="M21.2904 16.3226H19.8711V26.2581H21.2904V16.3226Z"
        fill="black"
      />
      <path
        d="M22.7097 17.0323H0V12.7742H22.7097V17.0323ZM1.41935 15.6129H21.2903V14.1936H1.41935V15.6129Z"
        fill="black"
      />
      <path
        d="M4.25822 11.3548H2.83887V5.53548H7.09693V9.2258H5.67758V6.95483H4.25822V11.3548Z"
        fill="black"
      />
      <path
        d="M14.1935 26.2581H12.7742V19.871H9.93547V26.2581H8.51611V18.4516H14.1935V26.2581Z"
        fill="black"
      />
      <path
        d="M34.0647 12.7742H29.8066V8.51613H34.0647V12.7742ZM31.226 11.3548H32.6454V9.93548H31.226V11.3548Z"
        fill="black"
      />
      <path
        d="M39.742 12.7742H35.4839V8.51613H39.742V12.7742ZM36.9032 11.3548H38.3226V9.93548H36.9032V11.3548Z"
        fill="black"
      />
      <path
        d="M34.0647 18.4516H29.8066V14.1935H34.0647V18.4516ZM31.226 17.0323H32.6454V15.6129H31.226V17.0323Z"
        fill="black"
      />
      <path
        d="M39.742 18.4516H35.4839V14.1935H39.742V18.4516ZM36.9032 17.0323H38.3226V15.6129H36.9032V17.0323Z"
        fill="black"
      />
      <path
        d="M34.0647 24.129H29.8066V19.871H34.0647V24.129ZM31.226 22.7097H32.6454V21.2903H31.226V22.7097Z"
        fill="black"
      />
      <path
        d="M39.742 24.129H35.4839V19.871H39.742V24.129ZM36.9032 22.7097H38.3226V21.2903H36.9032V22.7097Z"
        fill="black"
      />
      <path d="M44 5.67741H25.5483V7.09677H44V5.67741Z" fill="black" />
    </svg>
  );
};
export const FilterNewBuildingIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.3333 42.5333V38.7361C41.7416 38.1423 42.623 36.7257 42.5333 35.2C42.5333 33.1782 41.2177 31.5333 39.6 31.5333C37.9823 31.5333 36.6667 33.1782 36.6667 35.2C36.577 36.7257 37.4584 38.1423 38.8667 38.7361V42.5333H35.2V19.8C35.2001 19.5221 35.0431 19.2679 34.7945 19.1437L29.3333 16.4135V12.4667C29.3335 12.1391 29.1165 11.8512 28.8017 11.7612L19.0667 8.97967V0.733333C19.0667 0.32835 18.7383 0 18.3333 0H3.66667C3.26168 0 2.93333 0.32835 2.93333 0.733333V42.5333H0V44H44V42.5333H40.3333ZM17.6 42.5333H4.4V41.0667H11V39.6H4.4V36.6667H11V35.2H4.4V32.2667H11V30.8H4.4V27.8667H11V26.4H4.4V23.4667H11V22H4.4V19.0667H11V17.6H4.4V14.6667H11V13.2H4.4V10.2667H11V8.8H4.4V5.86667H11V4.4H4.4V1.46667H17.6V42.5333ZM27.8667 42.5333H19.0667V41.0667H24.2V39.6H19.0667V36.6667H24.2V35.2H19.0667V32.2667H24.2V30.8H19.0667V27.8667H24.2V26.4H19.0667V23.4667H24.2V22H19.0667V19.0667H24.2V17.6H19.0667V14.6667H24.2V13.2H19.0667V10.5057L27.8667 13.0196V42.5333ZM33.7333 42.5333H29.3333V41.0667H31.5333V39.6H29.3333V36.6667H31.5333V35.2H29.3333V32.2667H31.5333V30.8H29.3333V27.8667H31.5333V26.4H29.3333V23.4667H31.5333V22H29.3333V18.0532L33.7333 20.2532V42.5333ZM38.8667 37.0847C38.3741 36.5835 38.109 35.9022 38.1333 35.2C38.1333 34.0076 38.8051 33 39.6 33C40.3949 33 41.0667 34.0076 41.0667 35.2C41.0904 35.9021 40.8254 36.5833 40.3333 37.0847V35.9333H38.8667V37.0847Z"
        fill="white"
      />
    </svg>
  );
};
export const FilterHouseIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_60_204)">
        <path
          d="M19.7999 10.2666C17.3698 10.2666 15.3999 12.2365 15.3999 14.6666C15.3999 17.0967 17.3698 19.0666 19.7999 19.0666C22.229 19.0642 24.1975 17.0957 24.1999 14.6666C24.1999 12.2365 22.23 10.2666 19.7999 10.2666ZM19.7999 17.5999C18.1799 17.5999 16.8666 16.2866 16.8666 14.6666C16.8666 13.0466 18.1799 11.7333 19.7999 11.7333C21.4199 11.7333 22.7332 13.0466 22.7332 14.6666C22.7332 16.2866 21.4199 17.5999 19.7999 17.5999Z"
          fill="white"
        />
        <path
          d="M18.3334 21.9999H12.4667C12.0617 21.9999 11.7334 22.3282 11.7334 22.7332V30.0666C11.7334 30.4715 12.0617 30.7999 12.4667 30.7999H18.3334C18.7384 30.7999 19.0667 30.4715 19.0667 30.0666V22.7332C19.0667 22.3282 18.7384 21.9999 18.3334 21.9999ZM14.6667 29.3332H13.2001V27.1332H14.6667V29.3332ZM14.6667 25.6666H13.2001V23.4666H14.6667V25.6666ZM17.6001 29.3332H16.1334V27.1332H17.6001V29.3332ZM17.6001 25.6666H16.1334V23.4666H17.6001V25.6666Z"
          fill="white"
        />
        <path
          d="M27.1332 21.9999H21.2665C20.8616 21.9999 20.5332 22.3282 20.5332 22.7332V30.0666C20.5332 30.4715 20.8616 30.7999 21.2665 30.7999H27.1332C27.5382 30.7999 27.8665 30.4715 27.8665 30.0666V22.7332C27.8665 22.3282 27.5382 21.9999 27.1332 21.9999ZM23.4665 29.3332H21.9999V27.1332H23.4665V29.3332ZM23.4665 25.6666H21.9999V23.4666H23.4665V25.6666ZM26.3999 29.3332H24.9332V27.1332H26.3999V29.3332ZM26.3999 25.6666H24.9332V23.4666H26.3999V25.6666Z"
          fill="white"
        />
        <path
          d="M27.1332 32.2666H21.2665C20.8616 32.2666 20.5332 32.5949 20.5332 32.9999V37.3999C20.5332 37.8049 20.8616 38.1332 21.2665 38.1332H27.1332C27.5382 38.1332 27.8665 37.8049 27.8665 37.3999V32.9999C27.8665 32.5949 27.5382 32.2666 27.1332 32.2666ZM23.4665 36.6666H21.9999V35.9332H23.4665V36.6666ZM23.4665 34.4666H21.9999V33.7332H23.4665V34.4666ZM26.3999 36.6666H24.9332V35.9332H26.3999V36.6666ZM26.3999 34.4666H24.9332V33.7332H26.3999V34.4666Z"
          fill="white"
        />
        <path
          d="M38.8667 42.5339V38.7375C40.275 38.1434 41.1564 36.7265 41.0667 35.2006C41.0667 33.1788 39.7511 31.5339 38.1333 31.5339C36.5156 31.5339 35.2 33.1788 35.2 35.2006C35.1103 36.7265 35.9916 38.1434 37.4 38.7375V42.5339H30.8V17.1709L34.6815 21.0524C34.9679 21.3387 35.4321 21.3387 35.7185 21.0524L37.9185 18.8524C38.2047 18.566 38.2047 18.1018 37.9185 17.8155L20.3185 0.215454C20.0321 -0.0708211 19.5679 -0.0708211 19.2815 0.215454L1.68153 17.8155C1.39526 18.1018 1.39526 18.566 1.68153 18.8524L3.88153 21.0524C4.1679 21.3387 4.6321 21.3387 4.91847 21.0524L8.8 17.1709V42.5339H0V44.0006H44V42.5339H38.8667ZM4.4 19.497L3.23693 18.3339L19.8 1.77085L36.3631 18.3339L35.2 19.497L20.3185 4.61545C20.0321 4.32918 19.5679 4.32918 19.2815 4.61545L4.4 19.497ZM17.6 42.5339H13.2V33.7339H17.6V42.5339ZM29.3333 42.5339H19.0667V33.0006C19.0667 32.5956 18.7383 32.2673 18.3333 32.2673H12.4667C12.0617 32.2673 11.7333 32.5956 11.7333 33.0006V42.5339H10.2667V15.7042L19.8 6.17085L29.3333 15.7042V42.5339ZM37.4 37.0853C36.9075 36.5841 36.6424 35.9028 36.6667 35.2006C36.6667 34.0082 37.3384 33.0006 38.1333 33.0006C38.9283 33.0006 39.6 34.0082 39.6 35.2006C39.6237 35.9027 39.3587 36.5838 38.8667 37.0853V35.9339H37.4V37.0853Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_60_204">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FilterPlacesIcon = () => {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M36 38.5H1.14441e-05V39.9999H36V38.5Z" fill="white" />
      <path d="M35.2495 32.25H32.9995V33.7499H35.2495V32.25Z" fill="white" />
      <path d="M30.7495 32.25H28.4995V33.7499H30.7495V32.25Z" fill="white" />
      <path d="M26.2495 32.25H23.9995V33.7499H26.2495V32.25Z" fill="white" />
      <path d="M21.7495 32.25H19.4995V33.7499H21.7495V32.25Z" fill="white" />
      <path d="M17.2495 32.25H14.9995V33.7499H17.2495V32.25Z" fill="white" />
      <path d="M12.7495 32.25H10.4995V33.7499H12.7495V32.25Z" fill="white" />
      <path d="M8.24951 32.25H5.99947V33.7499H8.24951V32.25Z" fill="white" />
      <path d="M3.74951 32.25H1.49947V33.7499H3.74951V32.25Z" fill="white" />
      <path d="M11.25 11.75H6.75002V13.2499H11.25V11.75Z" fill="white" />
      <path d="M29.25 11.75H24.75V13.2499H29.25V11.75Z" fill="white" />
      <path
        d="M31.7175 27.5H36V26H32.7825L29.565 17H30.75V15.5H27.435L31.7175 27.5Z"
        fill="white"
      />
      <path
        d="M8.07071 27.5H27.9307L25.3582 15.5H22.5007V17H24.1432L26.0707 26H9.9307L11.8582 17H13.5007V15.5H10.6432L8.07071 27.5Z"
        fill="white"
      />
      <path
        d="M-6.58035e-05 27.5H4.28244L8.56494 15.5H5.24999V17H6.43496L3.21745 26H-6.58035e-05V27.5Z"
        fill="white"
      />
      <path
        d="M17.9995 17.6925L18.5845 16.9725C18.802 16.695 23.9995 10.2075 23.9995 5.88747C23.9995 2.64 21.307 0 17.9995 0C14.6921 0 11.9996 2.64 11.9996 5.88747C11.9996 10.215 17.1971 16.7024 17.4146 16.9725L17.9995 17.6925ZM17.9995 1.49997C20.4821 1.49997 22.4995 3.46496 22.4995 5.88747C22.4995 8.89498 19.3345 13.4699 17.9995 15.2699C16.6646 13.4699 13.4996 8.8949 13.4996 5.88747C13.4996 3.46503 15.517 1.49997 17.9995 1.49997Z"
        fill="white"
      />
      <path
        d="M18 8.24999C19.2374 8.24999 20.25 7.2375 20.25 5.99996C20.25 4.76241 19.2375 3.74992 18 3.74992C16.7624 3.74992 15.7499 4.76241 15.7499 5.99996C15.7499 7.2375 16.7624 8.24999 18 8.24999ZM18 5.24997C18.4125 5.24997 18.75 5.58744 18.75 5.99996C18.75 6.41247 18.4125 6.74994 18 6.74994C17.5875 6.74994 17.25 6.41247 17.25 5.99996C17.2499 5.58752 17.5875 5.24997 18 5.24997Z"
        fill="white"
      />
    </svg>
  );
};
export const FilterCommercesIcon = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.2667 32.2667H36.6667V30.8H38.1333C38.5383 30.8 38.8667 30.4717 38.8667 30.0667V27.1333C38.8667 26.7283 38.5383 26.4 38.1333 26.4H36.6667V0.733333C36.6667 0.32835 36.3383 0 35.9333 0H2.2C1.79502 0 1.46667 0.32835 1.46667 0.733333V26.4H0.733333C0.32835 26.4 0 26.7283 0 27.1333V30.0667C0 30.4717 0.32835 30.8 0.733333 30.8H1.46667V42.5333H0.733333V44H41.8V42.5333H39.6V39.6H43.2667C43.6716 39.6 44 39.2717 44 38.8667V33C44 32.595 43.6716 32.2667 43.2667 32.2667ZM13.2 1.46667H35.2V26.4H13.2V1.46667ZM2.93333 1.46667H11.7333V26.4H2.93333V1.46667ZM1.46667 27.8667H37.4V29.3333H1.46667V27.8667ZM11.7333 42.5333H2.93333V30.8H11.7333V42.5333ZM30.0667 42.5333H18.3333V35.2H30.0667V42.5333ZM35.2 42.5333H31.5333V34.4667C31.5333 34.0617 31.205 33.7333 30.8 33.7333H17.6C17.195 33.7333 16.8667 34.0617 16.8667 34.4667V42.5333H13.2V30.8H35.2V32.2667H34.4667C34.0617 32.2667 33.7333 32.595 33.7333 33V38.8667C33.7333 39.2717 34.0617 39.6 34.4667 39.6H35.2V42.5333ZM38.1333 42.5333H36.6667V39.6H38.1333V42.5333ZM42.5333 38.1333H35.2V33.7333H42.5333V38.1333Z"
        fill="white"
      />
      <path d="M10.2666 4.39999H4.3999V5.86666H10.2666V4.39999Z" fill="white" />
      <path d="M10.2666 10.2667H4.3999V11.7333H10.2666V10.2667Z" fill="white" />
      <path d="M10.2666 16.1333H4.3999V17.6H10.2666V16.1333Z" fill="white" />
      <path d="M10.2666 22H4.3999V23.4667H10.2666V22Z" fill="white" />
      <path d="M10.2666 33.7333H4.3999V35.2H10.2666V33.7333Z" fill="white" />
      <path d="M10.2666 36.6667H4.3999V38.1333H10.2666V36.6667Z" fill="white" />
      <path d="M10.2666 39.6H4.3999V41.0667H10.2666V39.6Z" fill="white" />
      <path d="M10.2666 19.0667H4.3999V20.5333H10.2666V19.0667Z" fill="white" />
      <path d="M10.2666 13.2H4.3999V14.6667H10.2666V13.2Z" fill="white" />
      <path d="M10.2666 7.33333H4.3999V8.79999H10.2666V7.33333Z" fill="white" />
      <path
        d="M16.1332 4.39999H14.6665V5.86666H16.1332V4.39999Z"
        fill="white"
      />
      <path
        d="M19.0668 4.39999H17.6001V5.86666H19.0668V4.39999Z"
        fill="white"
      />
      <path
        d="M21.9999 4.39999H20.5332V5.86666H21.9999V4.39999Z"
        fill="white"
      />
      <path
        d="M24.9335 4.39999H23.4668V5.86666H24.9335V4.39999Z"
        fill="white"
      />
      <path
        d="M27.8666 4.39999H26.3999V5.86666H27.8666V4.39999Z"
        fill="white"
      />
      <path
        d="M30.8002 4.39999H29.3335V5.86666H30.8002V4.39999Z"
        fill="white"
      />
      <path
        d="M33.7333 4.39999H32.2666V5.86666H33.7333V4.39999Z"
        fill="white"
      />
      <path
        d="M16.1332 7.33333H14.6665V8.79999H16.1332V7.33333Z"
        fill="white"
      />
      <path
        d="M19.0668 7.33333H17.6001V8.79999H19.0668V7.33333Z"
        fill="white"
      />
      <path
        d="M21.9999 7.33333H20.5332V8.79999H21.9999V7.33333Z"
        fill="white"
      />
      <path
        d="M24.9335 7.33333H23.4668V8.79999H24.9335V7.33333Z"
        fill="white"
      />
      <path
        d="M27.8666 7.33333H26.3999V8.79999H27.8666V7.33333Z"
        fill="white"
      />
      <path
        d="M30.8002 7.33333H29.3335V8.79999H30.8002V7.33333Z"
        fill="white"
      />
      <path
        d="M33.7333 7.33333H32.2666V8.79999H33.7333V7.33333Z"
        fill="white"
      />
      <path
        d="M16.1332 10.2667H14.6665V11.7333H16.1332V10.2667Z"
        fill="white"
      />
      <path
        d="M19.0668 10.2667H17.6001V11.7333H19.0668V10.2667Z"
        fill="white"
      />
      <path
        d="M21.9999 10.2667H20.5332V11.7333H21.9999V10.2667Z"
        fill="white"
      />
      <path
        d="M24.9335 10.2667H23.4668V11.7333H24.9335V10.2667Z"
        fill="white"
      />
      <path
        d="M27.8666 10.2667H26.3999V11.7333H27.8666V10.2667Z"
        fill="white"
      />
      <path
        d="M30.8002 10.2667H29.3335V11.7333H30.8002V10.2667Z"
        fill="white"
      />
      <path
        d="M33.7333 10.2667H32.2666V11.7333H33.7333V10.2667Z"
        fill="white"
      />
      <path d="M16.1332 13.2H14.6665V14.6667H16.1332V13.2Z" fill="white" />
      <path d="M19.0668 13.2H17.6001V14.6667H19.0668V13.2Z" fill="white" />
      <path d="M21.9999 13.2H20.5332V14.6667H21.9999V13.2Z" fill="white" />
      <path d="M24.9335 13.2H23.4668V14.6667H24.9335V13.2Z" fill="white" />
      <path d="M27.8666 13.2H26.3999V14.6667H27.8666V13.2Z" fill="white" />
      <path d="M30.8002 13.2H29.3335V14.6667H30.8002V13.2Z" fill="white" />
      <path d="M33.7333 13.2H32.2666V14.6667H33.7333V13.2Z" fill="white" />
      <path d="M16.1332 16.1333H14.6665V17.6H16.1332V16.1333Z" fill="white" />
      <path d="M19.0668 16.1333H17.6001V17.6H19.0668V16.1333Z" fill="white" />
      <path d="M21.9999 16.1333H20.5332V17.6H21.9999V16.1333Z" fill="white" />
      <path d="M24.9335 16.1333H23.4668V17.6H24.9335V16.1333Z" fill="white" />
      <path d="M27.8666 16.1333H26.3999V17.6H27.8666V16.1333Z" fill="white" />
      <path d="M30.8002 16.1333H29.3335V17.6H30.8002V16.1333Z" fill="white" />
      <path d="M33.7333 16.1333H32.2666V17.6H33.7333V16.1333Z" fill="white" />
      <path
        d="M16.1332 19.0667H14.6665V20.5333H16.1332V19.0667Z"
        fill="white"
      />
      <path
        d="M19.0668 19.0667H17.6001V20.5333H19.0668V19.0667Z"
        fill="white"
      />
      <path
        d="M21.9999 19.0667H20.5332V20.5333H21.9999V19.0667Z"
        fill="white"
      />
      <path
        d="M24.9335 19.0667H23.4668V20.5333H24.9335V19.0667Z"
        fill="white"
      />
      <path
        d="M27.8666 19.0667H26.3999V20.5333H27.8666V19.0667Z"
        fill="white"
      />
      <path
        d="M30.8002 19.0667H29.3335V20.5333H30.8002V19.0667Z"
        fill="white"
      />
      <path
        d="M33.7333 19.0667H32.2666V20.5333H33.7333V19.0667Z"
        fill="white"
      />
      <path d="M16.1332 22H14.6665V23.4667H16.1332V22Z" fill="white" />
      <path d="M19.0668 22H17.6001V23.4667H19.0668V22Z" fill="white" />
      <path d="M21.9999 22H20.5332V23.4667H21.9999V22Z" fill="white" />
      <path d="M24.9335 22H23.4668V23.4667H24.9335V22Z" fill="white" />
      <path d="M27.8666 22H26.3999V23.4667H27.8666V22Z" fill="white" />
      <path d="M30.8002 22H29.3335V23.4667H30.8002V22Z" fill="white" />
      <path d="M33.7333 22H32.2666V23.4667H33.7333V22Z" fill="white" />
      <path d="M39.6 35.2H38.1333V36.6667H39.6V35.2Z" fill="white" />
      <path d="M41.8002 35.2H40.3335V36.6667H41.8002V35.2Z" fill="white" />
      <path d="M37.3998 35.2H35.9331V36.6667H37.3998V35.2Z" fill="white" />
    </svg>
  );
};

export const FilterButtonExtendSearchIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 6H6" stroke="black" strokeWidth="1.5" />
      <path d="M3 18H6" stroke="black" strokeWidth="1.5" />
      <path d="M14 6L21 6" stroke="black" strokeWidth="1.5" />
      <path d="M14 18L21 18" stroke="black" strokeWidth="1.5" />
      <path d="M3 12H15" stroke="black" strokeWidth="1.5" />
      <rect
        x="8"
        y="4"
        width="4"
        height="4"
        rx="1.5"
        stroke="black"
        strokeWidth="1.5"
      />
      <rect
        x="8"
        y="16"
        width="4"
        height="4"
        rx="1.5"
        stroke="black"
        strokeWidth="1.5"
      />
      <rect
        x="17"
        y="10"
        width="4"
        height="4"
        rx="1.5"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const FilterCatalogMoreFilterIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.5 6H6.5" stroke="#3F6DB5" strokeWidth="1.5" />
      <path d="M3.5 18H6.5" stroke="#3F6DB5" strokeWidth="1.5" />
      <path d="M14.5 6L21.5 6" stroke="#3F6DB5" strokeWidth="1.5" />
      <path d="M14.5 18L21.5 18" stroke="#3F6DB5" strokeWidth="1.5" />
      <path d="M3.5 12H15.5" stroke="#3F6DB5" strokeWidth="1.5" />
      <rect
        x="8.5"
        y="4"
        width="4"
        height="4"
        rx="1.5"
        stroke="#3F6DB5"
        strokeWidth="1.5"
      />
      <rect
        x="8.5"
        y="16"
        width="4"
        height="4"
        rx="1.5"
        stroke="#3F6DB5"
        strokeWidth="1.5"
      />
      <rect
        x="17.5"
        y="10"
        width="4"
        height="4"
        rx="1.5"
        stroke="#3F6DB5"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const FilterCatalogSearchBuIdIcon = () => {
  return (
      <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.378 16.7887L14.1662 13.5769C15.2037 12.4016 15.8333 10.8577 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333C10.8577 15.8333 12.4016 15.2037 13.5769 14.1662L16.7887 17.378C16.9514 17.5407 17.2152 17.5407 17.378 17.378C17.5407 17.2152 17.5407 16.9514 17.378 16.7887ZM15 9.16667C15 12.3883 12.3883 15 9.16667 15C5.94501 15 3.33333 12.3883 3.33333 9.16667C3.33333 5.94501 5.94501 3.33333 9.16667 3.33333C12.3883 3.33333 15 5.94501 15 9.16667Z"
            fill="black"
        />
      </svg>
  );
};

