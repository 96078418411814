import React from "react";
import { useTranslation } from "react-i18next";

const NavigationTopMenuItem = ({ item, handler }) => {
  const { t } = useTranslation();
  return (
    <li>
      <a
        onClick={() => {
          item.handler(item.slug);
        }}
      >
        {t(item.text)}
      </a>
    </li>
  );
};
export default NavigationTopMenuItem;
