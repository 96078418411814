import React, { useState, useEffect, useRef, Fragment } from "react";
import InterfacesIcons from "../../vector/InterfacesIcons";
import APP_CONFIG from "../../../config";
import { MenuButton } from "@mui/base/MenuButton";
import { Dropdown } from "@mui/base/Dropdown";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { filterContainer } from "../../../containers/filterContainer";
import { setFilter } from "../../../store/reducers/filter";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { putObjects } from "../../../store/reducers/objects";
import { roomContainer } from "../../../containers/roomContainer";
import {
  putObject,
  putObjectBreadCrumbs,
  putObjectSimilar,
} from "../../../store/reducers/object";
import { putBestProposalsObjects } from "../../../store/reducers/bestProposals";
import { apiGet } from "../../../utils/api";
import { Button, Menu, MenuItem } from "@mui/material";

const Languages = (props) => {
  const { t, i18n } = useTranslation();
  const [languages, setLanguages] = useState(["uk", "en", "ru"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const dispatch = useDispatch();
  const { slug } = useParams();
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);
  const activeFilterTab = useSelector((state) => state.filter.activeFilterTab);
  const languageLabels = { uk: "UK", en: "EN", ru: "RU" };
  const isApartment = useRouteMatch("/apartment/:slug");
  const isCommerce = useRouteMatch("/commerce/:slug");
  const isHouse = useRouteMatch("/house/:slug");
  const isRealtors = useRouteMatch("/realtors/:slug");

  const isCatalogApartment = useRouteMatch("/catalog");
  const isCatalogHouses = useRouteMatch("/catalog");
  const isCatalogCommerce = useRouteMatch("/catalog");

  const isHome = useRouteMatch("/");

  useEffect(() => {
    const index = languages.indexOf(i18n.language.slice(0, 2).toLowerCase());
    if (index > -1) {
      setCurrentLanguageIndex(index);
    } else {
      setCurrentLanguageIndex(0);
    }
  }, [i18n.language]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  //todo refactor there
  const changeLanguage = (index) => {
    setCurrentLanguageIndex(index);
    i18n.changeLanguage(languages[index]);

    handleMenuClose();
  };

  return (
    <>
      <div className="app-header--languages">
        <Button
          className={"app-header--navigation-item--link"}
          onClick={handleMenuOpen}
        >
          {languageLabels[languages[currentLanguageIndex]]}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {languages.map((lang, index) => (
            <MenuItem key={lang} onClick={() => changeLanguage(index)}>
              {languageLabels[lang]}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};
export default roomContainer(filterContainer(Languages));
