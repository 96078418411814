
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogBooking from "./Dialog/DialogBooking";


const AnchorsLinksList = [
  {
    title: "Широкий спектр послуг",
    text: "Ми займаємося підбором об'єктів первинної та вторинної житлової нерухомості, а також комерційної нерухомості. З нами ви можете не лише купувати, а й продавати об'єкти нерухомості",
    anchor: "#",
  },
  {
    title: "Найкраща база об'єктів",
    text: "У нас один з найкращих і великих каталогів, де ви зможете знайти потрібний вам об'єкт в залежності від вартості, локації та інших переваг",
    anchor: "#",
  },
  {
    title: "Більш ніж 10-річний досвід",
    text:"За цей час ми зробили щасливішими понад 10 тисяч клієнтів, а наша репутація стала гарантом виконання наших обов'язків та поставленого результату" ,
    anchor: "#",
  },
  {
    title: "Індивідуальний підхід до кожного",
    text: "До кожного клієнта ми закріплюємо персонального агента з нерухомості, який буде з вами з моменту підбору об'єкта нерухомості до отримання ключів",
    anchor: "#",
  },
];

const WhyYou = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);

  const toggleText = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
      <div className="home-page--why-you">
        <div className="container">
          <h2>{t("Чому з Dominanta вигідно співпрацювати")}</h2>

          <div className="why-you--row">
            {AnchorsLinksList.map((obj, i) => {
              return (
                  <div key={i} className="why-you--cell">
                    <div className="why-you--cell_title">{t(obj.title)}</div>

                    <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => toggleText(i)}
                        style={{ cursor: 'pointer' }}
                    >
                      <circle cx="22.5" cy="22.5" r="22" stroke="#F1623D" />
                      <line
                          x1="12.5"
                          y1="22.4165"
                          x2="33.3333"
                          y2="22.4165"
                          stroke="#F1623D"
                      />
                      {openIndex !== i && (
                          <line
                              x1="22.4165"
                              y1="33.3335"
                              x2="22.4165"
                              y2="12.5002"
                              stroke="#F1623D"
                          />
                      )}
                    </svg>

                    {openIndex === i && (
                        <div className="why-you--cell_text">{t(obj.text)}</div>
                    )}
                  </div>
              );
            })}
          </div>
          <div className="actions mobile-flex-center">
            <DialogBooking model={{}} textOrder={"Отримати консультацію"} />
          </div>
        </div>
      </div>
  );
};

export default WhyYou;