import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import  creating from '../../media/creating.jpg'
import developmentImageUa from "../../media/development-block/development-uk.png";
import developmentImageEn from "../../media/development-block/development-en.png";
import developmentImageRu from "../../media/development-block/development-ru.png";
import developmentImageUaMobile from "../../media/development-block/development-uk-mobile.png";
import developmentImageEnMobile from "../../media/development-block/development-en-mobile.png";
import developmentImageRuMobile from "../../media/development-block/development-ru-mobile.png";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const DevelopmentBlock = (props) => {
  const [languages, setLanguages] = useState(["uk", "en", "ru"]);
  const { t, i18n } = useTranslation();
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);
  const [activeImage, setActiveImage] = useState(developmentImageUa);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const index = languages.indexOf(i18n.language.slice(0, 2).toLowerCase());
    if (index > -1) {
      switch (index) {
        case 0:
          setActiveImage(
            windowWidth < 768 ? developmentImageUaMobile : developmentImageUa,
          );
          break;
        case 1:
          setActiveImage(
            windowWidth < 768 ? developmentImageEnMobile : developmentImageEn,
          );
          break;
        case 2:
          setActiveImage(
            windowWidth < 768 ? developmentImageRuMobile : developmentImageRu,
          );
          break;
        default:
          setActiveImage(
            windowWidth < 768 ? developmentImageUaMobile : developmentImageUa,
          );
          break;
      }
      setCurrentLanguageIndex(index);
    } else {
      setActiveImage(
        windowWidth < 768 ? developmentImageUaMobile : developmentImageUa,
      );
    }
  }, [i18n.language, windowWidth]);

  return (
    <div className={clsx("development-mode", props.className)}>
      <img src={activeImage} alt={"development"} />
    </div>
  );
};

DevelopmentBlock.propTypes = {
  className: PropTypes.object,
};

export default DevelopmentBlock;
