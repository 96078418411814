import React, { useEffect, useState } from "react";

import AppFooter from "/src/components/AppFooter";
import BreadCrumbs from "/src/components/ui/BreadCrumbs";

import { useDispatch, useSelector } from "react-redux";
import { putObjectBreadCrumbs } from "../../store/reducers/object";

import AppHeader from "../../components/AppHeader";

import { useParams } from "react-router-dom";
import APP_CONFIG from "../../config";

import { useTranslation } from "react-i18next";

import HomePagePartners from "../../components/HomePagePartners";

import GoogleMapBlankItem from "../../components/Contacts/GoogleMapsBlankItem";
import TitleBlock from "../../components/Contacts/TitleBlock";
import ContactsContent from "../../components/Contacts/ContactsContent";

const Contacts = () => {
    const dispatch = useDispatch();
    // const data = useSelector((state) => state.object.data);
    const breadCrumbs = useSelector((state) => state.object.breadCrumbs);
    const { t, i18n } = useTranslation();
    let { slug } = useParams();
    useEffect(() => {
        dispatch(putObjectBreadCrumbs([...APP_CONFIG.breadCrumbs.defaultContacts]));
    }, [dispatch]);

    return (
        <div className=" catalog-page sell-page about-us-page contact-us">
            <AppHeader />
            <div className="realtor-page--layout sell-page--layout ">
                <div className="room--cell">
                    <div className="breadcrumb-wrapper">
                        <BreadCrumbs linkChain={breadCrumbs} />
                    </div>
                </div>
            </div>
            <div className="home-page--about-description2 ">
                <div className="sell-page--layout">
                    <div className="room--cell">
                        <TitleBlock />
                    </div>
                </div>
            </div>
            <ContactsContent/>

            <div className="map-wrap">
                <GoogleMapBlankItem/>
            </div>
            <HomePagePartners />
            <AppFooter />
        </div>
    );
};
export default Contacts;
