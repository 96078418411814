import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const SearchButton = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  return (
    <button onClick={onClick} className="search">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9602 20.5042L16.6243 16.0077C18.0251 14.3623 18.875 12.2007 18.875 9.83333C18.875 4.67868 14.8456 0.5 9.875 0.5C4.90444 0.5 0.875 4.67868 0.875 9.83333C0.875 14.988 4.90444 19.1667 9.875 19.1667C12.1579 19.1667 14.2422 18.2852 15.8288 16.8327L20.1648 21.3291C20.3844 21.557 20.7406 21.557 20.9602 21.3291C21.1799 21.1013 21.1799 20.732 20.9602 20.5042ZM17.75 9.83333C17.75 14.3437 14.2242 18 9.875 18C5.52576 18 2 14.3437 2 9.83333C2 5.32301 5.52576 1.66667 9.875 1.66667C14.2242 1.66667 17.75 5.32301 17.75 9.83333Z"
          fill="white"
        />
      </svg>
      {t("Пошук")}
    </button>
  );
};

SearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SearchButton;
