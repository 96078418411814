import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GoogleMap from "/src/libs/google-map-react/src";
import APP_CONFIG from "/src/config";
import Marker from "/src/libs/google-map-react/src/components/Marker";
import { isEmptyObject } from "../../utils/filterUtils";

// const LA_CENTER=[46.47747, 30.73262];
const GoogleMapPlaceItem = (props) => {
  const [marker, setMarker] = useState();
  useEffect(() => {
    if (!isEmptyObject(props.model)) {
      const { images } = props.model;
      //todo add default image
      let miniImage = "";
      if (images && images.length) {
        miniImage = images.at(0).mini;
      }
      setMarker({
        image: { mini: miniImage },
        lat: parseFloat(props.model.lat),
        lng: parseFloat(props.model.lng),
        title: props.model.title,
        region_name: props.model.region_name,
        area_name: props.model.district_name,
        id: props.model.id,
        type: props.model.type,
        slug: props.model.slug,
      });
    }
  }, [props.model]);

  if (!marker) {
    return null;
  }
  return (
    <React.Fragment>
      <GoogleMap
        defaultZoom={12}
        defaultCenter={[marker.lat, marker.lng]}
        bootstrapURLKeys={{ key: APP_CONFIG.GOOGLE_MAP_KEY }}
        onDragEnd={(f) => f}
        onClick={(f) => f}
      >
        {
          <Marker
            text={marker.title}
            model={marker}
            lat={marker.lat}
            lng={marker.lng}
            infoWindowModeVersion={"singleObject"}
            useGlobalDispatch={false}
          />
        }
      </GoogleMap>
    </React.Fragment>
  );
};
GoogleMapPlaceItem.defaultProps = {
  isDev: false,
  options: [],
  onDragEnd: (f) => f,
};
GoogleMapPlaceItem.propTypes = {
  model: PropTypes.object,

  isDev: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onDragEnd: PropTypes.func,
};

export default GoogleMapPlaceItem;
