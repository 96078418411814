import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AppNetworks from "../AppHeader/components/AppNetworks";
import GoogleMapComponent from "../GoogleMapComponent/GoogleMapComponent";
import {apiGet} from "../../utils/api";
import {useHistory} from "react-router-dom";

const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#F1623D",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#B2BAC2",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#D9D9D9",
        },
        "&:hover fieldset": {
            borderColor: "#F1623D",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#F1623D",
        },
    },
});
const ContactsContent = () => {
    const {t, i18n} = useTranslation();
    const data = useSelector((state) => state.object.data);
    const [textError, setTextError] = useState(false);
    const [textValue, setTextValue] = useState("");
    const [phone, setPhone] = useState("0");
    const history = useHistory();
    const [options, setOptions] = useState([
        {
            lat: "46.477340483980505",
            lng: "30.741459695292832",
            // title: t("Філія 1 , Пушкінська 32"),
            title: "1",
            type: "onlyLocation",
            useInfoPopup: false,
            extendClass: "only-location",
        },
        {
            lat: "46.46676184588144",
            lng: "30.752343568854553",
            // title: t("Філія 4, Французький бульвар 2"),
            title: "4",
            type: "onlyLocation",
            useInfoPopup: false,
            extendClass: "only-location",
        },
        {
            lat: "46.435790968455166",
            lng: "30.75813856885335",
            // title: t("Філія 5, Генуезька 24Б"),
            title: "5",
            type: "onlyLocation",
            useInfoPopup: false,
            extendClass: "only-location",
        },
        {
            lat: "46.5778081658107",
            lng: "30.79887316885878",
            // title: t("Філія 12, Семена Палія, 103"),
            title: "12",
            type: "onlyLocation",
            useInfoPopup: false,
            extendClass: "only-location",
        },
        {
            lat: "46.47736526086217",
            lng: "30.741403911182438",
            // title: t("Філія 14, Пушкінська 32"),
            title: "14",
            type: "onlyLocation",
            useInfoPopup: false,
            extendClass: "only-location",
        },
        {
            lat: "46.471904243315414",
            lng: "30.747693471566976",
            // title: t("Філія 17, Б. Арнаутська 23б"),
            title: "17",
            type: "onlyLocation",
            useInfoPopup: false,
            extendClass: "only-location",
        },


    ]);
    const [isPhoneCompleted, setIsPhoneCompleted] = useState(false);
    const sendRequest = () => {
        const params = {
            name: textValue,
            phone: phone,
            email: "",
            modelId: "",
            responsibleId: data.id,
        };
        const result = apiGet("user-action-booking-view", params)
            .then((data) => data.data)
            .then((response) => {
                const { status, serverResponce } = response;
                let ojson = JSON.parse(serverResponce.replace(/'/g, '"'));
                const { error_message, error } = ojson;
                if (error_message === "Лид добавлен") {
                    if (status) {
                        history.push({
                            pathname: "/user-success-page",
                            // search: '?'+query,  // query string
                            state: {
                                // location state
                                update: true,
                            },
                        });
                    } else {
                        alert("Error");
                    }
                }
            });
    };
    function handleSubmit(event) {
        event.preventDefault();
        if (textValue.length < 3) {
            setTextError(true);
            return;
        }
        if (!isPhoneCompleted) {
            return;
        }

        sendRequest();
    }

    const changeInputValue = (event) => {
        setTextValue(event.target.value);
        if (event.target.value.length >= 3) {
            setTextError(false);
        }
    };
    const changePhoneValue = (value) => {
        setPhone(value);
        if (value.length >= 12) {
            setIsPhoneCompleted(true);
        } else {
            setIsPhoneCompleted(false);
        }
    };


    return (
        <div className="realtor-page">
            <div className="realtor-page--layout">
                <div className="primary-room--carousel">
                    <div className="top-sale--cell cell-left">
                        <div className="stiky-wrap-main">
                            <div className="top-sale info-block">
                                <div className="column-contacts">
                                    <div className="col-item">
                                        <div className="promo-info">

                                            <li className="expirence-element">
                                                <div className="expirence-element--name">{t('Ми відкриті')}:</div>
                                                <div
                                                    className="expirence-element--value">{t("Пн - Пт: 09:00-18:00")}</div>
                                            </li>
                                            <li className="expirence-element">
                                                <div className="expirence-element--name">{t('Назва компанії')}</div>
                                                <div className="expirence-element--value">Dominanta</div>
                                            </li>
                                            <li className="expirence-element">
                                                <div className="expirence-element--name">{t('Адреса')}</div>
                                                <div
                                                    className="expirence-element--value">{t('Центральний офіс Пушкінська вулиця, 32')}</div>
                                            </li>

                                        </div>

                                    </div>
                                    <div className="col-item">
                                        <div className="promo-info contact-promo">

                                            <li className="expirence-element">
                                                <div className="expirence-element--name full flex-a-center">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M19.5063 7.95952C18.0666 13.6147 13.6147 18.0666 7.95953 19.5063C5.81867 20.0513 4 18.2091 4 16V15C4 14.4477 4.44883 14.0053 4.99842 13.9508C5.92696 13.8587 6.81815 13.6397 7.65438 13.3112L9.17366 14.8305C11.6447 13.648 13.648 11.6447 14.8305 9.17367L13.3112 7.65438C13.6397 6.81816 13.8587 5.92696 13.9508 4.99842C14.0053 4.44883 14.4477 4 15 4H16C18.2091 4 20.0513 5.81867 19.5063 7.95952Z"
                                                            stroke="#242328" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round"/>
                                                    </svg>

                                                    <span className="mal-5">+38 (063) 170-25-85</span>
                                                </div>
                                            </li>
                                            <li className="expirence-element">
                                                <div className="expirence-element--name full flex-a-center">
                                                    <svg width="19" height="15" viewBox="0 0 19 15" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M4.45355 0.204366C7.58819 -0.068122 10.7406 -0.0681222 13.8752 0.204366L15.3854 0.335648C16.3908 0.423043 17.2519 1.02271 17.698 1.88049C17.7551 1.99023 17.7123 2.12309 17.6071 2.18819L11.3412 6.06712C9.99732 6.89904 8.3028 6.91649 6.94209 6.11243L0.634259 2.38508C0.532348 2.32486 0.485571 2.2009 0.531498 2.0918C0.939655 1.12223 1.85729 0.430055 2.94332 0.335648L4.45355 0.204366Z"
                                                            fill="black"/>
                                                        <path
                                                            d="M0.526365 4.06363C0.370402 3.97147 0.171398 4.07065 0.153218 4.25089C-0.100517 6.76653 -0.039106 9.30597 0.337451 11.8101C0.535965 13.1302 1.61337 14.1422 2.94332 14.2578L4.45355 14.3891C7.58819 14.6616 10.7406 14.6616 13.8752 14.3891L15.3854 14.2578C16.7154 14.1422 17.7928 13.1302 17.9913 11.8101C18.3788 9.233 18.4326 6.61849 18.1525 4.03103C18.1329 3.84951 17.9305 3.75216 17.7753 3.84826L12.1307 7.34252C10.3125 8.46805 8.01996 8.49167 6.17899 7.40382L0.526365 4.06363Z"
                                                            fill="black"/>
                                                    </svg>

                                                    <span className={'mal-5'}>info@dominanta-d.com</span></div>
                                            </li>
                                            <li className="expirence-element">
                                                <div className="expirence-element--name full flex-a-center">

                                                    {/*<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                                    {/*    <path d="M12.5 13.2812C14.2259 13.2812 15.625 11.8821 15.625 10.1562C15.625 8.43036 14.2259 7.03125 12.5 7.03125C10.7741 7.03125 9.375 8.43036 9.375 10.1562C9.375 11.8821 10.7741 13.2812 12.5 13.2812Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
                                                    {/*    <path d="M20.8337 10.0968C20.8337 17.3083 12.5003 22.9173 12.5003 22.9173C12.5003 22.9173 4.16699 17.3083 4.16699 10.0968C4.16699 7.97167 5.04497 5.93358 6.60777 4.43089C8.17057 2.92819 10.2902 2.08398 12.5003 2.08398C14.7105 2.08398 16.8301 2.92819 18.3929 4.43089C19.9557 5.93358 20.8337 7.97167 20.8337 10.0968V10.0968Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
                                                    {/*</svg>*/}


                                                    {/*<span className={'mal-5'}>          {t('Центральний офіс Пушкінська вулиця, 32')} </span> */}
                                                    <AppNetworks/>
                                                </div>
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="top-form">
                                <div className="top-form--realtor">
                                    <div className="top-form--realtor-text">
                                        <p>
                                            {t(
                                                "Залишіть Ваш номер телефону та Вам передзвонять протягом 5 хвилин",
                                            )}
                                        </p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="top-form--realtor-form">
                                            <CssTextField
                                                error={textError}
                                                margin="dense"
                                                id="name"
                                                label={t("Ваше ім'я")}
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={textValue}
                                                onChange={changeInputValue}
                                            />
                                            <br/>
                                            <br/>
                                            <br/>
                                            <PhoneInput
                                                variant="outlined"
                                                error={textError}
                                                regions={"europe"}
                                                country={"ua"}
                                                onlyCountries={["ua"]}
                                                disableSearchIcon={true}
                                                value={phone}
                                                onChange={changePhoneValue}
                                                specialLabel={""}
                                                placeholder={"380 (00) 000 00 00"}
                                                isValid={(inputNumber, country, countries) => {
                                                    const isValid = countries.some((country) => {
                                                        return (
                                                            startsWith(inputNumber, country.dialCode) ||
                                                            startsWith(country.dialCode, inputNumber)
                                                        );
                                                    });
                                                    if (
                                                        isPhoneCompleted !== isValid &&
                                                        phone.length >= 12
                                                    ) {
                                                        setIsPhoneCompleted(isValid);
                                                    }
                                                    return isValid;
                                                }}
                                            />
                                            <div className="top-sale top-sale--dialog">
                                                <div className="viewing-action-row">
                                                    <button
                                                        disabled={!isPhoneCompleted}
                                                        className="viewing-action"
                                                    >
                                                        {" "}
                                                        {t("Надіслати")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="map--wrap">
                        <GoogleMapComponent options={options} onDragEnd={(map) => {
                        }} useInfoWindow={false}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactsContent;
