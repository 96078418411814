import { createSlice } from "@reduxjs/toolkit";

export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    value: {
      floorVariants: [],
      roomsVariant: [],
      terraceVariants: [],
      populars: [],
      floors: [],
      buildingType: [],
      bathRoomTypes: [],
      repair: [],
      numberOfStoreys: [],
      heating: [],
      walls: [],
      districts: [],
      cities: [],
      pricePresets: [],

      housesVariants: [],
      // prices:{},
      // totalArea:{},
      // livingArea:{},
      // kitchenArea:{},
      // prices:{},
    },
    activeFilterTab: "",
    defaultValue: {},
  },
  reducers: {
    setFilter: (state, action) => {
      state.value = action.payload;
      state.defaultValue = { ...action.payload };
    },
    setActiveFilterTab: (state, action) => {
      state.activeFilterTab = action.payload;
    },
    //todo it's ???
    setResetFilter: (state, action) => {
      // state.value=action.payload;
      state.value = { ...state.defaultValue };
    },
  },
});

// Action creators are generated for each case reducer function
// export const { setFilter,  decrement, incrementByAmount } = filterSlice.actions
export const { setFilter, setActiveFilterTab, setResetFilter } =
  filterSlice.actions;

export default filterSlice.reducer;
