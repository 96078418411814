import React, { useState, Fragment, useEffect } from "react";
import PriceField from "/src/components/AppHeader/components/HomePageFilterFields/Fields/PriceField";
import NumberOfRooms from "/src/components/AppHeader/components/HomePageFilterFields/Fields/NumberOfRooms";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import SearchButton from "/src/components/ui/buttons/SearchButton";
import { useHistory } from "react-router-dom";
import SelectAutocomplete from "/src/components/ui/selects/SelectAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  setBuildingType,
  setCity,
  setDistrict,
  setFloors,
  setHeating,
  setNumberOfStoreys,
  setPopulars,
  setRepair,
  setResetSelectedFilter,
  setWalls,
} from "/src/store/reducers/selectedFilterData";

import useSearchMove from "/src/hooks/useSearchMove";
import RangeFieldsetBlock from "../../../ui/sliders/RangeFieldsetBlock";
import {setArea, setPurposes} from "../../../../store/reducers/selectedFilterData";
import { useTranslation } from "react-i18next";
import { FilterButtonExtendSearchIcon } from "../../../vector/FIlterIcons";
import FilterButtonGroup from "../../../Filter/FilterButtonGroup";

const CommerceFilterBlock = () => {
  const search = useSearchMove("commerce");
  const cities = useSelector((state) => state.filter.value.cities);
  const districts = useSelector((state) => state.filter.value.districts);
  const prices = useSelector((state) => state.filter.value.prices);
  const areas = useSelector((state) => state.filter.value.totalArea);
  // const livingAreas = useSelector((state) => state.filter.value.livingArea);
  // const kitchenAreas = useSelector((state) => state.filter.value.kitchenArea);
  // const roomsVariant = useSelector((state) => state.filter.value.roomsVariant);
  const previousTotal = useSelector((state) => state.previousResults.total);

  //extend filter
  // const populars = useSelector((state) => state.filter.value.populars);
  const purposes = useSelector((state) => state.filter.value.purposes);
  // const floors = useSelector((state) => state.filter.value.floors);
  const numberOfStoreys = useSelector(
    (state) => state.filter.value.numberOfStoreys,
  );
  // const buildingType = useSelector((state) => state.filter.value.buildingType);
  // const heating = useSelector((state) => state.filter.value.heating);
  // const walls = useSelector((state) => state.filter.value.walls);
  // const repair = useSelector((state) => state.filter.value.repair);
  //extend filter

  const [defaultCitiId, setDefaultCitiId] = useState(33); // odessa todo change it
  const [defaultDistrictId, setDefaultDistrictId] = useState(8); // odessa todo change it

  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

  //selected filter only read write only components or callback
  const dispatch = useDispatch();
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  const selectedArea = useSelector(
    (state) => state.selectedFilterData.value.totalArea,
  );
  // const selectedLivingArea = useSelector(
  //   (state) => state.selectedFilterData.value.livingArea,
  // );
  // const selectedKitchenArea = useSelector(
  //   (state) => state.selectedFilterData.value.kitchenArea,
  // );
  const { t, i18n } = useTranslation();
  const [renderKey, setRenderKey] = useState(Math.random());
  const [hasReset, setHasReset] = useState(0);
  useEffect(() => {
    dispatch(setResetSelectedFilter({}));
  }, []);
  useEffect(() => {
    //fix update filted
    setRenderKey(Math.random());
  }, [cities, districts]);

  return (
    <Fragment>
      <div className="filter-fields-group">
        <SelectAutocomplete
          options={cities}
          key={renderKey}
          title={t("Виберіть місто")}
          defaultValueId={defaultCitiId}
          onChange={(e, value, reason) => {
            dispatch(setCity(value.id));
          }}
          hasReset={hasReset}
          // value={123}
        />

        <SelectAutocomplete
          key={renderKey + 1}
          options={districts}
          title={t("Виберіть район")}
          defaultValueId={defaultDistrictId}
          onChange={(e, value, reason) => {
            dispatch(setDistrict(value.id));
          }}
          hasReset={hasReset}
        />

        <PriceField title={t("Ціна")} values={prices} hasReset={hasReset} />

        {/*                <div className="filter-fields-group__cell">
                    <div className="filter-fields-group__cell-label">{t("Кількість кімнат")}</div>
                    <NumberOfRooms options={roomsVariant} selected={[]} callbackChange={f=>f} />
                </div>*/}

        <div className="filter-fields-group__cell">
          <div className="filter-fields-group__cell-label">
            <br />
          </div>
          <div className="form-actions">
            <SearchButton onClick={search.moveToFilter} />
            <button
              onClick={setShowAdvancedFilter.bind(null, !showAdvancedFilter)}
              className={
                showAdvancedFilter
                  ? "advanced-search show-filter"
                  : "advanced-search"
              }
            >
              <FilterButtonExtendSearchIcon />
              {t("Розширений пошук")}
            </button>
          </div>
        </div>
      </div>

      {showAdvancedFilter && (
        <div className="filter-advanced-fields">
          <div className="filter-advanced-fields__row">
            <FilterButtonGroup
              options={purposes}
              title={t("Призначення")}
              callbackChange={(data) => {
                const selectedSlugs = data.map((e) => e.slug).join(",");
                dispatch(setPurposes(selectedSlugs));
              }}
              selected={[]}
              hasReset={hasReset}
            />
            <div className="fields__cell">
              <div className="filter-advanced-fields__row">
                <RangeFieldsetBlock
                  key={renderKey}
                  title={t("Загальна площа")}
                  handlerMessage={(valueMin, valueMax) => {
                    return (
                      <React.Fragment>
                        {valueMin}м<sup>2</sup> - {valueMax}м<sup>2</sup>
                      </React.Fragment>
                    );
                  }}
                  values={selectedArea}
                  baseValues={areas}
                  dispatcherFn={setArea}
                  hasReset={hasReset}
                />
              </div>
            </div>
          </div>

          <div className="filter-advanced-fields__row">
            {/*<FilterButtonGroup options={floors} title={t("Поверх")}
                                       callbackChange={data=>{
                                           const selectedSlugs=data.map(e=>e.slug).join(',')
                                           dispatch(setFloors(selectedSlugs))
                                       }} selected={[]}
                    />*/}

            <div className="fields__cell">
              <div className="filter-advanced-fields__row">
                <FilterButtonGroup
                  options={numberOfStoreys}
                  title={t("Кількість поверхів")}
                  callbackChange={(data) => {
                    const selectedSlugs = data.map((e) => e.slug).join(",");
                    dispatch(setNumberOfStoreys(selectedSlugs));
                  }}
                  selected={[]}
                  hasReset={hasReset}
                />
              </div>
            </div>
          </div>

          {/*<div className="filter-advanced-fields__row">
                    <FilterButtonGroup options={buildingType} title={t("Тип будівлі")}
                                       callbackChange={data=>{
                                           const selectedSlugs=data.map(e=>e.slug).join(',')
                                           dispatch(setBuildingType(selectedSlugs))
                                       }} selected={[]}
                    />


                    <div className="fields__cell">
                        <div className="filter-advanced-fields__row">
                            <FilterButtonGroup options={heating} title={t("Опалення")}
                                               callbackChange={data=>{
                                                   const selectedSlugs=data.map(e=>e.slug).join(',')
                                                   dispatch(setHeating(selectedSlugs))
                                               }} selected={[]}
                            />
                            <FilterButtonGroup options={walls} title={t("Стіни")}
                                               callbackChange={data=>{
                                                   const selectedSlugs=data.map(e=>e.slug).join(',')
                                                   dispatch(setWalls(selectedSlugs))
                                               }} selected={[]}
                            />
                        </div>
                    </div>
                </div>*/}

          <div className="filter-advanced-fields__row">
            {/*                    <FilterButtonGroup options={repair} title={t("Ремонт")}
                                       callbackChange={data=>{
                                           const selectedSlugs=data.map(e=>e.slug).join(',')
                                           dispatch(setRepair(selectedSlugs))
                                       }} selected={[]}
                    />*/}
            <div className="fields__cell">
              <div className="filter-advanced-fields__row flex-end">
                <div className="fields__cell">
                  <button
                    className="clear-filter"
                    onClick={(e) => {
                      //todo it's ???
                      dispatch(setResetSelectedFilter({}));
                      setHasReset(Math.random());
                      // dispatch(setResetFilter({}))
                    }}
                  >
                    {t("Очистити фільтри")}
                  </button>
                </div>

                <div className="fields__cell">
                  <button
                    className="show-results"
                    onClick={search.moveToFilter.bind(this, "commerce")}
                  >
                    {t("Показати")} {previousTotal} {t("пропозиції")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default CommerceFilterBlock;

/*
import React, {useState, Fragment, useEffect} from 'react'
import PriceField from "/src/components/AppHeader/components/HomePageFilterFields/Fields/PriceField";
import NumberOfRooms from "/src/components/AppHeader/components/HomePageFilterFields/Fields/NumberOfRooms";
import Slider, {SliderThumb} from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import SearchButton from "/src/components/ui/buttons/SearchButton";
import {useHistory} from "react-router-dom";
import SelectAutocomplete from "/src/components/ui/selects/SelectAutocomplete";
import {useDispatch, useSelector} from "react-redux";
import {
    setBuildingType,
    setCity,
    setDistrict,
    setFloors, setHeating,
    setNumberOfStoreys,
    setPopulars, setRepair, setResetSelectedFilter, setWalls
} from "/src/store/reducers/selectedFilterData";
import FilterButtonGroup from "/src/components/Filter/FilterButtonGroup";
import useSearchMove from "/src/hooks/useSearchMove";
import RangeFieldsetBlock from "../../../ui/sliders/RangeFieldsetBlock";
import {setArea, setKitchenArea, setLivingArea, setPrice} from "../../../../store/reducers/selectedFilterData";
import {setResetFilter} from "../../../../store/reducers/filter";
import {useTranslation} from "react-i18next";



const CommerceFilterBlock = () => {

    const search = useSearchMove('commerce');
    const cities = useSelector((state) => state.filter.value.cities)
    const districts = useSelector((state) => state.filter.value.districts)
    const prices = useSelector((state) => state.filter.value.prices)
    const areas = useSelector((state) => state.filter.value.totalArea)
    const livingAreas = useSelector((state) => state.filter.value.livingArea)
    const kitchenAreas = useSelector((state) => state.filter.value.kitchenArea)
    const roomsVariant = useSelector((state) => state.filter.value.roomsVariant)
    const previousTotal = useSelector((state) => state.previousResults.total)

    //extend filter
    const populars = useSelector((state) => state.filter.value.populars)
    const floors = useSelector((state) => state.filter.value.floors)
    const numberOfStoreys = useSelector((state) => state.filter.value.numberOfStoreys)
    const buildingType = useSelector((state) => state.filter.value.buildingType)
    const heating = useSelector((state) => state.filter.value.heating)
    const walls = useSelector((state) => state.filter.value.walls)
    const repair = useSelector((state) => state.filter.value.repair)
    //extend filter


    const [defaultCitiId, setDefaultCitiId] = useState('33')// odessa todo change it
    const [defaultDistrictId, setDefaultDistrictId] = useState('8')// odessa todo change it

    const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)

    //selected filter only read write only components or callback
    const dispatch = useDispatch()
    const selectedFilterData = useSelector((state) => state.selectedFilterData.value)
    const selectedArea = useSelector((state) => state.selectedFilterData.value.totalArea)
    const selectedLivingArea= useSelector((state) => state.selectedFilterData.value.livingArea)
    const selectedKitchenArea = useSelector((state) => state.selectedFilterData.value.kitchenArea)
    const { t, i18n } = useTranslation();
    const [renderKey,setRenderKey]=useState(Math.random())

    useEffect(()=>{
        //fix update filted
        setRenderKey(Math.random())
    },[cities,districts])

    return (
        <Fragment>
            <div className='filter-fields-group'>

                <SelectAutocomplete

                    options={cities} key={renderKey} title={ t("Виберіть місто")}
                    defaultValueId={defaultCitiId}
                    onChange={(e, value, reason) => {
                        dispatch(setCity(value.id))
                    }}
                    // value={123}
                />

                <SelectAutocomplete key={renderKey+1} options={districts} title={ t("Виберіть район")}
                                    defaultValueId={defaultDistrictId}
                                    onChange={(e, value, reason) => {
                                        dispatch(setDistrict(value.id))
                                    }}/>


                <PriceField  title={ t('Ціна')} values={prices} />



                <div className="filter-fields-group__cell">
                    <div className="filter-fields-group__cell-label">{t("Кількість кімнат")}</div>
                    <NumberOfRooms options={roomsVariant} selected={[]} callbackChange={f=>f} />
                </div>


                <div className="filter-fields-group__cell">
                    <div className="filter-fields-group__cell-label"><br/></div>
                    <div className="form-actions">
                        <SearchButton onClick={search.moveToFilter}/>
                        <button onClick={setShowAdvancedFilter.bind(null, !showAdvancedFilter)}
                                className={showAdvancedFilter ? 'advanced-search show-filter' : 'advanced-search'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 6H6" stroke="black" strokeWidth="1.5"/><path d="M3 18H6" stroke="black" strokeWidth="1.5"/><path d="M14 6L21 6" stroke="black" strokeWidth="1.5"/><path d="M14 18L21 18" stroke="black" strokeWidth="1.5"/><path d="M3 12H15" stroke="black" strokeWidth="1.5"/><rect x="8" y="4" width="4" height="4" rx="1.5" stroke="black" strokeWidth="1.5"/><rect x="8" y="16" width="4" height="4" rx="1.5" stroke="black" strokeWidth="1.5"/><rect x="17" y="10" width="4" height="4" rx="1.5" stroke="black" strokeWidth="1.5"/></svg>
                            {t("Розширений пошук")}
                        </button>
                    </div>
                </div>
            </div>

            {showAdvancedFilter && <div className='filter-advanced-fields'>
                <div className="filter-advanced-fields__row">

                    <FilterButtonGroup options={populars} title={t("Популярні запити")}
                                       callbackChange={data=>{
                                           const selectedSlugs=data.map(e=>e.slug).join(',')
                                           dispatch(setPopulars(selectedSlugs))
                                       }} selected={[]}
                    />
                    <div className="fields__cell">
                        <div className="filter-advanced-fields__row">
                            <RangeFieldsetBlock title={t("Загальна площа")} handlerMessage={(valueMin,valueMax)=>{return  (<React.Fragment>{valueMin}м<sup>2</sup> - {valueMax}м<sup>2</sup></React.Fragment>)}}
                                                values={selectedArea} baseValues={areas}
                                                dispatcherFn={setArea}/>

                            <RangeFieldsetBlock title={t("Житлова площа")} handlerMessage={(valueMin,valueMax)=>{return  (<React.Fragment>{valueMin}м<sup>2</sup> - {valueMax}м<sup>2</sup></React.Fragment>)}}
                                                values={selectedLivingArea} baseValues={livingAreas}
                                                dispatcherFn={setLivingArea}/>

                        </div>
                    </div>
                </div>

                <div className="filter-advanced-fields__row">
                    <FilterButtonGroup options={floors} title={t("Поверх")}
                                       callbackChange={data=>{
                                           const selectedSlugs=data.map(e=>e.slug).join(',')
                                           dispatch(setFloors(selectedSlugs))
                                       }} selected={[]}
                    />


                    <div className="fields__cell">
                        <div className="filter-advanced-fields__row">
                            <FilterButtonGroup options={numberOfStoreys} title={t("Кількість поверхів")}
                                               callbackChange={data=>{
                                                   const selectedSlugs=data.map(e=>e.slug).join(',')
                                                   dispatch(setNumberOfStoreys(selectedSlugs))
                                               }} selected={[]}
                            />

                            <RangeFieldsetBlock title={t("Площа кухні")} handlerMessage={(valueMin,valueMax)=>{return  (<React.Fragment>{valueMin}м<sup>2</sup> - {valueMax}м<sup>2</sup></React.Fragment>)}}
                                                values={selectedKitchenArea} baseValues={kitchenAreas}
                                                dispatcherFn={setKitchenArea}/>

                        </div>
                    </div>
                </div>

                <div className="filter-advanced-fields__row">
                    <FilterButtonGroup options={buildingType} title={t("Тип будівлі")}
                                       callbackChange={data=>{
                                           const selectedSlugs=data.map(e=>e.slug).join(',')
                                           dispatch(setBuildingType(selectedSlugs))
                                       }} selected={[]}
                    />


                    <div className="fields__cell">
                        <div className="filter-advanced-fields__row">
                            <FilterButtonGroup options={heating} title={t("Опалення")}
                                               callbackChange={data=>{
                                                   const selectedSlugs=data.map(e=>e.slug).join(',')
                                                   dispatch(setHeating(selectedSlugs))
                                               }} selected={[]}
                            />
                            <FilterButtonGroup options={walls} title={t("Стіни")}
                                               callbackChange={data=>{
                                                   const selectedSlugs=data.map(e=>e.slug).join(',')
                                                   dispatch(setWalls(selectedSlugs))
                                               }} selected={[]}
                            />
                        </div>
                    </div>
                </div>

                <div className="filter-advanced-fields__row">
                    <FilterButtonGroup options={repair} title={t("Ремонт")}
                                       callbackChange={data=>{
                                           const selectedSlugs=data.map(e=>e.slug).join(',')
                                           dispatch(setRepair(selectedSlugs))
                                       }} selected={[]}
                    />
                    <div className="fields__cell">
                        <div className="filter-advanced-fields__row flex-end">
                            <div className="fields__cell">
                                <button className='clear-filter' onClick={e=>{
                                    //todo it's ???
                                    dispatch(setResetSelectedFilter({}))
                                    // dispatch(setResetFilter({}))

                                }}>{t("Очистити фільтри")}</button>
                            </div>

                            <div className="fields__cell">
                                <button className='show-results' onClick={search.moveToFilter.bind(this,'commerce')}>{t("Показати")} {previousTotal} {t("пропозиції")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </Fragment>

    )
}*/
