import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import { useDispatch, useSelector } from "react-redux";
import {
  removePrice,
  setDistrict,
  setPrice,
  setRooms,
} from "../../store/reducers/selectedFilterData";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";

const PriceSelect = ({ type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.filter.value.pricePresets);

  const [selectedItems, setSelectedItems] = useState(null);
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  // const selectedPrice = useSelector((state) => state.selectedFilterData.value.price)
  const search = useSearchMove(type);
  const handlerSelectChange = (event, newValue) => {
    if (newValue) {
      let overriddenData = { price: newValue?.slug ?? "" };
      search.moveToFilterOverridden(overriddenData);
    } else {
      if (items.length) {
        let overriddenData = { price: items[0].slug };
        search.moveToFilterOverridden(overriddenData);
      }
    }
    setSelectedItems(newValue);
  };
  useEffect(() => {
    if (items.length && selectedFilterData.price) {
      const filtered = items.find((e) => e.slug === selectedFilterData.price);
      setSelectedItems(filtered);
    }
  }, [items, selectedFilterData]);
  return (
    <React.Fragment>
      <SelectItems
        items={items}
        selected={selectedItems}
        label={t("Ціна")}
        multiple={false}
        callbackChange={handlerSelectChange}
      />
    </React.Fragment>
  );
};

PriceSelect.propTypes = {};

export default PriceSelect;
