import React from 'react';
import {useTranslation} from "react-i18next";
const FloorNumberText = ({ model }) => {

    const { t, i18n } = useTranslation();

    if (model.type === "house") {
        return (
            <>{model.max_floor} {t("поверховий")}</>
        )
    }else if (model.type === "commerce") {
       return <>{model.floor} {t("поверх")}</>
    }   else{
        return <>{model.floor}  {t("поверх з")} {model.max_floor}</>
    }

};


export default FloorNumberText;