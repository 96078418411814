import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import { useDispatch, useSelector } from "react-redux";
import { setRooms } from "../../store/reducers/selectedFilterData";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";

const CountItemsSelect = ({ type }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  const roomsVariant = useSelector((state) => state.filter.value.roomsVariant);
  const search = useSearchMove(type);
  const handlerSelectRoomsChange = (event, newValue) => {
    const selectedSlugs = newValue.map((e) => e.slug).join("-");
    setSelectedRooms(newValue);
    dispatch(setRooms(selectedSlugs));
    let overriddenData = { rooms: selectedSlugs };
    search.moveToFilterOverridden(overriddenData);
  };
  useEffect(() => {
    if (roomsVariant && roomsVariant.length && selectedFilterData.rooms) {
      //todo fix get params cutted plus
      const splitted = selectedFilterData.rooms.split("-").map((e) => {
        if (e.trim() === "four") {
          return "four+";
        }
        return e;
      });
      const filtered = roomsVariant.filter(
        (e) => splitted.findIndex((d) => e.slug === d) > -1,
      );
      setSelectedRooms(filtered);
    }
  }, [roomsVariant, selectedFilterData]);

  if (!roomsVariant || !roomsVariant.length) {
    return null;
  }
  return (
    <React.Fragment>
      <SelectItems
        limitTags={1}
        items={roomsVariant}
        selected={selectedRooms}
        label={t("Кількість кімнат")}
        multiple={true}
        callbackChange={handlerSelectRoomsChange}
      />
    </React.Fragment>
  );
};

CountItemsSelect.propTypes = {};

export default CountItemsSelect;
