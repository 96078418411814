import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import AddToFavorites from "../ui/AddToFavorites";
const settings = {
  dots: false,
  infinite: false,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const RoomCarousel = ({ items, model }) => {
  if (!items) return null;
  return (
    <div className="primary-room--carousel">
      <Slider {...settings} key={"mob_deals"}>
        {items &&
          items.map((obj, index) => {
            return (
              <div key={index} className="primary-room--carousel_item">
                <img src={obj.url} alt={model.title} />
                <div className="primary-room--carousel_item-mask">
                  <div className="thumbnails-counter">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5 19.5H4.5C4.10218 19.5 3.72064 19.342 3.43934 19.0607C3.15804 18.7794 3 18.3978 3 18V7.5C3 7.10218 3.15804 6.72064 3.43934 6.43934C3.72064 6.15804 4.10218 6 4.5 6H7.49945L8.99945 3.75H14.9995L16.4995 6H19.5C19.8978 6 20.2794 6.15804 20.5607 6.43934C20.842 6.72064 21 7.10218 21 7.5V18C21 18.3978 20.842 18.7794 20.5607 19.0607C20.2794 19.342 19.8978 19.5 19.5 19.5Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 15.75C13.864 15.75 15.375 14.239 15.375 12.375C15.375 10.511 13.864 9 12 9C10.136 9 8.625 10.511 8.625 12.375C8.625 14.239 10.136 15.75 12 15.75Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="thumbnails-counter--row">
                      <span>{index + 1}</span>/<span>{items.length}</span>
                    </div>
                  </div>

                </div>
              </div>
            );
          })}
      </Slider>
        <div className="abs-wrapper-favorites">
            <AddToFavorites id={model.id}/>
        </div>

    </div>
  );
};
RoomCarousel.propTypes = {
  items: PropTypes.array,
  model: PropTypes.object,
};
export default RoomCarousel;
