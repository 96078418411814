import { createSlice } from "@reduxjs/toolkit";

export const activeItemSlice = createSlice({
  name: "activeItem",
  initialState: {
    itemId: null,
  },
  reducers: {
    setItem: (state, action) => {
      state.itemId = action.payload;
    },
    clear: (state, action) => {
      state.itemId = null;
    },
  },
});
export const { setItem, clear } = activeItemSlice.actions;
export default activeItemSlice.reducer;
