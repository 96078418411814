import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const RoundButtonLi = (props) => {
  return (
    <li
      className={clsx("option", { active: props.status })}
      onClick={props.callback}
    >
      {props.model.label}
      <button>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.50024 4.5L11.5004 11.5001"
            stroke="white"
            strokeLinejoin="round"
          />
          <path
            d="M4.50024 11.5L11.5004 4.49988"
            stroke="white"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </li>
  );
};

RoundButtonLi.defaultProps = {
  status: false,
};

RoundButtonLi.propTypes = {
  model: PropTypes.object.isRequired,
  status: PropTypes.bool,
  callback: PropTypes.func.isRequired,
};

export default RoundButtonLi;
