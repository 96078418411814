import { createSlice } from "@reduxjs/toolkit";

export const objectsSlice = createSlice({
  name: "objects",
  initialState: {
    data: { data: [], links: {}, meta: {} },
  },
  reducers: {
    putObjects: (state, action) => {
      state.data = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { putObjects } = objectsSlice.actions;
export default objectsSlice.reducer;
