import React, { useEffect, useState } from "react";

import AppFooter from "/src/components/AppFooter";
// import Consultation from "../../components/Sell/Consultation";
import BreadCrumbs from "/src/components/ui/BreadCrumbs";

import { useDispatch, useSelector } from "react-redux";
import { putObjectBreadCrumbs } from "../../store/reducers/object";

import AppHeader from "../../components/AppHeader";

import { useParams } from "react-router-dom";
import APP_CONFIG from "../../config";
import HowDoesItWork from "../../components/Sell/HowDoesItWork";
import { useTranslation } from "react-i18next";
import OurBenefits from "../../components/Sell/OurBenefits";
import DialogBooking from "../../components/Dialog/DialogBooking";
import BrandBlock from "../../components/AboutUs/BrandBlock";
import AboutUsTeamImage from "/src/media/about-us/about_us_team.png"
// import AboutUsSkyscraperImage from "/src/media/about-us/about_us_skyscraper.png"
// import AboutUsHouseImage from "/src/media/about-us/about_us_house.png"
// import AboutUsCelebrationImage from "/src/media/about-us/about_us_celebration.png"
import HomePagePartners from "../../components/HomePagePartners";
import OurAchievements from "../../components/AboutUs/OurAchievements";
import OurReviews from "../../components/AboutUs/OurReviews";
import ConsultationBlock from "../../components/AboutUs/ConsultationBlock";
import ImageSlider from "../../components/AboutUs/ImageSlider";
const AboutUs = () => {
  const dispatch = useDispatch();
  const breadCrumbs = useSelector((state) => state.object.breadCrumbs);
  const { t, i18n } = useTranslation();
  let { slug } = useParams();
  useEffect(() => {
      window.scrollTo(0, 0)
    dispatch(putObjectBreadCrumbs([...APP_CONFIG.breadCrumbs.defaultAboutUs]));
  }, [dispatch]);

  return (
    <div className=" catalog-page sell-page about-us-page">
      <AppHeader />
      <div className="realtor-page--layout sell-page--layout ">
        <div className="room--cell">
          <div className="breadcrumb-wrapper">
            <BreadCrumbs linkChain={breadCrumbs} />
          </div>
        </div>
      </div>

      <div className="home-page--about-description2 ">
        <div className="sell-page--layout">
          <div className="room--cell">
            <BrandBlock />
          </div>
        </div>
      </div>

      <div className="home-page--about-description">
        <div className="container-about-us">


          <div className="about-us-description--row">

            <div className="about-us-text-cell">

                <p>
                    <h4 className="about-us-description-text">
                        {t(
                            "Dominanta - твій ключ",
                        )}
                        <br/>{t("в світ нерухомості")}
                    </h4>
                  {t(
                      "Агенція нерухомості «Dominanta» працює на ринку нерухомості вже 12+ років і має експертизу в галузі купівлі та продажу житлової та комерційної нерухомості. З 2023 року агентство входить до структури Dominanta corporation."
                  )}
              </p>
            </div>
            <img src={AboutUsTeamImage} className="about-us-team-image" alt="first image"></img>
          </div>
            <div className="about-us-second-description--row">
                <div className="about-us-second-text-cell">
                    <p>
                        {t("Агентство нерухомості «Dominanta» створює можливості для реалізації бажань клієнтів на ринку нерухомості. Наші послуги є джерелом позитивних змін у житті наших клієнтів. Критерії нашої роботи - уявлення сучасної людини про першокласні послуги. Саме тому розвиток сервісу і кожного агента особисто - не просто гучна заява, а поступальна і копітка робота."                 )}
                    </p>
                </div>
            </div>
            <div className="about-us-third-description--row">
                <div className="about-us-second-text-cell">
                    <p>
                        {t(
                            "Ми поважаємо індивідуальність вибору кожного клієнта, адже цілі наших клієнтів - наші цілі! Ми робимо все, щоб досягти їх. І робимо це не тому що мусимо, а тому що хочемо це робити. Ґрунтуючись на принципах нашої роботи - ясності, чесності та прозорості - ми гарантуємо високий рівень використовуваних схем і стандартів, а також професіоналізм і відповідальність усіх співробітників компанії."
                        )}
                    </p>
                </div>
            </div>
            <div className="about-us-description--row">
                <div className="about-us-text-cell">
                    <p>
                        {t(
                            "Ми ретельно підбираємо і перевіряємо кожну пропозицію, щоб відповідати вашим очікуванням. Наші експерти забезпечать професійну фотосесію, створять привабливі оголошення та проведуть переговори з потенційними покупцями."
                        )}
                    </p>
                </div>
            </div>
            <div className="about-us-third-description--row">
                <div className="about-us-second-text-cell">
                    <p>
                        {t(
                            "У Dominanta ми віримо, що довіра - це ключ до успішної співпраці. Ми пишаємося нашою репутацією і прагнемо до того, щоб кожен клієнт почувався в безпеці та знав, що його нерухомість у надійних руках. Ваша мрія про новий будинок - наша головна мета"
                        )}
                    </p>
                </div>
            </div>
        </div>
      </div>

      {/*//todo fix it style*/}
        <ImageSlider/>
      <OurAchievements/>
      <OurReviews/>
        <div className="home-page--about-description">
            <div className="sell-page--layout" style={{ maxWidth: "1231px" }}>
                <div className="room--cell">
                    <ConsultationBlock/>
                </div>
            </div>
        </div>
      <HomePagePartners />


      <AppFooter />
    </div>
  );
};




export default AboutUs;
