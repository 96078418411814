import React, { useState, useEffect, useRef } from "react";
import AboutLg from "../media/AboutLg.png";
import { useTranslation } from "react-i18next";
import DialogBooking from "./Dialog/DialogBooking";
import {useHistory} from "react-router-dom";



const HomePageAbout = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const handleButtonClick = () => {
    history.push("/about-us");
    window.scrollTo(0, 0);
  };

  return (
    <div className="home-page--about">
      <div className="home-page--about-bars">
        <div className="bars-row">
          <div className="coll">
            <img src={AboutLg} alt={"about"} />

            <div className="mask">
              <h2>
                {t(
                  "Продаж та купівля нерухомості з АН Домінанта - це спосіб швидко і просто здійснити угоду",
                )}{" "}
              </h2>

              <p>
                {t(
                  "Ріелтор агентства зробить всю роботу за вас і супроводжує на кожному етапі, аж до завершального. Тільки так можна отримати задоволеного клієнта",
                )}
              </p>

              <button onClick={handleButtonClick}>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                    fill="white"
                  />
                  <path
                    d="M33.6202 23.1482L26.4692 15.8647C26.0254 15.4127 26.0254 14.6799 26.4692 14.2279C26.913 13.7759 27.6325 13.7759 28.0763 14.2279L37.1672 23.4872C37.6109 23.9392 37.6109 24.672 37.1672 25.124L28.0763 34.3833C27.6325 34.8352 26.913 34.8352 26.4692 34.3833C26.0254 33.9313 26.0254 33.1984 26.4692 32.7464L33.6202 25.463H13.6364C13.0088 25.463 12.5 24.9448 12.5 24.3056C12.5 23.6664 13.0088 23.1482 13.6364 23.1482H33.6202Z"
                    fill="black"
                  />
                </svg>

                {t("Докладніше про нас")}
              </button>
            </div>
          </div>

          <div className="coll">
            <div className="cell">
              <div className="counter">300</div>

              <div className="description">
                {t("фахівців")} <br />
                {t("з нерухомості")}
              </div>
            </div>

            <div className="cell">
              <div className="counter">900</div>

              <div className="description">{t("об'єктів у базі")}</div>
            </div>

            <div className="cell">
              <div className="counter">168</div>

              <div className="description">{t("новобудов у базі")}</div>
            </div>

            <div className="cell">
              <div className="counter">1500</div>

              <div className="description">{t("угод за рік")}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-page--about-description">
        <div className="container">
          <h4 className="description-text">
            {t(
              "Агентство нерухомості Домінанта працює дванадцять років на одеському ринку",
            )}
          </h4>

          <div className="description--row">
            <div className="icon-wrap">
              <svg
                width="184"
                height="139"
                viewBox="0 0 184 139"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_60_277)">
                  <path
                    d="M174.479 87.5123L136.452 97.1045L146.04 135.148L184.067 125.555L174.479 87.5123Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M164.782 87.0373L141.668 71.0588L166.73 59.3672L164.782 87.0373Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M137.901 58.3265C131.798 20.3938 96.0888 -5.32771 58.3019 0.907807C20.3852 7.14332 -5.32547 42.8676 0.907415 80.6704C7.1403 118.473 42.8495 144.195 80.6364 137.959C118.423 131.854 144.134 96.1293 137.901 58.3265ZM66.093 93.1414H48.563V42.8676H66.093C87.3887 42.8676 95.05 50.9218 95.05 68.0695C95.05 85.0872 87.129 93.1414 66.093 93.1414ZM105.178 94.4405C104.01 95.6096 102.581 96.1293 100.893 96.1293C99.2052 96.1293 97.7769 95.6096 96.6082 94.4405C95.4395 93.2713 94.7903 91.8424 94.7903 90.1536C94.7903 88.4648 95.4395 87.0358 96.6082 85.8667C97.7769 84.6975 99.2052 84.1779 100.893 84.1779C102.581 84.1779 104.01 84.6975 105.178 85.8667C106.347 87.0358 106.866 88.4648 106.866 90.1536C106.866 91.8424 106.347 93.2713 105.178 94.4405Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M66.095 50.144H57.0054V85.7384L66.095 85.8683C82.4563 85.8683 86.4817 81.1917 86.4817 68.0711C86.4817 55.3403 82.4563 50.144 66.095 50.144Z"
                    fill="#F1623D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_60_277">
                    <rect width="184" height="139" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="text-cell">
              <p>
                {t(
                  "11 філій по всьому місту, професійні співробітники, які в найкоротші терміни підберуть Вам найкраще вирішення квартирного питання",
                )}
              </p>

              <div className="actions">
                <DialogBooking model={{}} textOrder={"Отримати консультацію"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePageAbout;
