import React from "react";
import InterfacesIcons from "../../vector/InterfacesIcons";

import METADATA from "../../../utils/metadata";

const AppNetworks = (props) => {
  return (
    <React.Fragment>
      <div className="app-header--networks">
          <ul className="app-header--networks--links">
            {METADATA.networksList.map((obj) => {
              return (
                <li key={obj.icon_name} className="app-header--networks--link">
                  <button onClick={obj.action} title={obj.title}>
                    <InterfacesIcons name={obj.icon_name} />
                  </button>
                </li>
              );
            })}
          </ul>
      </div>
    </React.Fragment>
  );
};

export default AppNetworks;
