import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getDefaultById } from "../../../utils/filterUtils";
// import { getDefaultById } from "/src/utils/filterUtils";
const SelectAutocomplete = (props) => {
  const [selectedValue, setSelectedValue] = useState(
    props.defaultValueId && props.options
      ? getDefaultById(props.defaultValueId, props.options)
      : null,
  );
  const handlerChange = (e, value, reason) => {
    props.onChange(e, value, reason);
    setSelectedValue(value);
  };

  useEffect(() => {
    if (props.hasReset) {
      setSelectedValue(
        props.defaultValueId && props.options
          ? getDefaultById(props.defaultValueId, props.options)
          : null,
      );
    }
  }, [props.hasReset]);
  if (!props.options || !props.options.length) return null;

  return (
    <React.Fragment>
      <div className="filter-fields-group__cell">
        <div className="filter-fields-group__cell-label">{props.title}</div>
        <div className="filter-fields-group__cell-input">
          <Autocomplete
            options={props.options}
            onChange={handlerChange}
            value={selectedValue}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
// options =[{label: 'Львів',id:1,slug:'lviv'},...]
SelectAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValueId: PropTypes.string,
  hasReset: PropTypes.number.isRequired,
};

export default SelectAutocomplete;
