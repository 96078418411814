import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const StepsList = [
  {
    index: 1,
    title: "Заявка",
    text: "Ми цінуємо кожного нашого клієнта та прагнемо надати лише найвищий рівень сервісу. Якість та професіоналізм – це основні принципи, якими ми керуємося в роботі. Тому, при зверненні до нас, ви можете бути впевнені, що вам буде надано досвідченого експерта, який спеціалізується саме на вашому типі нерухомості",
  },
  {
    index: 2,
    title: "Консультація",
    text: "Завдяки унікальній базі об'єктів та передовим цифровим технологіям. Ми надаємо клієнтам найбільш релевантну добірку об'єктів, які найкраще відповідають їхнім запитам та потребам",
  },
  {
    index: 3,
    title: "Огляд",
    text: "Ми цінуємо ваш час та комфорт, тому зробимо все можливе, щоб ваше знайомство з нерухомістю було приємним та результативним. Наші тури – це незабутній досвід, який дозволить вам поринути у атмосферу обраного району. Наш агент покаже вам всі цікаві місця, розповість про нерухомість, її історію та архітектуру. Ви дізнаєтеся про інфраструктуру району та інші особливості, які допоможуть вам зробити правильний вибір",
  },
  {
    index: 4,
    title: "Договір",
    text: "Ми готові надати вам якісний супровід під час придбання нерухомості. Наші досвідчені юристи мають високу кваліфікацію та професійний підхід до роботи. Вони проведуть юридичне обстеження об'єкта нерухомості та оцінять усі юридичні та фінансові ризики, пов'язані з угодою",
  },
  // {
  //     index: 5,
  //     title: 'Квартира ваша',
  //     text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  // }
];

const CooperationWork = () => {
  const [openStep, setOpenStep] = useState(StepsList[0]);
  const { t, i18n } = useTranslation();
  const openStepAction = (step) => {
    setOpenStep(step);
  };

  return (
    <div className="home-page--cooperation">
      <div className="container">
        <h2>{t("Як відбувається співпраця")} </h2>
      </div>

      <div className="container step-list">
        {StepsList.map((obj, i) => {
          return (
            <div key={i} className="step-list--row">
              <div
                key={i}
                onClick={() => openStepAction(obj)}
                className={
                  openStep === obj ? "step--cell open-cell" : "step--cell"
                }
              >
                <h3>{t(obj.title)}</h3>

                {openStep === obj && (
                  <div className="fade-block">
                    <p>{t(obj.text)}</p>
                  </div>
                )}
              </div>
              {openStep === obj && (
                <div className="current-step--label">
                  <div className="fade-block">{openStep.index}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CooperationWork;
