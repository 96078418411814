import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/base";
import { Box, Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import { apiGet } from "../../utils/api";
import { useHistory } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { ArrowButtonIcon } from "../vector/CatalogsIcons";

const DialogBooking = ({
                         model,
                         reviewModel = false,
                         textOrder = "Записати на перегляд",
                         callbackLeaveReview = (f) => f,
                         showIcon = false
                       }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [textError, setTextError] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [feedbackValue, setFeedbackValue] = useState("");
  const [phone, setPhone] = useState("0");
  const [email, setEmail] = useState("");
  const [isPhoneCompleted, setIsPhoneCompleted] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFeedbackField, setShowFeedbackField] = useState(false);
  const [rating, setRating] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleOpenFeedback = () => {
    setShowFeedbackField(true);
    handleOpen();
  };
  const handleClose = () => {
    setOpen(false);
    setShowFeedbackField(false);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const changePhoneValue = (value) => {
    setPhone(value);
    if (value.length >= 12) {
      setIsPhoneCompleted(true);
    } else {
      setIsPhoneCompleted(false);
    }
  };

  const changeEmailValue = (event) => {
    const { value } = event.target;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const changeInputValue = (event) => {
    setTextValue(event.target.value);
    if (event.target.value.length >= 3) {
      setTextError(false);
    }
  };

  const changeInputFeedbackValue = (event) => {
    setFeedbackValue(event.target.value);
    if (event.target.value.length >= 3) {
      setTextError(false);
    }
  };

  const sendRequest = () => {
    const params = {
      name: textValue,
      phone: phone,
      email: email,
      modelId: model.id,
      url_object: window.location.href,
    };

    apiGet("user-action-booking-view", params)
        .then((data) => data.data)
        .then((response) => {
          const { status, serverResponce } = response;
          let ojson = JSON.parse(serverResponce.replace(/'/g, '"'));
          const { error_message, error } = ojson;
          if (error_message === "Лид добавлен") {
            if (status) {
              history.push({
                pathname: "/user-success-page",
                state: {
                  update: true,
                },
              });
            } else {
              alert("Error");
            }
          }
        })
        .catch((error) => {
          console.error("Error sending booking request:", error);
          alert("Error occurred while processing your request.");
        });
  };

  const sendRequestFeedback = () => {
    const params = {
      name: textValue,
      phone: phone,
      email: email,
      modelId: model.id,
      responsibleId: reviewModel.id,
      feedback: feedbackValue,
      rating: rating,
      url_object: window.location.href,
    };

    apiGet("user-action-booking-feedback", params)
        .then((data) => data.data)
        .then((response) => {
          const { status, serverResponce } = response;
          let ojson = JSON.parse(serverResponce.replace(/'/g, '"'));
          const { error_message, error } = ojson;
          if (error_message === "Лид добавлен") {
            if (status) {
              history.push({
                pathname: "/user-success-feedback-page",
                state: {
                  update: true,
                },
              });
            } else {
              alert("Error");
            }
          }
        })
        .catch((error) => {
          console.error("Error sending feedback request:", error);
          alert("Error occurred while processing your feedback.");
        });
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (textValue.length < 3) {
      setTextError(true);
      return;
    }
    if (!isPhoneCompleted || !isEmailValid) {
      return;
    }
    if (!showFeedbackField) {
      sendRequest();
    } else {
      sendRequestFeedback();
    }
  }

  return (
      <React.Fragment>
        <div className="viewing-action-row">
          <button onClick={handleOpen} className="viewing-action">
            {t(textOrder)}
            {showIcon && <span className="arrow-button-icon"><ArrowButtonIcon/></span>}
          </button>
          {reviewModel && (
              <button onClick={handleOpenFeedback} className="viewing-action">
                {t("Залишити відгук")}
              </button>
          )}
        </div>

        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle className={"dialog-title"}>
              {showFeedbackField ? t("Залишити відгук") : t("Замовити дзвінок")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {/* Add any dialog content text here */}
              </DialogContentText>
              <TextField
                  autoFocus
                  error={textError}
                  margin="dense"
                  id="name"
                  label={t("Ваше ім'я")}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={textValue}
                  onChange={changeInputValue}
              />
              <br />
              <br />
              <br />
              <PhoneInput
                  error={textError}
                  regions={"europe"}
                  country={"ua"}
                  onlyCountries={["ua"]}
                  disableSearchIcon={true}
                  value={phone}
                  onChange={changePhoneValue}
                  specialLabel={""}
                  placeholder={"380 (00) 000 00 00"}
                  isValid={(inputNumber, country, countries) => {
                    const isValid = countries.some((country) => {
                      return (
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber)
                      );
                    });
                    if (isPhoneCompleted !== isValid && phone.length >= 12) {
                      setIsPhoneCompleted(isValid);
                    }
                    return isValid;
                  }}
              />
              <br />
              <br />
              <br />
              <TextField
                  autoFocus
                  error={!isEmailValid}
                  margin="dense"
                  id="email"
                  label={t("Email")}
                  type="email"
                  fullWidth
                  variant="standard"
                  value={email}
                  onChange={changeEmailValue}
              />
              <br />
              <br />
              <br />
              {showFeedbackField && (
                  <TextField
                      autoFocus
                      error={textError}
                      margin="dense"
                      id="feedback"
                      label={t("Ваш відгук")}
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      variant="standard"
                      value={feedbackValue}
                      onChange={changeInputFeedbackValue}
                  />
              )}

              {showFeedbackField && (
                  <div className="ratingBlock">
                    <Rating onClick={setRating} />
                  </div>
              )}
            </DialogContent>
            <DialogActions className={"top-sale--cell flex-center"}>
              <div className="top-sale top-sale--dialog">
                <div className="viewing-action-row">
                  {!showFeedbackField && (
                      <button
                          disabled={!isPhoneCompleted || !isEmailValid}
                          className="viewing-action"
                      >
                        {" "}
                        {t("Надіслати")}
                      </button>
                  )}
                  {showFeedbackField && (
                      <button
                          disabled={!isPhoneCompleted || !isEmailValid || feedbackValue.length <= 5}
                          className="viewing-action"
                      >
                        {" "}
                        {t("Надіслати")}
                      </button>
                  )}
                </div>
              </div>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
  );
};

DialogBooking.propTypes = {};

export default DialogBooking;
