import React from "react";
import { Pagination as PaginationOuter } from "react-laravel-paginex";
import useSearchMove from "/src/hooks/useSearchMove";

const Pagination = ({ meta, type = "catalog" }) => {
  const search = useSearchMove(type);

  return (
    <PaginationOuter
      changePage={(p) => {
        search.moveToFilterPaginate(p.page);
      }}
      data={meta}
    />
  );
};
export default Pagination;
