import React from "react";
import PropTypes from "prop-types";
import { getQueryString } from "../../utils/filterUtils";
import ApiPort from "../../utils/ApiPort";
import { changeTotal } from "../reducers/previousResults";

const CounterWatcher = (store) => (next) => (action) => {
  let result = next(action);

  //this if check double load count
  if (action.type !== "selectedFilterData/setMass") {
    if (
      action.type.indexOf("selectedFilterData") !== -1 ||
      action.type.indexOf("filter/setFilter") !== -1
    ) {
      const { selectedFilterData, filter } = store.getState();
      let query = getQueryString(selectedFilterData.value);
      const pathArray = window.location.pathname.split("/").slice(1);
      let type = "";
      if (
        pathArray[0] === "catalog" ||
        pathArray[0] === "commerce" ||
        pathArray[0] === "house" ||
        pathArray[0] === "plots"
      ) {
        if (pathArray[0] === "catalog") {
          type = "apartment";
        } else {
          type = pathArray[0];
        }
      } else {
        type = filter.activeFilterTab;
      }

      if (query !== "") {
        query = `${query}&type=${type}`;
      }
      ApiPort.fetchCount(query).then((responce) => {
        const { data } = responce;
        store.dispatch(changeTotal(data.total));
      });
    }
  }
  return result;
};
export default CounterWatcher;
