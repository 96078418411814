import React, { useState, Fragment, useEffect } from "react";
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
// import {filterContainer} from "../../containers/filterContainer";
// import {setFilter} from "../../store/reducers/filter";
// import {useDispatch, useSelector} from "react-redux";
// import {setRooms} from "../../store/reducers/selectedFilterData";
// import SelectItems from "./SelectItems";
import CountItemsSelect from "./CountItemsSelect";
import DistrictItemsSelect from "./DistrictItemsSelect";
import SearchByIdInput from "./SearchByIdInput";
import PriceSelect from "./PriceSelect";
import { useTranslation } from "react-i18next";
import AreaSelect from "./AreaItemsSelect";
import AreaItemsSelect from "./AreaItemsSelect";
import PopularSelect from "./PopularSelect";
import HousesTypesSelect from "./HousesTypesSelect";
import { FilterCatalogMoreFilterIcon } from "../vector/FIlterIcons";
import PlotsFormVariantSelect from "./PlotsFormVariantSelect";

const PlotsCatalogFilter = ({
  showAdvancedFilter,
  setShowAdvancedFilter,
  hasReset,
  type,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="catalog-page--filter_wrap">
      <div className="filter-fields">
        <div className="filter-fields-group">
          <div className="filter-fields-group__cell">
            <PriceSelect type={type} />
          </div>

          {/*<div className="filter-fields-group__cell">*/}
          {/*    <CountItemsSelect />*/}
          {/*</div>*/}

          <div className="filter-fields-group__cell">
            <DistrictItemsSelect type={type} />
          </div>

          <div
            className="filter-fields-group__cell"
            style={{ "padding-right": 0 }}
          >
            <PlotsFormVariantSelect type={type} />
            {/*<PopularSelect type={"house"} />*/}
          </div>
        </div>

        <div className="filter-fields-group">
          <div className="filter-fields-group__cell">
            {/*sam todo fix double reload*/}
            <SearchByIdInput type={'plots'} />
          </div>
          <div className="filter-fields-group__cell">
            <button
              className={
                showAdvancedFilter
                  ? "advanced-filter--toggle active"
                  : "advanced-filter--toggle"
              }
              onClick={setShowAdvancedFilter.bind(null, !showAdvancedFilter)}
            >
              <FilterCatalogMoreFilterIcon />
              {t("Ще фільтри")}
            </button>
          </div>

          <div
            className="filter-fields-group__cell"
            style={{ "padding-right": 0 }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default PlotsCatalogFilter;
