import React from "react";
import APP_CONFIG from "../../config";
import { useTranslation } from "react-i18next";
import AppNetworks from "../AppHeader/components/AppNetworks";

const AppFooter = () => {
  const { t, i18n } = useTranslation();
  const scrollTopAction = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="app-footer">
      <div className="container">
        <div className="app-footer--row">
          <div className="app-footer--cell">
            <div className="contacts">
              <div className="tel">
                <a href={APP_CONFIG.phone.hrefPhone}>
                  {APP_CONFIG.phone.visualPhone}
                </a>
              </div>

              <div className="mail">
                <a href={`mailto:${APP_CONFIG.email}`}>{APP_CONFIG.email}</a>
              </div>

              <AppNetworks />
            </div>

            <div className="bottom-logo">
              <svg
                width="162"
                height="121"
                viewBox="0 0 162 121"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M153.618 76.1793L120.137 84.5293L128.579 117.646L162.059 109.296L153.618 76.1793Z"
                  fill="white"
                />
                <path
                  d="M145.08 75.7656L124.73 61.8563L146.795 51.6787L145.08 75.7656Z"
                  fill="white"
                />
                <path
                  d="M121.413 50.7737C116.04 17.7532 84.6005 -4.63747 51.3316 0.790569C17.9484 6.2186 -4.68817 37.3167 0.799477 70.2242C6.28713 103.132 37.7268 125.522 70.9957 120.094C104.265 114.779 126.901 83.6812 121.413 50.7737ZM58.1912 81.0803H42.7571V37.3167H58.1912C76.9406 37.3167 83.6859 44.3279 83.6859 59.255C83.6859 74.069 76.712 81.0803 58.1912 81.0803ZM92.6033 82.2111C91.5743 83.2289 90.3168 83.6812 88.8305 83.6812C87.3443 83.6812 86.0867 83.2289 85.0578 82.2111C84.0288 81.1933 83.4572 79.9494 83.4572 78.4793C83.4572 77.0092 84.0288 75.7653 85.0578 74.7476C86.0867 73.7298 87.3443 73.2775 88.8305 73.2775C90.3168 73.2775 91.5743 73.7298 92.6033 74.7476C93.6322 75.7653 94.0895 77.0092 94.0895 78.4793C94.0895 79.9494 93.6322 81.1933 92.6033 82.2111Z"
                  fill="white"
                />
                <path
                  d="M58.1918 43.6504H50.189V74.6355L58.1918 74.7485C72.5969 74.7485 76.141 70.6775 76.141 59.256C76.141 48.1738 72.5969 43.6504 58.1918 43.6504Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>

          <div className="app-footer--cell">
            <div className="app-footer--row">
              <div className="footer-links--group">
                <div className="footer-links--group_title">{t("Послуги")}</div>

                <ul className="footer-links">
                  <li className="footer-link">
                    <a href="/catalog">{t("Придбати")}</a>
                  </li>

                  <li className="footer-link">
                    <a href="/sell">{t("Продати")}</a>
                  </li>

                  {/*<li className="footer-link">*/}
                  {/*  <a href="">{t("Оцінити")}</a>*/}
                  {/*</li>*/}
                </ul>
              </div>

              <div className="footer-links--group">
                <div className="footer-links--group_title">{t("Придбати")}</div>

                <ul className="footer-links">
                  <li className="footer-link">
                    <a href="catalog?city=33&price=4800-1440000&populars=">{t("Квартири вторинний ринок")}</a>
                  </li>

{/*                  <li className="footer-link">
                    <a href="">{t("Новобудови")}</a>
                  </li>*/}

                  <li className="footer-link">
                    <a href="/house?city=33&price=4800-1440000&populars=">{t("Будинки")}</a>
                  </li>

                  <li className="footer-link">
                    <a href="/plots?city=33&price=4800-1440000&populars=">{t("Ділянки")}</a>
                  </li>

                  <li className="footer-link">
                    <a href="commerce?city=33&price=4800-1440000&populars=">{t("Комерція")}</a>
                  </li>
                </ul>
              </div>

              <div className="footer-links--group">
                <div className="footer-links--group_title">
                </div>
                <br/>
                <ul className="footer-links">
                  <li className="footer-link">
                    <a href="/about-us">{t("Про нас")}</a>
                  </li>

                  <li className="footer-link">
                    <a href="/">{t("Новини")}</a>
                  </li>

                  <li className="footer-link">
                    <a href="/contacts">{t("Контакти")}</a>
                  </li>

                </ul>
              </div>
            </div>

            <div className="copy-row">
              <div className="copyright">
                ©Dominanta 2024. {t("Всі права захищені")}
              </div>

              {/*<div className="designer">Дизайн от Worth</div>*/}
            </div>
          </div>

          <div className="app-footer--cell">
            {/*<button className="app-download">*/}
            {/*  <svg*/}
            {/*    width="24"*/}
            {/*    height="24"*/}
            {/*    viewBox="0 0 24 24"*/}
            {/*    fill="none"*/}
            {/*    xmlns="http://www.w3.org/2000/svg"*/}
            {/*  >*/}
            {/*    <path*/}
            {/*      d="M12 4V14"*/}
            {/*      stroke="white"*/}
            {/*      strokeWidth="1.5"*/}
            {/*      strokeLinecap="round"*/}
            {/*      strokeLinejoin="round"*/}
            {/*    />*/}
            {/*    <path*/}
            {/*      d="M15.5 10.5L12 14L8.5 10.5"*/}
            {/*      stroke="white"*/}
            {/*      strokeWidth="1.5"*/}
            {/*      strokeLinecap="round"*/}
            {/*      strokeLinejoin="round"*/}
            {/*    />*/}
            {/*    <path*/}
            {/*      d="M3 13V20H21V13"*/}
            {/*      stroke="white"*/}
            {/*      strokeWidth="1.5"*/}
            {/*      strokeLinecap="round"*/}
            {/*      strokeLinejoin="round"*/}
            {/*    />*/}
            {/*  </svg>*/}

            {/*  <div>*/}
            {/*    {t("Завантажити")} <br />*/}
            {/*    {t("Застосунок")}*/}
            {/*  </div>*/}
            {/*</button>*/}

            <button onClick={() => scrollTopAction()} className="scroll-top">
              <div className="icon-wrap">
                <svg
                  width="20"
                  height="11"
                  viewBox="0 0 20 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.90869 0.908203L19.5536 10.5531H0.263745L9.90869 0.908203Z"
                    fill="#3F6DB5"
                  />
                </svg>
              </div>

              {t("Вгору")}
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default AppFooter;
