import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import InfoWindow from './InfoWindow';
import { useDispatch, useSelector } from 'react-redux';
import { clear, setItem } from '/src/store/reducers/activeItem';
import clsx from 'clsx';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '16px',
  height: '16px',
  backgroundColor: '#3F6DB5',
  border: '2px  solid  #fff',
  borderRadius: '100%',
};
const Marker = (props) => {
  const itemId = useSelector((state) => state.activeItem.itemId);
  const dispatch = useDispatch();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const markerRef = useRef(null);

  useEffect(() => {
    if (itemId == props.model.id) {
      setShowInfoWindow(true);
    } else {
      setShowInfoWindow(false);
    }
  }, [itemId]);

  useEffect(()=>{
    if (showInfoWindow){
      props.shouldChangePosition(props.model)
    }
  },[showInfoWindow])

  const clickMarker = () => {
    const newStatus = !showInfoWindow;
    //only use on the main map
    if (props.useGlobalDispatch) {
      if (newStatus) {
        dispatch(setItem(props.model.id));
      } else {
        dispatch(clear());
      }
    }
    setShowInfoWindow(newStatus);
  };

  return (
    <React.Fragment>
      <div
        ref={markerRef}
        className={clsx('map-marker', props.className)}
        style={style}
        onClick={clickMarker}
      >

        {props.useInfoWindow &&<InfoWindow
          model={props.model}
          isShow={showInfoWindow}
          onClick={() => {
            setShowInfoWindow(false);
          }}
          infoWindowModeVersion={props.infoWindowModeVersion}
        />
        }
      </div>
    </React.Fragment>
  );
};
Marker.defaultProps = {
  onClick: null,
  useGlobalDispatch: true,
  useInfoWindow: true,
  shouldChangePosition:f=>f
};
Marker.propTypes = {
  onClick: PropTypes.func,
  model: PropTypes.object.isRequired,
  className: PropTypes.object,
  //infoWindowModeVersion == singleObject
  infoWindowModeVersion: PropTypes.string,
  useGlobalDispatch: PropTypes.bool,
  useInfoWindow: PropTypes.bool,
  shouldChangePosition: PropTypes.func,
};
export default Marker;
