import React, { useEffect, useState } from "react";
import InterfacesIcons from "../../vector/InterfacesIcons";
import APP_CONFIG from "../../../config";
import Languages from "./Languages";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useFavoriteStorage from "../../../hooks/useFavoriteStorage";
import clsx from "clsx";

const AppHeaderActions = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { value: favoritesObjects } = useFavoriteStorage("favorites", []);
  const [favoriteItems, setFavoriteItems] = useState(favoritesObjects);
  const [hasFavorites, setHasFavorites] = useState(false);

  const favoritesDataStore = useSelector((state) => state.favorites.data);

  useEffect(() => {
    if (isInitialized) {
      setFavoriteItems(favoritesDataStore);
    } else {
      setIsInitialized(true);
    }
  }, [favoritesDataStore]);
  useEffect(() => {
    if (favoriteItems.length > 0) {
      setHasFavorites(true);
    } else {
      setHasFavorites(false);
    }
  }, [favoriteItems]);

  return (
    <div className="app-header--actions">
      <ul className="app-header--actions_list">
        <li
          className={`app-header--actions_item app-header--actions_languages`}
        >
          <Languages />
        </li>
        <li
          className={`app-header--actions_item app-header--actions_item_phone_call`}
        >
          <a href={APP_CONFIG.phone.hrefPhone}>
            {" "}
            <button>
              <InterfacesIcons name={"phone_call"} />
            </button>
          </a>
        </li>

        <li
          className={clsx(
            `app-header--actions_item app-header--actions_item_like`,
            { active: hasFavorites },
          )}
        >
          <Link to={`/favorites`}>
            {" "}
            <button>
              <InterfacesIcons name={"like"} />
            </button>
          </Link>
        </li>

        <li
          className={`app-header--actions_item app-header--actions_item_search opacity-0`}
        >
          <button>
            <InterfacesIcons name={"search"} />
          </button>
        </li>
      </ul>
    </div>
  );
};
export default AppHeaderActions;
