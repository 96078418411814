import React, { Fragment, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
// import SliderFilter from "/src/components/ui/sliders/SliderFilter";
import { renderPrice } from "/src/utils/filterUtils";
import { useDispatch, useSelector } from "react-redux";
import { setPrice } from "/src/store/reducers/selectedFilterData";
import useInputDebounce from "/src/hooks/useInputDebounce";
import { useTranslation } from "react-i18next";
import SliderFilter from "../../../../ui/sliders/SliderFilter";

const PriceField = (props) => {
  const [valueMin, setValueMin] = useState(0);
  const [valueMax, setValueMax] = useState(0);
  const { t, i18n } = useTranslation();
  const debounced = useInputDebounce([valueMin, valueMax].join("-"), 800); //todo complete debounce
  // const selectedFilterData = useSelector((state) => state.selectedFilterData.value)
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.values) {
      setValueMin(props.values.min);
      setValueMax(props.values.max);
    }
  }, [props.values]);
  useEffect(() => {
    if (props.hasReset) {
      setValueMin(props.values.min);
      setValueMax(props.values.max);
    }
  }, [props.hasReset]);

  useEffect(() => {
    if (debounced !== "0-0") {
      dispatch(setPrice(debounced));
    }
  }, [debounced]);
  //todo complete debounce
  // useEffect(() => {
  //     props.onChange([priceMin, priceMax])
  // }, [debouncedSearchMin, debouncedSearchMax]);

  const handleChange = (event, newValue) => {
    setValueMin(newValue[0]);
    setValueMax(newValue[1]);
    // debounced

    // dispatch(setPrice(newValue.join('-')))
  };

  if (!props.values) {
    return null;
  }

  return (
    <Fragment>
      <div className="filter-fields-group__cell price__cell">
        <div className="filter-fields-group__cell-label">{props.title}</div>
        <div className="price-row">
          <div className="input-cell">
            <TextField
              disabled
              id="min-price"
              label={t("Мін. ціна")}
              // defaultValue="25 000 $"
              // value={valueMin}
              value={renderPrice(valueMin)}
            />
          </div>

          <div className="separator">
            <svg
              width="8"
              height="2"
              viewBox="0 0 8 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="8" height="2" fill="#7E7E7E" />
            </svg>
          </div>

          <div className="input-cell">
            <TextField
              disabled
              id="max-price"
              label={t("Макс. ціна")}
              // defaultValue={value[1]}
              value={renderPrice(valueMax)}
            />
          </div>
        </div>

        <SliderFilter
          min={valueMin}
          max={valueMax}
          callbackChange={handleChange}
          hasReset={props.hasReset}
        />
      </div>
    </Fragment>
  );
};
// values {min:10,max:45}
PriceField.propTypes = {
  values: PropTypes.object.isRequired,
  title: PropTypes.string,
  hasReset: PropTypes.number.isRequired,
};

export default PriceField;
