import { useTranslation } from "react-i18next";
import DialogBooking from "../Dialog/DialogBooking";
import {
  SellSafetyIcon,
  SellHourglassesIcon,
  SellCoinIcon,
  SellPersonIcon,
} from "../vector/SellPageIcons";
import React from "react";

const BenefitsList = [
  {
    title: "Швидкий продаж",
    text: "Ми гарантуємо швидкий продаж вашої нерухомості завдяки нашій великій базі покупців та ефективній маркетинговій стратегії",
    icon: SellHourglassesIcon,
  },
  {
    title: "Юридична безпека",
    text: "Ми забезпечимо повний юридичний супровід угоди, захищаючи ваші інтереси на кожному етапі",
    icon: SellSafetyIcon,
  },
  {
    title: "Висока ціна",
    text: "Ми допоможемо вам отримати максимальну вартість за вашу нерухомість, використовуючи точну оцінку ринку та переговорні навички",
    icon: SellCoinIcon,
  },
  {
    title: "Персональний менеджер",
    text: "За вами буде закріплено персонального менеджера, котрий буде курирувати весь процес продажу і відповідати на всі ваші запитання",
    icon: SellPersonIcon,
  },
];

const OurBenefits = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="sell-page--why-we">
      <div className="container">
        <div className="title-why-we">
          <h2>{t("Чому вибирають нас")}</h2>
          <h3>{t("Гарантії та переваги роботи з нашим агенством")}</h3>
        </div>


        <div className="why-we--row">
          {BenefitsList.map((obj, i) => {
            const Icon = obj.icon;
            return (
              <div key={i} className="why-we--cell">
                <div className="centered-content">
                  <Icon />
                  <h4 className="why-we--cell_title">{t(obj.title)}</h4>
                  <p>{t(obj.text)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OurBenefits;
