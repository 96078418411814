import React, { useState, useEffect, useRef, Fragment } from "react";
import AppHeaderMenuItem from "./AppHeaderMenuItem";

const AppHeaderLinks = [
  {
    title: "Придбати",
    route: "/",
    sub_menu: [],
  },
  {
    title: "Продати",
    route: "/sell",
  },
  // {
  //   title: "Оцінити",
  //   route: "/",
  // },
  {
    title: "Про компанію",
    route: "/about-us",
  },
  // {
  //   title: "Новини",
  //   route: "/",
  // },
  {
    title: "Контакти",
    route: "/contacts",
  },
];

const AppHeaderMenu = () => {
  return (
    <Fragment>
      <nav className="app-header--navigation">
        {AppHeaderLinks && (
          <ul className="app-header--navigation-items">
            {AppHeaderLinks.map((obj, index) => {
              return <AppHeaderMenuItem item={obj} key={index} index={index} />;
            })}
          </ul>
        )}
      </nav>
    </Fragment>
  );
};
export default AppHeaderMenu;
