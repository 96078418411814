import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import useInputDebounce from "../../hooks/useInputDebounce";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";
import {FilterCatalogSearchBuIdIcon} from "../vector/FIlterIcons";
import {IconButton} from "@mui/material";


const SearchByIdInput = ({type}) => {
  const { t, i18n } = useTranslation();
    const search = useSearchMove(type);
  const [valueInput, setValueInput] = useState("");
  const debounced= useInputDebounce(valueInput, 1200);
  // const search = useSearchMove('catalog');
  useEffect(()=>{

          if (debounced) {
              let overriddenData = { id: debounced };
              search.moveToFilterOverridden(overriddenData);
          } else {
                  let overriddenData = {  };
                  search.moveToFilterOverridden(overriddenData);
          }
  },[debounced])
  const handlerChange = (e) => {

    setValueInput(e.target.value);
  };

  //todo add icon rm stop here
  return (
    <div className="filter-fields-group__cell-input search-from-id">
      <div className="icon-search">


          <IconButton type="button" sx={{ p: '10px' }} onClick={handlerChange.bind(null,{target:{value:''}})}>
              <FilterCatalogSearchBuIdIcon />
          </IconButton>

      </div>

      <TextField
        value={valueInput}
        onChange={handlerChange}
        id="search-from-id"
        label={t("Пошук за ID")}
        variant="filled"
      />
    </div>
  );
};

SearchByIdInput.propTypes = {};

export default SearchByIdInput;
