import React from "react";
import PropTypes from "prop-types";

const RatingLabel = ({ rating = 5.0 }) => {
  const content = [];

  return (
    <div className={"rating-label-wrap"}>
      <button className={"rating-label--button"}>
        <span className={"rating-label--star"}>
          {" "}
          <svg
            width="19"
            height="17"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.22402 0.694674C8.53664 -0.197921 9.79897 -0.197919 10.1116 0.694676L11.5955 4.93137C11.7359 5.33235 12.1144 5.60081 12.5392 5.60081H17.1719C18.1587 5.60081 18.5491 6.87851 17.7308 7.43004L14.1152 9.86701C13.7382 10.1211 13.58 10.5977 13.7303 11.0268L15.1412 15.0552C15.4593 15.9635 14.4366 16.7528 13.6385 16.215L9.72671 13.5783C9.38889 13.3506 8.94671 13.3506 8.60889 13.5783L4.69707 16.215C3.89905 16.7528 2.87626 15.9635 3.19437 15.0552L4.60528 11.0268C4.75558 10.5977 4.59745 10.1211 4.22041 9.86701L0.60481 7.43004C-0.213463 6.87851 0.17693 5.60081 1.16372 5.60081H5.79636C6.22123 5.60081 6.59971 5.33235 6.74015 4.93137L8.22402 0.694674Z"
              fill="white"
            />
          </svg>
        </span>
        {rating}
      </button>
    </div>
  );
};

export default RatingLabel;
