import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  setBathRoomVariants,
  setCity,
  setDistrict,
  setFloorVariants,
  setHeating,
  setLandPlotsVariants,
  setPopulars,
  setRepair,
  setResetSelectedFilter,
  setTerraceVariants,
  setViewVariants,
  setWalls,
  setWindowVariants,
} from "../../../../store/reducers/selectedFilterData";
import useSearchMove from "../../../../hooks/useSearchMove";
import SelectAutocomplete from "../../../ui/selects/SelectAutocomplete";
import PriceField from "./Fields/PriceField";
import SearchButton from "../../../ui/buttons/SearchButton";
import FilterButtonGroup from "../../../Filter/FilterButtonGroup";
import NumberOfRooms from "./Fields/NumberOfRooms";
import { FilterButtonExtendSearchIcon } from "../../../vector/FIlterIcons";

const PlotsFilterBlock = () => {
  const search = useSearchMove("plots");
  const cities = useSelector((state) => state.filter.value.cities);
  const districts = useSelector((state) => state.filter.value.districts);
  const prices = useSelector((state) => state.filter.value.prices);
  const landPlotsVariants = useSelector(
    (state) => state.filter.value.landPlotsVariants,
  );
  // const floorVariants = useSelector(
  //   (state) => state.filter.value.floorVariants,
  // );
  // const terraceVariants = useSelector(
  //   (state) => state.filter.value.terraceVariants,
  // );
  // const windowVariants = useSelector(
  //   (state) => state.filter.value.windowVariants,
  // );
  // const viewVariants = useSelector((state) => state.filter.value.viewVariants);
  const previousTotal = useSelector((state) => state.previousResults.total);

  //extend filter
  const populars = useSelector((state) => state.filter.value.populars);
  // const heating = useSelector((state) => state.filter.value.heating);
  // const bathRoomVariants = useSelector(
  //   (state) => state.filter.value.bathRoomVariants,
  // );
  // const walls = useSelector((state) => state.filter.value.walls);
  // const repair = useSelector((state) => state.filter.value.repair);
  //extend filter

  const [defaultCitiId] = useState(33); // odessa todo change it
  const [defaultDistrictId] = useState(8); // odessa todo change it
  const [defaultPlotId] = useState(-1); // odessa todo change it

  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [renderKey, setRenderKey] = useState(Math.random());
  const [hasReset, setHasReset] = useState(0);

  useEffect(() => {
    dispatch(setResetSelectedFilter({}));
  }, []);
  useEffect(() => {
    //fix update filted

    setRenderKey(Math.random());
  }, [cities, districts]);

  // todo add loader
  if (cities.length === 0) {
    return null;
  }
  return (
    <Fragment>
      <div className="filter-fields-group">
        <SelectAutocomplete
          options={cities}
          key={renderKey}
          title={t("Виберіть місто")}
          defaultValueId={defaultCitiId}
          onChange={(e, value, reason) => {
            dispatch(setCity(value.id));
          }}
          hasReset={hasReset}
        />

        <SelectAutocomplete
          key={renderKey + 1}
          options={districts}
          title={t("Виберіть район")}
          defaultValueId={defaultDistrictId}
          onChange={(e, value, reason) => {
            dispatch(setDistrict(value.id));
          }}
          hasReset={hasReset}
        />

        <PriceField title={t("Ціна")} values={prices} hasReset={hasReset} />

        <SelectAutocomplete
          key={renderKey + 2}
          options={landPlotsVariants}
          title={t("Форма ділянки")}
          defaultValueId={defaultPlotId}
          onChange={(e, value, reason) => {
            dispatch(setLandPlotsVariants(value.slug));
          }}
          hasReset={hasReset}
        />
        {/*<SelectAutocomplete*/}
        {/*    options={cities}*/}
        {/*    key={renderKey}*/}
        {/*    title={t("Виберіть місто")}*/}
        {/*    defaultValueId={defaultCitiId}*/}
        {/*    onChange={(e, value, reason) => {*/}
        {/*      dispatch(setCity(value.id));*/}
        {/*    }}*/}
        {/*    hasReset={hasReset}*/}
        {/*/>*/}

        {/*
        {floorVariants && floorVariants.length && (
          <div className="filter-fields-group__cell">
            <div className="filter-fields-group__cell-label">
              {t("Кількість поверхів")}
            </div>
            <NumberOfRooms
              options={floorVariants}
              selected={[]}
              callbackChange={(f) => f}
              dispatchFn={setFloorVariants}
              hasReset={hasReset}
            />
          </div>
        )}*/}

        <div className="filter-fields-group__cell">
          <div className="filter-fields-group__cell-label">
            <br />
          </div>
          <div className="form-actions">
            <SearchButton onClick={search.moveToFilter} />
            <button
              onClick={setShowAdvancedFilter.bind(null, !showAdvancedFilter)}
              className={
                showAdvancedFilter
                  ? "advanced-search show-filter"
                  : "advanced-search"
              }
            >
              <FilterButtonExtendSearchIcon />
              {t("Розширений пошук")}
            </button>
          </div>
        </div>
      </div>

      {showAdvancedFilter && (
        <div className="filter-advanced-fields">
          <div className="filter-advanced-fields__row">
            <FilterButtonGroup
              options={populars}
              title={t("Популярні запити")}
              callbackChange={(data) => {
                const selectedSlugs = data.map((e) => e.slug).join(",");
                dispatch(setPopulars(selectedSlugs));
              }}
              selected={[]}
              hasReset={hasReset}
            />

            <div className="fields__cell">
              {/*<div className="filter-advanced-fields__row">*/}
              {/*  <FilterButtonGroup*/}
              {/*    options={terraceVariants}*/}
              {/*    title={t("Тераса")}*/}
              {/*    callbackChange={(data) => {*/}
              {/*      const selectedSlugs = data.map((e) => e.slug).join(",");*/}
              {/*      dispatch(setTerraceVariants(selectedSlugs));*/}
              {/*    }}*/}
              {/*    selected={[]}*/}
              {/*    hasReset={hasReset}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>

          <div className="filter-advanced-fields__row">
            {/*<FilterButtonGroup*/}
            {/*  options={repair}*/}
            {/*  title={t("Ремонт")}*/}
            {/*  callbackChange={(data) => {*/}
            {/*    const selectedSlugs = data.map((e) => e.slug).join(",");*/}
            {/*    dispatch(setRepair(selectedSlugs));*/}
            {/*  }}*/}
            {/*  selected={[]}*/}
            {/*  hasReset={hasReset}*/}
            {/*/>*/}

            {/*<div className="fields__cell">*/}
            {/*  <div className="filter-advanced-fields__row">*/}
            {/*    <FilterButtonGroup*/}
            {/*      options={walls}*/}
            {/*      title={t("Стіни")}*/}
            {/*      callbackChange={(data) => {*/}
            {/*        const selectedSlugs = data.map((e) => e.slug).join(",");*/}
            {/*        dispatch(setWalls(selectedSlugs));*/}
            {/*      }}*/}
            {/*      selected={[]}*/}
            {/*      hasReset={hasReset}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <div className="filter-advanced-fields__row">
            {/*<FilterButtonGroup*/}
            {/*  options={heating}*/}
            {/*  title={t("Опалення")}*/}
            {/*  callbackChange={(data) => {*/}
            {/*    const selectedSlugs = data.map((e) => e.slug).join(",");*/}
            {/*    dispatch(setHeating(selectedSlugs));*/}
            {/*  }}*/}
            {/*  selected={[]}*/}
            {/*  hasReset={hasReset}*/}
            {/*/>*/}

            {/*<div className="fields__cell">*/}
            {/*  <div className="filter-advanced-fields__row">*/}
            {/*    <FilterButtonGroup*/}
            {/*      options={bathRoomVariants}*/}
            {/*      title={t("Тип санвузла")}*/}
            {/*      callbackChange={(data) => {*/}
            {/*        const selectedSlugs = data.map((e) => e.slug).join(",");*/}
            {/*        dispatch(setBathRoomVariants(selectedSlugs));*/}
            {/*      }}*/}
            {/*      selected={[]}*/}
            {/*      hasReset={hasReset}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <div className="filter-advanced-fields__row">
            {/*<FilterButtonGroup*/}
            {/*  options={windowVariants}*/}
            {/*  title={t("Тип вікон")}*/}
            {/*  callbackChange={(data) => {*/}
            {/*    const selectedSlugs = data.map((e) => e.slug).join(",");*/}
            {/*    dispatch(setWindowVariants(selectedSlugs));*/}
            {/*  }}*/}
            {/*  selected={[]}*/}
            {/*  hasReset={hasReset}*/}
            {/*/>*/}

            {/*<div className="fields__cell">*/}
            {/*  <div className="filter-advanced-fields__row">*/}
            {/*    <FilterButtonGroup*/}
            {/*      options={viewVariants}*/}
            {/*      title={t("Вид")}*/}
            {/*      callbackChange={(data) => {*/}
            {/*        const selectedSlugs = data.map((e) => e.slug).join(",");*/}
            {/*        dispatch(setViewVariants(selectedSlugs));*/}
            {/*      }}*/}
            {/*      selected={[]}*/}
            {/*      hasReset={hasReset}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <div className="filter-advanced-fields__row">
            <div className="fields__cell">
              <div className="filter-advanced-fields__row flex-end">
                <div className="fields__cell">
                  <button
                    className="clear-filter"
                    onClick={(e) => {
                      //todo it's ???
                      dispatch(setResetSelectedFilter({}));
                      setHasReset(Math.random());
                      // dispatch(setResetFilter({}))
                    }}
                  >
                    {t("Очистити фільтри")}
                  </button>
                </div>

                <div className="fields__cell">
                  <button
                    className="show-results"
                    onClick={search.moveToFilter}
                  >
                    {t("Показати")} {previousTotal} {t("пропозиції")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default PlotsFilterBlock;
