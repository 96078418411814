import React from "react";
import { apiGet } from "/src/utils/api";
export const realtorContainer = (Component) => {
  class wrapRoomContainer extends React.PureComponent {
    constructor(props) {
      super(props);
      this.loadData = this.loadData.bind(this);
    }

    // server
    loadData(slug, callback) {
      return apiGet("responsible/" + slug)
        .then((data) => data.data)
        .then((response) => {
          return response.data;
        });
    }
    render() {
      return <Component {...this.props} loadData={this.loadData} />;
    }
  }
  return wrapRoomContainer;
};
