import React from 'react';
const RealtorObjectSocial = ({data}) => {
    return  null;

    return (
        <div className="realtor-networks  zsw">
            <div className="realtor-networks--item">
                <a href=" ">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15Z"
                            fill="#9179EE"
                        />
                        <path
                            d="M14.9996 22.8129C14.4302 22.8129 13.8964 22.7931 13.3963 22.7533L11.0053 25.5723C10.8237 25.7864 10.4732 25.6583 10.4732 25.3778V22.1609C7.65794 21.1038 6.70117 18.7397 6.70117 14.9105C6.70117 9.4931 8.61618 7.0081 14.9996 7.0081C21.3829 7.0081 23.2979 9.4931 23.2979 14.9105C23.2979 20.3279 21.3829 22.8129 14.9996 22.8129Z"
                            fill="#9179EE"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M24.6813 14.8766C24.6813 8.53427 22.4471 5.625 14.9998 5.625C7.55254 5.625 5.31836 8.53427 5.31836 14.8766C5.31836 19.3903 6.44991 22.1652 9.78672 23.3901V25.5255C9.78672 26.3115 10.773 26.6706 11.2838 26.0705L13.0057 24.0482C13.6246 24.1017 14.2884 24.1283 14.9998 24.1283C22.4471 24.1283 24.6813 21.219 24.6813 14.8766ZM13.4171 22.5894C13.9107 22.6286 14.4377 22.648 14.9998 22.648C21.3013 22.648 23.1918 20.2042 23.1918 14.8766C23.1918 9.54905 21.3013 7.10526 14.9998 7.10526C8.69827 7.10526 6.80781 9.54905 6.80781 14.8766C6.80781 18.6423 7.75231 20.9673 10.5314 22.0068V25.1704C10.5314 25.4462 10.8775 25.5722 11.0567 25.3616L13.4171 22.5894Z"
                            fill="white"
                        />
                        <path
                            d="M18.1243 19.6469C18.1248 19.6469 18.125 19.6468 18.125 19.6468C18.1251 19.646 18.0871 19.6419 17.9932 19.6208C17.8916 19.598 17.7523 19.5618 17.5804 19.51C17.237 19.4067 16.7742 19.245 16.2388 19.0147C15.1665 18.5534 13.8179 17.8234 12.5565 16.7497C11.7688 16.0792 11.0208 15.1473 10.4356 14.2698C9.84329 13.3816 9.45615 12.607 9.35467 12.2649L9.34371 12.228L9.32723 12.1931C9.30914 12.1549 9.277 12.0554 9.27638 11.9418C9.27579 11.8349 9.30226 11.7461 9.36133 11.6753C9.6447 11.3357 9.93566 11.0118 10.5865 10.697C10.63 10.6759 10.7069 10.6544 10.7814 10.6532C10.8278 10.6525 10.8497 10.6595 10.8559 10.6618C11.1105 10.8858 11.5016 11.2772 11.7634 11.6088C11.9019 11.7842 12.0698 12.0077 12.2097 12.2019C12.2796 12.2991 12.3408 12.3863 12.3868 12.455C12.4184 12.502 12.436 12.5304 12.4443 12.5439C12.4452 12.5481 12.4462 12.5532 12.4471 12.5593C12.4513 12.587 12.4535 12.6221 12.4505 12.6584C12.4444 12.7342 12.4213 12.7735 12.399 12.7939C12.2085 12.9683 11.9451 13.1334 11.8434 13.1897L11.8028 13.2122L11.767 13.2418C11.5407 13.429 11.2085 13.9349 11.4987 14.5553C11.7103 15.0074 12.1881 15.9464 13.0342 16.6571C13.8541 17.3458 14.7327 17.7196 15.3205 17.8436L15.3715 17.8543H15.4101L15.4277 17.8571L15.4585 17.8624C15.4635 17.8633 15.469 17.8643 15.4747 17.8653C15.6511 17.8967 16.1374 17.9834 16.5425 17.5798C16.7618 17.3612 16.9623 17.1341 17.0567 17.0225C17.0618 17.0185 17.0702 17.0125 17.0819 17.0053C17.1047 16.9913 17.1342 16.977 17.1684 16.9663C17.2334 16.946 17.3152 16.9382 17.4144 16.9717C17.8731 17.1268 18.429 17.482 18.7059 17.6684C18.9166 17.8102 19.396 18.1536 19.6157 18.3122C19.6164 18.3129 19.6211 18.3171 19.6277 18.3253C19.6366 18.3366 19.6437 18.3487 19.648 18.3598C19.6519 18.3699 19.6534 18.3785 19.6532 18.3872C19.653 18.3951 19.6514 18.4142 19.6362 18.4467C19.566 18.5969 19.3325 18.9153 19.0053 19.1991C18.6727 19.4875 18.3538 19.6436 18.1243 19.6469ZM18.1243 19.6469C18.1232 19.647 18.1207 19.6469 18.117 19.6469L18.1243 19.6469Z"
                            fill="white"
                            stroke="white"
                            strokeLinecap="round"
                        />
                    </svg>
                </a>
            </div>

            <div className="realtor-networks--item">
                <a href=" ">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15Z"
                            fill="#12A2EB"
                        />
                        <path
                            d="M7.59367 14.7265C11.6202 12.9722 14.3052 11.8157 15.6486 11.2569C19.4844 9.66148 20.2814 9.38434 20.8009 9.37509C20.9152 9.37317 21.1707 9.40149 21.3362 9.53577C21.4759 9.64916 21.5143 9.80232 21.5327 9.90982C21.5511 10.0173 21.574 10.2622 21.5558 10.4536C21.348 12.6376 20.4486 17.9376 19.991 20.3838C19.7974 21.4189 19.4161 21.7659 19.047 21.7999C18.2449 21.8737 17.6359 21.2698 16.859 20.7605C15.6434 19.9637 14.9566 19.4676 13.7766 18.69C12.4129 17.7914 13.2969 17.2974 14.0741 16.4902C14.2775 16.279 17.8115 13.0645 17.8799 12.7729C17.8885 12.7364 17.8964 12.6005 17.8157 12.5287C17.7349 12.4569 17.6157 12.4815 17.5297 12.501C17.4078 12.5287 15.4658 13.8123 11.7036 16.3518C11.1524 16.7303 10.6531 16.9147 10.2058 16.9051C9.71261 16.8944 8.76396 16.6262 8.05873 16.397C7.19374 16.1158 6.50627 15.9671 6.56613 15.4896C6.59731 15.2409 6.93983 14.9865 7.59367 14.7265Z"
                            fill="white"
                        />
                    </svg>
                </a>
            </div>

            <div className="realtor-networks--item">
                <a href=" ">
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15Z"
                            fill="#25D366"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.25 9.65625C18.8438 8.25 16.9688 7.5 15 7.5C10.875 7.5 7.5 10.875 7.5 15C7.5 16.3125 7.87501 17.625 8.53126 18.75L7.5 22.5L11.4375 21.4688C12.5625 22.0312 13.7812 22.4062 15 22.4062C19.125 22.4062 22.5 19.0312 22.5 14.9062C22.5 12.9375 21.6563 11.0625 20.25 9.65625ZM15 21.1875C13.875 21.1875 12.75 20.9063 11.8125 20.3438L11.625 20.25L9.28124 20.9062L9.93751 18.6562L9.74999 18.375C9.09374 17.3438 8.81249 16.2188 8.81249 15.0938C8.81249 11.7188 11.625 8.90625 15 8.90625C16.6875 8.90625 18.1875 9.5625 19.4063 10.6875C20.625 11.9062 21.1875 13.4063 21.1875 15.0938C21.1875 18.375 18.4688 21.1875 15 21.1875ZM18.375 16.5C18.1875 16.4062 17.25 15.9375 17.0625 15.9375C16.875 15.8438 16.7812 15.8438 16.6875 16.0312C16.5937 16.2188 16.2188 16.5938 16.125 16.7812C16.0313 16.875 15.9375 16.875 15.75 16.875C15.5625 16.7812 15 16.5938 14.25 15.9375C13.6875 15.4688 13.3125 14.8125 13.2188 14.625C13.125 14.4375 13.2188 14.3438 13.3125 14.25C13.4063 14.1562 13.5 14.0625 13.5938 13.9687C13.6875 13.875 13.6875 13.7813 13.7813 13.6875C13.875 13.5938 13.7813 13.5 13.7813 13.4062C13.7813 13.3125 13.4063 12.375 13.2188 12C13.125 11.7188 12.9375 11.7188 12.8438 11.7188C12.75 11.7188 12.6562 11.7188 12.4687 11.7188C12.375 11.7188 12.1875 11.7188 12 11.9062C11.8125 12.0938 11.3438 12.5625 11.3438 13.5C11.3438 14.4375 12 15.2812 12.0937 15.4688C12.1875 15.5625 13.4062 17.5312 15.2812 18.2812C16.875 18.9375 17.1563 18.75 17.5313 18.75C17.9063 18.75 18.6562 18.2812 18.75 17.9062C18.9375 17.4375 18.9375 17.0625 18.8438 17.0625C18.75 16.5938 18.5625 16.5938 18.375 16.5Z"
                            fill="white"
                        />
                    </svg>
                </a>
            </div>
        </div>
    );
};

export default RealtorObjectSocial;