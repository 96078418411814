export function getDefaultById(defaultId, data) {
  const index = data.findIndex((element) => {
    if(!element) {
      return null
    }
    return element.id === defaultId;
  });
  if (index > -1) {
    return data[index];
  }
  return null;
}
export function getDefaultBySlug(slug, data) {
  const index = data.findIndex((element) => {
    return element.slug === slug;
  });
  if (index > -1) {
    return data[index];
  }
  return null;
}
export const renderPrice = (price) => {
  return new Intl.NumberFormat("ua-UA", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(price);
};

export const getQueryString = (query) => {
  let pathname = "";
  if (query) {
    if (typeof query === "string") {
      // pathname += `?${query.replace(/^\?/, '')}`;
      pathname += `${query.replace(/^\?/, "")}`;
    } else if (typeof query === "object") {
      const temp = [];
      for (let [key, value] of Object.entries(query)) {
        temp.push(`${key}=${value}`);
      }
      // pathname += `?${temp.join('&')}`
      pathname += `${temp.join("&")}`;
    } else {
      // TODO: ...
    }
  }
  return pathname;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};
