import React, { useState, useEffect, useRef } from "react";
import InProcess from "../../media/images.jpeg";
import AddToFavorites from "../ui/AddToFavorites";
import Slider from "react-slick";
import DevelopmentBlock from "../DevelopmentBlock/DevelopmentBlock";
import { useTranslation } from "react-i18next";
import { putBestProposalsObjects } from "../../store/reducers/bestProposals";

const ApartmentsNew = ({ mobile = false, settings = {} }) => {
  // const { t, i18n } = useTranslation();
  // useEffect(() => {
  // }, [i18n.language]);
  return <DevelopmentBlock />;

  return (
    <div className="home-page--best-deals-tab">
      {!mobile ? (
        <div className="home-page--best-deals-list">
          <div className="home-page--best-deals-list--item">
            <div className="home-page--best-deals-list--item_thumbnail">
              <img src={InProcess} alt="deals title" />
            </div>

            <div className="home-page--best-deals-list--item_details">
              <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
            </div>
          </div>

          <div className="home-page--best-deals-list--item">
            <div className="home-page--best-deals-list--item_thumbnail">
              <img src={InProcess} alt="deals title" />
            </div>

            <div className="home-page--best-deals-list--item_details">
              <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
            </div>
          </div>

          <div className="home-page--best-deals-list--item">
            <div className="home-page--best-deals-list--item_thumbnail">
              <img src={InProcess} alt="deals title" />
            </div>

            <div className="home-page--best-deals-list--item_details">
              <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
            </div>
          </div>

          <div className="home-page--best-deals-list--item">
            <div className="home-page--best-deals-list--item_thumbnail">
              <img src={InProcess} alt="deals title" />
            </div>

            <div className="home-page--best-deals-list--item_details">
              <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
            </div>
          </div>
        </div>
      ) : (
        <div className="home-page--best-deals-mobile-list">
          <Slider {...settings}>
            <div className="home-page--best-deals-mobile-list--item">
              <div className="home-page--best-deals-list--item">
                <div className="home-page--best-deals-list--item_thumbnail">
                  <img src={InProcess} alt="deals title" />
                </div>

                <div className="home-page--best-deals-list--item_details">
                  <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
                </div>
              </div>
            </div>

            <div className="home-page--best-deals-mobile-list--item">
              <div className="home-page--best-deals-list--item">
                <div className="home-page--best-deals-list--item_thumbnail">
                  <img src={InProcess} alt="deals title" />
                </div>

                <div className="home-page--best-deals-list--item_details">
                  <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
                </div>
              </div>
            </div>

            <div className="home-page--best-deals-mobile-list--item">
              <div className="home-page--best-deals-list--item">
                <div className="home-page--best-deals-list--item_thumbnail">
                  <img src={InProcess} alt="deals title" />
                </div>

                <div className="home-page--best-deals-list--item_details">
                  <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
                </div>
              </div>
            </div>

            <div className="home-page--best-deals-mobile-list--item">
              <div className="home-page--best-deals-list--item">
                <div className="home-page--best-deals-list--item_thumbnail">
                  <img src={InProcess} alt="deals title" />
                </div>

                <div className="home-page--best-deals-list--item_details">
                  <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
                </div>
              </div>
            </div>

            <div className="home-page--best-deals-mobile-list--item">
              <div className="home-page--best-deals-list--item">
                <div className="home-page--best-deals-list--item_thumbnail">
                  <img src={InProcess} alt="deals title" />
                </div>

                <div className="home-page--best-deals-list--item_details">
                  <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
                </div>
              </div>
            </div>

            <div className="home-page--best-deals-mobile-list--item">
              <div className="home-page--best-deals-list--item">
                <div className="home-page--best-deals-list--item_thumbnail">
                  <img src={InProcess} alt="deals title" />
                </div>

                <div className="home-page--best-deals-list--item_details">
                  <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
                </div>
              </div>
            </div>

            <div className="home-page--best-deals-mobile-list--item">
              <div className="home-page--best-deals-list--item">
                <div className="home-page--best-deals-list--item_thumbnail">
                  <img src={InProcess} alt="deals title" />
                </div>

                <div className="home-page--best-deals-list--item_details">
                  <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
                </div>
              </div>
            </div>

            <div className="home-page--best-deals-mobile-list--item">
              <div className="home-page--best-deals-list--item">
                <div className="home-page--best-deals-list--item_thumbnail">
                  <img src={InProcess} alt="deals title" />
                </div>

                <div className="home-page--best-deals-list--item_details">
                  <h3>РАЗДЕЛ В РАЗРАБОТКЕ</h3>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      )}
    </div>
  );
};
export default ApartmentsNew;
