import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GoogleMap from "/src/libs/google-map-react/src";
import APP_CONFIG from "/src/config";
import Marker from "/src/libs/google-map-react/src/components/Marker";

const GoogleMapBlankItem = ({ showMarker = false }) => {
    const defaultMarker = {
        lat: 46.4825,
        lng: 30.7233,
        title: "Odesa",
        region_name: "Odesa Region",
        area_name: "Odesa District",
        id: 1,
        type: "city",
        slug: "odesa",
    };

    return (
        <React.Fragment>
            <GoogleMap
                defaultZoom={12}
                defaultCenter={[defaultMarker.lat, defaultMarker.lng]}
                bootstrapURLKeys={{ key: APP_CONFIG.GOOGLE_MAP_KEY }}
                onDragEnd={(f) => f}
                onClick={(f) => f}
            >
                {showMarker && (
                    <Marker
                        text={defaultMarker.title}
                        model={defaultMarker}
                        lat={defaultMarker.lat}
                        lng={defaultMarker.lng}
                        infoWindowModeVersion={"singleObject"}
                        useGlobalDispatch={false}
                    />
                )}
            </GoogleMap>
        </React.Fragment>
    );
};

GoogleMapBlankItem.defaultProps = {
    isDev: false,
    options: [],
    onDragEnd: (f) => f,
    showMarker: false,
};

GoogleMapBlankItem.propTypes = {
    isDev: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onDragEnd: PropTypes.func,
    showMarker: PropTypes.bool,
};

export default GoogleMapBlankItem;