import React from "react";
import { apiGet } from "../utils/api";
export const filterContainer = (Component) => {
  class wrapFilterContainer extends React.PureComponent {
    constructor(props) {
      super(props);
      this.loadFilterData = this.loadFilterData.bind(this);
    }

    // server
    loadFilterData(search = "", callback) {
      return apiGet("filter" + search)
        .then((data) => data.data)
        .then((response) => {
          return response.data;
        });
    }
    loadPlaces(search) {
      return apiGet("search" + search).then((data) => data.data);
      // return  apiGet('search',+search).then(data=>data.data);
    }
    render() {
      return (
        <Component
          {...this.props}
          loadFilterData={this.loadFilterData}
          loadPlaces={this.loadPlaces}
        />
      );
    }
  }
  return wrapFilterContainer;
};
