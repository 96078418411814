import { createSlice } from "@reduxjs/toolkit";

export const activeItemSlice = createSlice({
  name: "previousResults",
  initialState: {
    total: 0,
  },
  reducers: {
    changeTotal: (state, action) => {
      state.total = action.payload;
    },
  },
});
export const { changeTotal } = activeItemSlice.actions;
export default activeItemSlice.reducer;
