import React from "react";

const InterfacesIcons = ({ name = "" }) => {
  const renderIcon = () => {
    switch (name) {
      case "menu-arrow":
        return (
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.95017 6.59983L0 1.64967L1.65083 0L4.95017 3.3005L8.2495 0L9.90033 1.64967L4.95017 6.59983Z"
              fill="black"
            />
          </svg>
        );

      case "instagram":
        return (
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.11862 1.42888C10.2836 1.42888 10.5542 1.42888 11.4337 1.49383C13.6663 1.55877 14.6812 2.59796 14.7488 4.67632C14.8165 5.52066 14.8165 5.71551 14.8165 7.79387C14.8165 9.87224 14.8165 10.132 14.7488 10.9114C14.6812 12.9898 13.5987 14.029 11.4337 14.0939C10.5542 14.1589 10.3512 14.1589 8.11862 14.1589C5.95365 14.1589 5.68303 14.1589 4.87117 14.0939C2.63855 14.029 1.62372 12.9898 1.55607 10.9114C1.48841 10.0671 1.48841 9.87224 1.48841 7.79387C1.48841 5.71551 1.48841 5.45571 1.55607 4.67632C1.62372 2.59796 2.70621 1.55877 4.87117 1.49383C5.68303 1.42888 5.95365 1.42888 8.11862 1.42888ZM8.11862 0C5.886 0 5.61538 0 4.80352 0.064949C1.82669 0.194847 0.202965 1.75362 0.0676552 4.61138C0 5.39076 0 5.65056 0 7.79387C0 9.93719 0 10.197 0.0676552 10.9764C0.202965 13.8341 1.82669 15.3929 4.80352 15.5228C5.61538 15.5877 5.886 15.5877 8.11862 15.5877C10.3512 15.5877 10.6219 15.5877 11.4337 15.5228C14.4105 15.3929 16.0343 13.8341 16.1696 10.9764C16.2372 10.197 16.2372 9.93719 16.2372 7.79387C16.2372 5.65056 16.2372 5.39076 16.1696 4.61138C16.0343 1.75362 14.4105 0.194847 11.4337 0.064949C10.6219 0 10.3512 0 8.11862 0ZM8.11862 3.76704C5.81834 3.76704 3.924 5.58561 3.924 7.79387C3.924 10.0021 5.81834 11.8207 8.11862 11.8207C10.4189 11.8207 12.3132 10.0021 12.3132 7.79387C12.3132 5.58561 10.4189 3.76704 8.11862 3.76704ZM8.11862 10.3918C6.63021 10.3918 5.41241 9.22275 5.41241 7.79387C5.41241 6.365 6.63021 5.19592 8.11862 5.19592C9.60703 5.19592 10.8248 6.365 10.8248 7.79387C10.8248 9.22275 9.60703 10.3918 8.11862 10.3918ZM12.4485 2.72786C11.9073 2.72786 11.5014 3.11755 11.5014 3.63714C11.5014 4.15673 11.9073 4.54643 12.4485 4.54643C12.9898 4.54643 13.3957 4.15673 13.3957 3.63714C13.3957 3.11755 12.9898 2.72786 12.4485 2.72786Z"
              fill="black"
            />
          </svg>
        );

      case "you_tube":
        return (
          <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.1555 2.61312L15.1555 2.61316L15.1682 2.61414C15.713 2.65605 15.9369 2.75552 16.0138 2.80138L16.0154 2.80229C16.0504 2.82312 16.1063 2.85639 16.1859 3.03679C16.2878 3.26754 16.3952 3.67336 16.4655 4.38519C16.5347 5.08627 16.5581 5.96606 16.5581 7.09308C16.5581 8.21983 16.5347 9.10499 16.4653 9.81271C16.3948 10.5319 16.2869 10.9462 16.1833 11.183C16.1041 11.3641 16.0502 11.3949 16.0271 11.408L16.0239 11.4099C15.962 11.4463 15.753 11.5414 15.2191 11.5688L15.2191 11.5687L15.2106 11.5692C12.6439 11.7118 6.85977 11.7122 4.27435 11.5705C3.74391 11.5277 3.52457 11.4301 3.44855 11.3848L3.44701 11.3839C3.412 11.3631 3.35607 11.3298 3.27644 11.1494C3.17458 10.9186 3.06718 10.5128 2.99691 9.80098C2.92771 9.0999 2.9043 8.22011 2.9043 7.09308C2.9043 5.96606 2.92771 5.08627 2.99691 4.38519C3.06718 3.67336 3.17458 3.26754 3.27644 3.03679C3.35607 2.85639 3.412 2.82312 3.44701 2.80229L3.44855 2.80138C3.52551 2.75552 3.74937 2.65605 4.29422 2.61414L4.29422 2.61418L4.30691 2.61312C6.84445 2.40166 12.6179 2.40166 15.1555 2.61312ZM0.904297 7.09308C0.904297 11.6536 1.27208 13.3455 4.14082 13.5661C6.78889 13.7133 12.6735 13.7133 15.3216 13.5661C18.1903 13.419 18.5581 11.6536 18.5581 7.09308C18.5581 2.53253 18.1903 0.840705 15.3216 0.620033C12.6735 0.399361 6.78889 0.399361 4.14082 0.620033C1.27208 0.840705 0.904297 2.53253 0.904297 7.09308ZM8.97152 9.31181C8.30662 9.64426 7.52431 9.16077 7.52431 8.41739V5.76886C7.52431 5.02548 8.30662 4.54198 8.97152 4.87443L11.62 6.19869C12.3571 6.56722 12.3571 7.61902 11.62 7.98755L8.97152 9.31181Z"
              fill="black"
            />
          </svg>
        );

      case "facebook":
        return (
          <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.40643 5.33333H0.224609V8H2.40643V16H6.04279V8H8.66097L8.95188 5.33333H6.04279V4.2C6.04279 3.6 6.18825 3.33333 6.84279 3.33333H8.95188V0H6.18825C3.57006 0 2.40643 1.06667 2.40643 3.06667V5.33333Z"
              fill="black"
            />
          </svg>
        );

      case "telegram":
        return (
          <svg
            width="19"
            height="16"
            viewBox="0 0 19 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.58149 10.2095L7.28445 14.1876C7.73 14.1876 7.87852 14.0456 8.1013 13.8324L10.1063 12.0565L14.1905 14.898C14.9331 15.3242 15.4529 15.1111 15.6757 14.2587L18.349 2.18234C18.5718 1.11678 17.9777 0.690558 17.2351 0.974707L1.41801 6.72872C0.378383 7.08391 0.378383 7.6522 1.26949 7.93635L5.27946 9.14398L14.7103 3.53205C15.1559 3.2479 15.5272 3.38997 15.2301 3.67412L7.58149 10.2095Z"
              fill="black"
            />
          </svg>
        );

      case "phone_call":
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.49371 4.95952C2.93339 10.6147 7.38532 15.0666 13.0405 16.5063C15.1813 17.0513 17 15.2091 17 13V12C17 11.4477 16.5512 11.0053 16.0016 10.9508C15.073 10.8587 14.1818 10.6397 13.3456 10.3112L11.8263 11.8305C9.3553 10.648 7.35202 8.64471 6.16949 6.17367L7.68877 4.65438C7.36032 3.81816 7.14126 2.92696 7.04922 1.99842C6.99474 1.44883 6.55229 1 6 1H5C2.79086 1 0.948692 2.81867 1.49371 4.95952Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case "like":
        return (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.071 10.1421L11.4141 15.799C10.6331 16.58 9.36672 16.58 8.58568 15.799L2.92882 10.1421C0.9762 8.18951 0.9762 5.02369 2.92882 3.07106C4.88144 1.11844 8.04727 1.11844 9.99989 3.07106C11.9525 1.11844 15.1183 1.11844 17.071 3.07106C19.0236 5.02369 19.0236 8.18951 17.071 10.1421Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );

      case "search":
        return (
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.3395 18.4859L14.4682 14.4712C15.7188 13.002 16.4777 11.0721 16.4777 8.95833C16.4777 4.35596 12.88 0.625 8.44196 0.625C4.00396 0.625 0.40625 4.35596 0.40625 8.95833C0.40625 13.5607 4.00396 17.2917 8.44196 17.2917C10.4802 17.2917 12.3413 16.5047 13.7579 15.2077L17.6292 19.2225C17.8254 19.4258 18.1434 19.4258 18.3395 19.2225C18.5356 19.0191 18.5356 18.6893 18.3395 18.4859ZM15.4732 8.95833C15.4732 12.9854 12.3252 16.25 8.44196 16.25C4.55871 16.25 1.41071 12.9854 1.41071 8.95833C1.41071 4.93126 4.55871 1.66667 8.44196 1.66667C12.3252 1.66667 15.4732 4.93126 15.4732 8.95833Z"
              fill="white"
            />
          </svg>
        );
    }
  };

  if (!name) return null;

  return <span className={`icon-wrap ${name}-icon-wrap`}>{renderIcon()}</span>;
};
export default InterfacesIcons;
