import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  setHousesVariants,
  setPopulars, setPurposes,
  setRooms,
} from "../../../../store/reducers/selectedFilterData";
import useSearchMove from "../../../../hooks/useSearchMove";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DevelopmentBlock from "../../../DevelopmentBlock/DevelopmentBlock";
import NavigationTopMenuItem from "../../../ui/NavigationMenu/NavigationTopMenuItem";
import InterfacesIcons from "../../../vector/InterfacesIcons";
const AppHeaderMenuItem = ({ item, index = 1 }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [items] = useState({
    secondary: [
      {
        text: "Квартири біля моря",
        slug: "kvartiri-z-vigliadom-na-more",
        handler: moveToPopulars,
      },
      {
        text: "Квартири з терасами",
        slug: "kvartiri-z-terasami",
        handler: moveToPopulars,
      },
      { text: "1-кімнатні", slug: "one", handler: moveToContRoms },
      { text: "2-х кімнатні", slug: "two", handler: moveToContRoms },
      { text: "3-х кімнатні", slug: "three", handler: moveToContRoms },
    ],
    commerce: [
      {
        text: "Ресторани, кафе, бари",
        slug: "restoran-kafe-bar",
        handler: moveToCommercePopulars,
      },
      {
        text: "Приміщення вільного призначення",
        slug: "pomeshheniia-svobodnogo-naznaceniia",
        handler: moveToCommercePopulars,
      },
      {
        text: "Магазини, салони",
        slug: "magazin-salon",
        handler: moveToCommercePopulars,
      },
      {
        text: "Приміщення промислового призначення",
        slug: "pomeshheniia-promyslennogo-naznaceniia",
        handler: moveToCommercePopulars,
      },
      {
        text: "Офісні приміщення",
        slug: "ofisnye-pomeshheniia",
        handler: moveToCommercePopulars,
      },
    ],
    house: [
      { text: "Будинки та Дачі", slug: "house", handler: moveToHousesByTypes },
      { text: "Таунхауз", slug: "taunxaus", handler: moveToHousesByTypes },
    ],
    plots: [
      {
        text: "Ділянки біля моря",
        slug: "dilianka-bilia-moria",
        handler: moveToPlotPopulars,
      },
      {
        text: "Ділянки в межах міста",
        slug: "dilianka-v-mezax-mista",
        handler: moveToPlotPopulars,
      },
      {
        text: "Ділянки за містом",
        slug: "dilianka-za-mistom",
        handler: moveToPlotPopulars,
      },
    ],
    places: [],
    newBuild: [],
  });
  const dispatch = useDispatch();
  const search = useSearchMove("catalog");
  const commerceSearch = useSearchMove("commerce");
  const houseSearch = useSearchMove("house");
  const plotsSearch = useSearchMove("plots");
  const { t, i18n } = useTranslation();
  const handlerClick = () => {
    setShowSubMenu(!showSubMenu);
  };
  const subMenuToggleAction = (val) => {
    if (item.sub_menu) {
      setShowSubMenu(val);
    }
  };
  function moveToContRoms(countRoomsSlug) {
    dispatch(setRooms(countRoomsSlug));
    const overriddenData = { rooms: countRoomsSlug };
    search.moveToFilterOverridden(overriddenData);
  }
  function moveToPopulars(slug) {
    dispatch(setPopulars(slug));
    const overriddenData = { populars: slug };
    search.moveToFilterOverridden(overriddenData);
  }

  function moveToPlotPopulars(slug) {
    dispatch(setPopulars(slug));
    const overriddenData = { populars: slug };
    plotsSearch.moveToFilterOverridden(overriddenData);
  }
  function moveToCommercePopulars(slug) {
    dispatch(setPurposes(slug));
    const overriddenData = { purposes: slug };
    commerceSearch.moveToFilterOverridden(overriddenData);
  }
  function moveToHousesPopulars(slug) {
    dispatch(setPopulars(slug));
    const overriddenData = { populars: slug };
    houseSearch.moveToFilterOverridden(overriddenData);
  }
  function moveToHousesByTypes(slug) {
    dispatch(setHousesVariants(slug));
    const overriddenData = { housesVariants: slug };
    houseSearch.moveToFilterOverridden(overriddenData);
  }

  return (
    <li
      onClick={handlerClick}
      onMouseEnter={subMenuToggleAction.bind(null, true)}
      // onMouseLeave={subMenuToggleAction.bind(null, false)}

      key={`menu_item_${index}`}
      className="app-header--navigation-item"
    >
      <Link
        className={
          showSubMenu
            ? "app-header--navigation-item--link app-header--navigation-item--link__sub-menu-open"
            : "app-header--navigation-item--link"
        }
        to={item.route}
      >
        {t(item.title)}

        {item.sub_menu && <InterfacesIcons name={"menu-arrow"} />}
      </Link>

      {showSubMenu && item.sub_menu && (
        <div className="app-header--navigation__sub_menu">
          <div
            className="app-header--navigation__sub_menu-list"
            onMouseLeave={subMenuToggleAction.bind(null, false)}
          >
            <div className="app-header--navigation__sub_menu-list-coll">
              <h3 onClick={moveToPopulars.bind(null, "")}>
                {" "}
                {t("Квартири вторинний ринок")}{" "}
              </h3>
              <ul>
                {items.secondary.map((obj, index) => {
                  return <NavigationTopMenuItem item={obj} />;
                })}
              </ul>
            </div>

            <div className="app-header--navigation__sub_menu-list-coll">
              {/*Alternatively,use *AppHeaderMenuCommerceItem*,but without styles affected */}
              <h3 onClick={moveToCommercePopulars.bind(null, "")}>
                {" "}
                {t("Комерція")}{" "}
              </h3>
              <ul>
                {items.commerce.map((obj, index) => {
                  return <NavigationTopMenuItem item={obj} />;
                })}
              </ul>
            </div>

            <div className="app-header--navigation__sub_menu-list-coll">
              <h3 onClick={moveToHousesPopulars.bind(null, "")}>
                {" "}
                {t("Будинки")}{" "}
              </h3>

              <ul>
                {items.house.map((obj, index) => {
                  return <NavigationTopMenuItem item={obj} />;
                })}
              </ul>
              {/*<br/>*/}
              {/*<h3 onClick={moveToPlotPopulars.bind(null, "")}>*/}
              {/*  {" "}*/}
              {/*  {t("Ділянки")}{" "}*/}
              {/*</h3>*/}
              {/*<ul>*/}
              {/*  {items.plots.map((obj, index) => {*/}
              {/*    return <NavigationTopMenuItem item={obj} />;*/}
              {/*  })}*/}
              {/*</ul>*/}
            </div>

            <div className="app-header--navigation__sub_menu-list-coll">
              <h3 onClick={moveToPlotPopulars.bind(null, "")}>
                {" "}
                {t("Ділянки")}{" "}
              </h3>
              <ul>
                {items.plots.map((obj, index) => {
                  return <NavigationTopMenuItem item={obj} />;
                })}
              </ul>
            </div>


            {/*<div className="app-header--navigation__sub_menu-list-coll">*/}
            {/*  <h3>{t("Новобудова")}</h3>*/}
            {/*  <ul>*/}
            {/*    <DevelopmentBlock className="development-block" />*/}

                {/*
                            <li>
                                <a href="/">{t("Здані ЖК")}</a>
                            </li>

                            <li>
                                <a href="/">{t("Будуються ЖК")}</a>
                            </li>

                            <li>
                                <a href="/">{t("З готовим ремонтом")}</a>
                            </li>

                            <li>
                                <a href="/">{t("Новобудова на виплату")}</a>
                            </li>

                            <li>
                                <a href="/">{t("Акційні пропозиції")}</a>
                            </li>

                            <li>
                                <a href="/">{t("Новобудови в Аркадії")}</a>
                            </li>

                            <li>
                                <a href="/">{t("Новобудови в Центрі")}</a>
                            </li>

                            <li>
                                <a href="/">{t("Новобудови у Київському районі")}</a>
                            </li>

                            <li>
                                <a href="/">{t("Новобудови у Суворівському районі")}</a>
                            </li>

                            <li>
                                <a href="/">{t("Новобудови у Малинівському районі")}</a>
                            </li>
                            */}
            {/*  </ul>*/}
            {/*</div>*/}


          </div>
        </div>
      )}
    </li>
  );
};

export default AppHeaderMenuItem;
