import { useLayoutEffect, useState } from "react";

export const useWindowWidth = () => {
  const [size, setSize] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.screen != null) setSize(window.screen.availWidth);

      if (window.innerWidth != null) setSize(window.innerWidth);

      if (document.body != null) setSize(document.body.clientWidth);
    }

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};
