import { useSelector } from "react-redux";
import { getQueryString } from "/src/utils/filterUtils";
import { useHistory } from "react-router-dom";

const useSearchMove = (type) => {
  const history = useHistory();
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  const moveToFilter = () => {
    const query = getQueryString(selectedFilterData);

    history.push({
      // pathname: '/catalog',
      pathname: `/${type}`, // /catalog, /commerce
      search: "?" + query, // query string
      state: {
        // location state
        update: true,
      },
    });
  };
  const moveToFilterOverridden = (overridden = []) => {
    const cloneObj = { ...selectedFilterData };
    if (cloneObj.hasOwnProperty("objectId")) {
      delete cloneObj.objectId;
    }
    const query = getQueryString(Object.assign({}, cloneObj, overridden));
    history.push({
      // pathname: '/catalog',
      pathname: `/${type}`, // /catalog, /commerce
      search: "?" + query, // query string
      state: {
        // location state
        update: true,
      },
    });
  };
  const moveToFilterPaginate = (page) => {
    const query = getQueryString(selectedFilterData);

    history.push({
      // pathname: '/catalog',
      pathname: `/${type}`, // /catalog, /commerce
      search: "?" + query + "&page=" + page, // query string
      state: {
        // location state
        update: true,
      },
    });
  };
  return { moveToFilter, moveToFilterPaginate, moveToFilterOverridden };
};
export default useSearchMove;
