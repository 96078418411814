import React, { useEffect, useState } from "react";

import AppFooter from "/src/components/AppFooter";
import Consultation from "../../components/Sell/Consultation";
import BreadCrumbs from "/src/components/ui/BreadCrumbs";

import { useDispatch, useSelector } from "react-redux";
import { putObjectBreadCrumbs } from "../../store/reducers/object";

import AppHeader from "../../components/AppHeader";

import { useParams } from "react-router-dom";
import APP_CONFIG from "../../config";
import HowDoesItWork from "../../components/Sell/HowDoesItWork";
import { useTranslation } from "react-i18next";
import OurBenefits from "../../components/Sell/OurBenefits";
import DialogBooking from "../../components/Dialog/DialogBooking";

const Sell = () => {
  const dispatch = useDispatch();
  const breadCrumbs = useSelector((state) => state.object.breadCrumbs);
  const { t, i18n } = useTranslation();
  let { slug } = useParams();
  useEffect(() => {
    dispatch(putObjectBreadCrumbs([...APP_CONFIG.breadCrumbs.defaultSell]));
  }, [dispatch]);

  return (
    <div className=" catalog-page sell-page">
      <AppHeader />
      <div className="realtor-page--layout sell-page--layout ">
        <div className="room--cell">
          <div className="breadcrumb-wrapper">
            <BreadCrumbs linkChain={breadCrumbs} />
          </div>
        </div>
      </div>

      <div className="home-page--about-description2 ">
        <div className="sell-page--layout">
          <div className="room--cell">
            <Consultation />
          </div>
        </div>
      </div>

      <div className="home-page--about-description">
        <div className="container">
          <h4 className="description-text">
            {t(
              "Агентство нерухомості Домінанта працює дванадцять років на одеському ринку",
            )}
          </h4>

          <div className="description--row">
            <div className="icon-wrap">
              <svg
                width="184"
                height="139"
                viewBox="0 0 184 139"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_60_277)">
                  <path
                    d="M174.479 87.5123L136.452 97.1045L146.04 135.148L184.067 125.555L174.479 87.5123Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M164.782 87.0373L141.668 71.0588L166.73 59.3672L164.782 87.0373Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M137.901 58.3265C131.798 20.3938 96.0888 -5.32771 58.3019 0.907807C20.3852 7.14332 -5.32547 42.8676 0.907415 80.6704C7.1403 118.473 42.8495 144.195 80.6364 137.959C118.423 131.854 144.134 96.1293 137.901 58.3265ZM66.093 93.1414H48.563V42.8676H66.093C87.3887 42.8676 95.05 50.9218 95.05 68.0695C95.05 85.0872 87.129 93.1414 66.093 93.1414ZM105.178 94.4405C104.01 95.6096 102.581 96.1293 100.893 96.1293C99.2052 96.1293 97.7769 95.6096 96.6082 94.4405C95.4395 93.2713 94.7903 91.8424 94.7903 90.1536C94.7903 88.4648 95.4395 87.0358 96.6082 85.8667C97.7769 84.6975 99.2052 84.1779 100.893 84.1779C102.581 84.1779 104.01 84.6975 105.178 85.8667C106.347 87.0358 106.866 88.4648 106.866 90.1536C106.866 91.8424 106.347 93.2713 105.178 94.4405Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M66.095 50.144H57.0054V85.7384L66.095 85.8683C82.4563 85.8683 86.4817 81.1917 86.4817 68.0711C86.4817 55.3403 82.4563 50.144 66.095 50.144Z"
                    fill="#F1623D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_60_277">
                    <rect width="184" height="139" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="text-cell">
              <p>
                {t(
                  "11 філій по всьому місту, професійні співробітники, які в найкоротші терміни підберуть Вам найкраще вирішення квартирного питання",
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      <HowDoesItWork />
      {/*//todo fix it style*/}

      <OurBenefits/>

      <div className="home-page--about-description sell-description">
        <div className="container">
          <h4 className="description-text hidden-before-1200">
            {t(
              "Зв'яжіться з нами і дізнайтесь, як ми можемо допомогти вам продати вашу нерухомість швидко і вигідно!",
            )}
          </h4>

          <div className="description--row description-sell flex-direction-column-1200">
            <div className="icon-wrap">
              <svg
                width="184"
                height="139"
                viewBox="0 0 184 139"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_60_277)">
                  <path
                    d="M174.479 87.5123L136.452 97.1045L146.04 135.148L184.067 125.555L174.479 87.5123Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M164.782 87.0373L141.668 71.0588L166.73 59.3672L164.782 87.0373Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M137.901 58.3265C131.798 20.3938 96.0888 -5.32771 58.3019 0.907807C20.3852 7.14332 -5.32547 42.8676 0.907415 80.6704C7.1403 118.473 42.8495 144.195 80.6364 137.959C118.423 131.854 144.134 96.1293 137.901 58.3265ZM66.093 93.1414H48.563V42.8676H66.093C87.3887 42.8676 95.05 50.9218 95.05 68.0695C95.05 85.0872 87.129 93.1414 66.093 93.1414ZM105.178 94.4405C104.01 95.6096 102.581 96.1293 100.893 96.1293C99.2052 96.1293 97.7769 95.6096 96.6082 94.4405C95.4395 93.2713 94.7903 91.8424 94.7903 90.1536C94.7903 88.4648 95.4395 87.0358 96.6082 85.8667C97.7769 84.6975 99.2052 84.1779 100.893 84.1779C102.581 84.1779 104.01 84.6975 105.178 85.8667C106.347 87.0358 106.866 88.4648 106.866 90.1536C106.866 91.8424 106.347 93.2713 105.178 94.4405Z"
                    fill="#F1623D"
                  />
                  <path
                    d="M66.095 50.144H57.0054V85.7384L66.095 85.8683C82.4563 85.8683 86.4817 81.1917 86.4817 68.0711C86.4817 55.3403 82.4563 50.144 66.095 50.144Z"
                    fill="#F1623D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_60_277">
                    <rect width="184" height="139" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="text-cell">
              <span className="description-text-large hidden-after-1200 ">
                {t(
                  "Зв'яжіться з нами і дізнайтесь, як ми можемо допомогти вам продати вашу нерухомість швидко і вигідно!",
                )}
              </span>

              <div className="actions">
                <DialogBooking model={{}} textOrder={"Отримати консультацію"} />
              </div>
            </div>
          </div>
        </div>
      </div>


      <AppFooter />
    </div>
  );
};
export default Sell;
