import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import APP_CONFIG from "/src/config";

import { useDispatch } from "react-redux";
import { clear } from "/src/store/reducers/activeItem";
import GoogleMap from "../../libs/google-map-react/src";
import Marker from "../../libs/google-map-react/src/components/Marker";

const LA_CENTER = [46.47747, 30.73262];
const GoogleMapComponent = (props) => {
  const dispatch = useDispatch();
  const [placesOnTheMap, setPlacesOnTheMap] = useState(props.options);
  const [center,setCenter] = useState(LA_CENTER)
  useEffect(() => {
    setPlacesOnTheMap(props.options);
  }, [props.options]);


  const handlerChangePosition = (model) => {
      const {lat,lng} = model
      setCenter([parseFloat( lat),parseFloat(lng)])
  }


  return (
    <React.Fragment>
      <GoogleMap
        defaultZoom={12}
        defaultCenter={LA_CENTER}
        bootstrapURLKeys={{ key: APP_CONFIG.GOOGLE_MAP_KEY }}
        onDragEnd={props.onDragEnd}
        center={center}
        onClick={(f) => {
          dispatch(clear());
        }}
      >
        {placesOnTheMap.length &&
          placesOnTheMap.map((place) => {
            return (
              <Marker
                key={place.id}
                // text={place.title}
                model={place}
                lat={place.lat}
                lng={place.lng}
                useInfoWindow={props.useInfoWindow}
                shouldChangePosition={handlerChangePosition}
              />
            );
          })}
      </GoogleMap>
      {/*</div>*/}
    </React.Fragment>
  );
};
// todo rm after first realize!!!
GoogleMapComponent.defaultProps = {
  isDev: false,
  useInfoWindow: true,
  options: [],
  onDragEnd: (f) => f,
};
GoogleMapComponent.propTypes = {
    isDev: PropTypes.bool,
    useInfoWindow: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onDragEnd: PropTypes.func,
};

export default GoogleMapComponent;
