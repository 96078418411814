import React, { useEffect } from "react";
import HomePageNews from "/src/components/HomePageNews";
import HomePagePartners from "/src/components/HomePagePartners";
import HomePageAbout from "/src/components/HomePageAbout";
import CooperationWork from "/src/components/CooperationWork";
import AppFooter from "/src/components/AppFooter";
import { useDispatch } from "react-redux";
import { setResetSelectedFilter } from "../../store/reducers/selectedFilterData";
import HomePageRealtorsPromo from "../../components/HomePageRealtorsPromo";
import PrimaryCarousel from "../../components/PrimaryCarousel/PrimaryCarousel";
import WhyYou from "../../components/WhyYou";
import AppHeader from "../../components/AppHeader";
import HomePageBestDeals from "../../components/HomePageBestDeals";

const Home = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // todo reset filter
    dispatch(setResetSelectedFilter());
  }, []);

  return (
    <div className="home-page">
      <AppHeader />

      <PrimaryCarousel />
      <HomePageNews />

      <HomePageRealtorsPromo />

      <HomePageBestDeals />

      <HomePageAbout />

      <CooperationWork />

      <WhyYou />

      {/*<NewsHomePage />*/}
      <HomePagePartners />
      <AppFooter />
    </div>
  );
};
export default Home;
