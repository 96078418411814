import { configureStore } from "@reduxjs/toolkit";
import filter from "./reducers/filter";
import selectedFilterData from "./reducers/selectedFilterData";
import objects from "./reducers/objects";
import object from "./reducers/object";
import activeItem from "./reducers/activeItem";
import Logger from "./middlewares/Logger";
import CounterWatcher from "./middlewares/CounterWatcher";
import previousResults from "./reducers/previousResults";
import bestProposals from "./reducers/bestProposals";
import favorites from "./reducers/favorites";

// const logger = store => next => action => {

//     let result = next(action)

//     return result
// }
export default configureStore({
  reducer: {
    filter: filter,
    selectedFilterData: selectedFilterData,
    objects: objects,
    object: object,
    activeItem: activeItem,
    previousResults: previousResults,
    bestProposals: bestProposals,
    favorites: favorites,
  },
  middleware: [Logger, CounterWatcher],
});
