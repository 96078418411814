import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { useDispatch } from "react-redux";
import useInputDebounce from "../../../hooks/useInputDebounce";
// import {setPrice} from "../../../store/reducers/selectedFilterData";

const PriceSlider = styled(Slider)(({ theme }) => ({
  color: "#3F6DB5",
  height: 1,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 21,
    width: 21,
    backgroundColor: "#fff",
    border: "1px solid #fafafa",
    "&:hover": {
      boxShadow: "0 0 0 1px rgba(64, 110, 181, 0)",
    },
    "&.airbnb-bar": {
      height: 1,
      width: 1,
      backgroundColor: "transparent",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 1,
    backgroundColor: "transparent",
    border: "1px solid transparent",
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 1,
  },
}));

function ThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10.5" cy="10.5" r="10" stroke="#DEDEDE" />
        <line
          x1="10.4473"
          y1="5.52631"
          x2="10.4473"
          y2="15.4737"
          stroke="black"
        />
        <line
          x1="13.7627"
          y1="6.76974"
          x2="13.7627"
          y2="14.2303"
          stroke="black"
        />
        <line
          x1="7.13184"
          y1="6.76974"
          x2="7.13184"
          y2="14.2303"
          stroke="black"
        />
      </svg>
    </SliderThumb>
  );
}

const RangeFieldsetBlock = ({
  title,
  handlerMessage,
  values,
  baseValues,
  dispatcherFn,
  renderKey,
  hasReset,
}) => {
  const [valueMin, setValueMin] = useState(() => {
    // let result = baseValues.min;
    let result = baseValues?.min || 0;
    if (values) {
      const splited = values.split("-");
      if (splited.length > 1) {
        result = parseInt(splited[0]);
      }
    }
    return result;
  });

  const [valueMax, setValueMax] = useState(() => {
    // let result = baseValues.max;
    let result = baseValues?.max || 0;
    if (values) {
      const splited = values.split("-");
      if (splited.length > 1) {
        result = parseInt(splited[1]);
      }
    }
    return result;
  });

  const debounced = useInputDebounce([valueMin, valueMax].join("-"), 800); //todo complete debounce
  useEffect(() => {
    if (debounced !== "0-0") {
      // dispatch(setPrice(debounced))
      dispatch(dispatcherFn(debounced));
    }
  }, [debounced]);

  useEffect(() => {
    if (hasReset && baseValues) {
      setValueMin(baseValues.min);
      setValueMax(baseValues.max);
    }
  }, [hasReset]);

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValueMin(newValue[0]);
    setValueMax(newValue[1]);
  };

  //???
  if (!baseValues) {
    return null;
  }

  return (
    <div className="fields__cell">
      <div className="range-fieldset">
        <div className="fields__cell-label">{title}</div>
        <PriceSlider
          key={renderKey}
          components={{ Thumb: ThumbComponent }}
          // getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
          defaultValue={[valueMin, valueMax]}
          min={baseValues.min}
          // step={100}
          max={baseValues.max}
          onChange={handleChange}
          //todo stop here
          value={[valueMin, valueMax]}
        />
        <div className="fields__cell-label ">
          {handlerMessage(valueMin, valueMax)}
        </div>
      </div>
    </div>
  );
};

RangeFieldsetBlock.propTypes = {};

export default RangeFieldsetBlock;
