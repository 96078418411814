import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import { useDispatch, useSelector } from "react-redux";
import { setLandPlotsVariants } from "../../store/reducers/selectedFilterData";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";

const PlotsFormVariantSelect = ({ type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.filter.value.landPlotsVariants);
  const [selectedItems, setSelectedItems] = useState(null);
  const selectedFilterData = useSelector(
    (state) => state.selectedFilterData.value,
  );
  const search = useSearchMove(type);
  const handlerSelectChange = (event, newValue) => {
    dispatch(setLandPlotsVariants(newValue?.slug ?? ""));
    let overriddenData = { landPlotsVariants: newValue?.slug ?? "" };
    search.moveToFilterOverridden(overriddenData);
  };
  useEffect(() => {
    if (items && items.length && selectedFilterData.landPlotsVariants) {
      const filtered = items.find(
        (e) => e.slug === selectedFilterData.landPlotsVariants,
      );
      setSelectedItems(filtered);
    }
  }, [items, selectedFilterData]);
  return (
    <React.Fragment>
      <SelectItems
        items={items}
        selected={selectedItems}
        label={t("Форма ділянки")}
        multiple={false}
        callbackChange={handlerSelectChange}
      />
    </React.Fragment>
  );
};

export default PlotsFormVariantSelect;
