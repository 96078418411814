import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";

const CustomAutocomplete = withStyles({
  tag: {
    height: 22,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#fff",
    },
    "& .MuiChip-deleteIcon": {
      // color: "red"
    },
    "&:after": {
      content: '""',
      // right: 170,
      // top: 6,
      // height: 12,
      // width: 12,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
})(Autocomplete);
const SelectItems = ({
  items,
  label,
  multiple,
  callbackChange,
  selected,
  limitTags = 2,
}) => {
  return (
    <div className="filter-fields-group__cell-input">
      <CustomAutocomplete
        // id='rooms-count'
        options={items}
        value={selected}
        multiple={multiple}
        limitTags={limitTags}
        onChange={callbackChange}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="filled" />
        )}
      />
    </div>
  );
};

SelectItems.propTypes = {};

export default SelectItems;
