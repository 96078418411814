import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DialogBooking from "../Dialog/DialogBooking";

const QuestionBanner = ({ type }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="catalog-page--banner">
      <div className="container">
        <div className="catalog-page--banner_row">
          <div className="text-cell">
            <h2>{t("Не вдається самостійно знайти нерухомість?")}</h2>

            <p>
              {t("Ми знаємо, як це складно. Дозвольте нам допомогти!")}
              <br />
              {t("Заповніть заявку, і наш фахівець знайде ідеальну нерухомість для вас у потрібному районі")}
            </p>


            <div className="home-page--why-you .viewing-action-row .viewing-action1" style={{ padding: 0 }}>
              <DialogBooking model={{}} textOrder={"Отримати консультацію"} />
            </div>
          </div>

          <div className="image-cell">
            <div className="background-layer">
              <svg
                width="1180"
                height="648"
                viewBox="0 0 1440 648"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_436_2515"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="1440"
                  height="649"
                >
                  <rect
                    y="0.000244141"
                    width="1440"
                    height="648"
                    fill="#DFE3E9"
                  />
                </mask>

                <g mask="url(#mask0_436_2515)">
                  <circle cx="1184" cy="711" r="387" fill="#3F6DB5" />
                  <rect
                    x="1231.04"
                    y="230.61"
                    width="113.892"
                    height="113.892"
                    transform="rotate(-22.6125 1231.04 230.61)"
                    fill="#3F6DB5"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuestionBanner.propTypes = {};

export default QuestionBanner;
