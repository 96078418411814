import React from "react";
import { Route, Switch } from "react-router-dom";

import "./style/main.css";

import { Provider } from "react-redux";
import store from "./store/store";
import Room from "./pages/Room/Room";

import UserSuccess from "./pages/Success/UserSuccess";
import RealtorPage from "./pages/Realtors/RealtorPage";
import UserSuccessFeedback from "./pages/Success/UserSuccessFeedback";
import CommerceCatalog from "./pages/CommerceCatalog/CommerceCatalog";
import Commerce from "./pages/Commerce/Commerce";
import RoomCatalog from "./pages/RoomCatalog/RoomCatalog";
import HousesCatalog from "./pages/HousesCatalog/HousesCatalog";
import House from "./pages/House/House";
import PlotsCatalog from "./pages/PlotsCatalog/PlotsCatalog";
import Plot from "./pages/Plot/Plot";
import Home from "./pages/Home/Home";
import Favorites from "./pages/Favorites/Favorites";
import Sell from "./pages/Sell/Sell";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contacts from "./pages/Contacts/Contacts"

const App = () => {
  // const { t, i18n } = useTranslation();
  // return <h1>{t("Ласкаво просимо до React")}</h1>
  return (
    <Provider store={store}>
      <Switch>
        <Route exact={true} path="/" component={Home} />

        <Route
          exact={true}
          path="/user-success-feedback-page"
          component={UserSuccessFeedback}
        />
        <Route exact={true} path="/catalog" component={RoomCatalog} />
        <Route exact={true} path="/house" component={HousesCatalog} />
        <Route exact={true} path="/plots" component={PlotsCatalog} />
        <Route exact={true} path="/commerce" component={CommerceCatalog} />
        <Route exact={true} path={"/apartment/:slug"} component={Room} />
        <Route exact={true} path={"/commerce/:slug"} component={Commerce} />
        <Route exact={true} path={"/house/:slug"} component={House} />
        <Route exact={true} path={"/plots/:slug"} component={Plot} />


        <Route exact={true} path="/realtors/:slug" component={RealtorPage} />
        <Route exact={true} path="/favorites" component={Favorites} />
        <Route exact={true} path="/about-us" component={AboutUs} />
        <Route exact={true} path="/contacts" component={Contacts} />
        <Route exact={true} path="/sell" component={Sell} />
        <Route exact={true} path="/user-success-page" component={UserSuccess} />
      </Switch>
    </Provider>
  );
};

export default App;
