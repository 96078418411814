import { createSlice } from "@reduxjs/toolkit";
import APP_CONFIG from "../../config";

export const objectSlice = createSlice({
  name: "object",
  initialState: {
    // data: {data:[],links:{},meta:{}},
    data: {},
    // breadCrumbs: APP_CONFIG.breadCrumbs.defaultVtorichka,
    breadCrumbs: APP_CONFIG.breadCrumbs.default,
    similar: [],
  },
  reducers: {
    putObject: (state, action) => {
      state.data = action.payload;
    },
    putObjectBreadCrumbs: (state, action) => {
      state.breadCrumbs = action.payload;
    },
    putObjectSimilar: (state, action) => {
      state.similar = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { putObject, putObjectBreadCrumbs, putObjectSimilar } =
  objectSlice.actions;
export default objectSlice.reducer;
